import React, { useEffect, useState } from "react";
import "./Navbar.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import logo from "../../images/whsc-logo.png";
import {
  faBars,
  faTimes,
  faChevronDown,
  faPhoneAlt,
  faEnvelope,
} from "@fortawesome/free-solid-svg-icons";
import {
  faFacebookF,
  faTwitter,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";
import "./Overlay.css";
import OverlayMenu from "./OverlayMenu";
import { MdClose } from "react-icons/md";
import { GiHamburgerMenu } from "react-icons/gi";

const Navbar = () => {
  window.addEventListener("scroll", function () {
    let header = document.querySelector("header");
    header.classList.toggle("sticky", window.scrollY > 0);
  });

  const [sidebar, setSidebar] = useState(false);

  const showSidebar = () => setSidebar(!sidebar);

  const [toggle, setToggle] = useState(false);
  const toggleCLick = () => {
    setToggle(!toggle);
  };
  return (
    <header id="header" class="header">
      <div className="nav-container">
        <div className="nav">
          <input type="checkbox" id="show-menu" />
          <label for="show-menu" className="menu-icon" id="target">
            <div className="menu-icon" onClick={toggleCLick}>
              {toggle ? <MdClose /> : <GiHamburgerMenu />}
            </div>
          </label>
          <div class="content p-2">
            <div class="logo">
              <a href="/">
                <img src={logo} alt="whsc-logo" className="w-100" />
              </a>
            </div>
            <ul class="links my-auto pb-3">
              <li>
                <a href="/">Home</a>
              </li>
              {/* <li>
                <a href="https://www.whsc.in/" target="_blank" rel="noreferrer">
                  News & Media
                </a>
              </li> */}
              {/* <li>
                <a href="/gallery">Gallery</a>
              </li> */}
              <li>
                <a href="/campaign">Campaign</a>
              </li>
              <li>
                <a href="/affiliated-organization">Partner Org</a>
              </li>
              <li>
                <a href="#" class="desktop-link">
                  We Stand For{" "}
                  <FontAwesomeIcon
                    icon={faChevronDown}
                    size="sm"
                    className="icon-down"
                  />
                </a>
                <input type="checkbox" id="show-drop1" />
                <label for="show-drop1">
                  We Stand For
                  <FontAwesomeIcon
                    icon={faChevronDown}
                    size="sm"
                    color="#ffb422"
                    className="dropdown-icon-mobile"
                  />
                </label>
                <ul className="dropdown-area">
                  <li>
                    <a href="/we-stands-for/rights-of-defenders/1">
                      Right of Defenders
                    </a>
                  </li>
                  <li>
                    <a href="/we-stands-for/human-right-defenders/2">
                      Human Rights Defender
                    </a>
                  </li>
                  <li>
                    <a href="/we-stands-for/women-human-rights-defenders/3">
                      Women Human Rights Defender
                    </a>
                  </li>
                  <li>
                    <a href="/we-stands-for/human-rights-lawyers/4">
                      Human Rights lawyers
                    </a>
                  </li>
                  <li>
                    <a href="/we-stands-for/freedom-of-association/5">
                      Freedom of Association
                    </a>
                  </li>
                  <li>
                    <a href="/we-stands-for/freedom-of-assembly/6">
                      Freedom of Assembly
                    </a>
                  </li>
                  <li>
                    <a href="/we-stands-for/strong-international-mechanisms/7">
                      Strong International Mechanism
                    </a>
                  </li>
                  <li>
                    <a href="/we-stands-for/universalism-and-indivisibility/8">
                      Universalism and Indivisibility
                    </a>
                  </li>
                  <li>
                    <a href="/we-stands-for/resisting-ill-democracy/9">
                      Resisting ill democracy
                    </a>
                  </li>
                  <li>
                    <a href="/we-stands-for/freedom-of-expression/10">
                      Freedom of Expression
                    </a>
                  </li>
                  <li>
                    <a href="/we-stands-for/freedom-of-religion/11">
                      Freedom of Religion
                    </a>
                  </li>
                </ul>
              </li>

              <li>
                {/* IoIosArrowDown */}
                <a href="#" class="desktop-link">
                  About Us{" "}
                  <FontAwesomeIcon
                    icon={faChevronDown}
                    size="sm"
                    className="icon-down"
                  />
                </a>
                <input type="checkbox" id="show-drop2" />
                <label for="show-drop2">
                  About
                  <FontAwesomeIcon
                    icon={faChevronDown}
                    size="sm"
                    color="#ffb422"
                    className="dropdown-icon-mobile"
                  />
                </label>
                <ul className="dropdown-area">
                  <li>
                    <a href="/about-us">About WHSC</a>
                  </li>
                  <li>
                    <a href="/ourPeople">Our People</a>
                  </li> 
                  <li>
                    <a href="/ourStory">Our Story</a>
                  </li>
                   <li>
                    <a href="/ourDonors">Our Donor</a>
                  </li> 
                  <li>
                    <a href="/mission-and-vision">Mission & Vision</a>
                  </li>
                  <li>
                    <a href="/membership">Membership</a>
                  </li>
                  <li>
                    <a href="/workWithUs">Work With Us</a>
                  </li>
                </ul>
              </li>
              <li>
                <a href="/contact">Contact</a>
              </li>
              <li>
                <a href="/donate" className="donate">
                  Donate
                </a>
              </li>

              {/* For Side Bar Purpose ===============*/}
              <div className="item-form-side-bar py-3 text-center">
                <h2 className="side-bar-title">WHSC</h2>
                <div className="text-center">
                  <a href="tel: +919123800434">
                    <FontAwesomeIcon icon={faPhoneAlt} />
                    +91 9123800434
                  </a>{" "}
                  <br />
                  <a href="mailto: whsc.in@gmail.com">
                    <FontAwesomeIcon icon={faEnvelope} /> whsc.in@gmail.com
                  </a>{" "}
                  <br />
                </div>
                <div className="lh-1 copyright-nav pt-2">
                  <span className="text-white">
                    {" "}
                    &copy; {new Date().getFullYear()}{" "}
                  </span>
                  <a className="credit-line"
                    href="/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    WHSC
                  </a>

                  <div className="side-bar-social-icon my-2">
                    <a href="https://twitter.com/Hindu_Struggle" target="_blank">
                      <FontAwesomeIcon icon={faTwitter} />
                    </a>
                    <a href="https://www.facebook.com/WorldHinduStruggleCommittee" target="_blank">
                      <FontAwesomeIcon icon={faFacebookF} />
                    </a>
                    <a href="https://www.youtube.com/channel/UCDh3RPds6qyGXtc3Y_CJAvQ" target="_blank">
                      <FontAwesomeIcon icon={faYoutube} />
                    </a>
                  </div>
                </div>
              </div>
              {/* For Side Bar Purpose ===============*/}

              <li className="px-2 text-white overlay-burger ">
                <FontAwesomeIcon
                  icon={faBars}
                  onClick={showSidebar}
                  size="lg"
                />
              </li>
            </ul>
          </div>
        </div>

        {/* sidebar*/}
        <div
          className={sidebar ? "overlay-menu  active-sidebar" : "overlay-menu "}
        >
          <div className="primary-color">
            <FontAwesomeIcon
              icon={faTimes}
              className="white float-end close-btn my-4 fs-3"
              onClick={showSidebar}
            />
            <OverlayMenu />
          </div>
        </div>
      </div>
    </header>
  );
};

export default Navbar;
