import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDotCircle } from '@fortawesome/free-regular-svg-icons';
import { faAt, faEnvelopeOpen } from '@fortawesome/free-solid-svg-icons'

const PrivacyComponents = () => {
    return (
        <section>
            <div className="termsPage">
                <h3 className="leftDesign">Legal basis of processing</h3>
                <p className="fs-6">"Data protection law requires us to have a legal justification to process your personal information. We use the following depending on the type of data and the type of processing:</p>
                <div>
                    <p className="m-2 fs-6 fw-bold"><FontAwesomeIcon className="primary-color2" icon={faDotCircle} /> Consent</p>
                    <p className="m-2 fs-6"> We require your consent to send you our communications for example to send you emails to update you on our work and our campaigns and to request donations. We will only process your information in this way if you consent. If you apply for a job with us and provide us with sensitive personal information (including details of your race, ethnicity, gender) we will only process that information with your consent.</p>
                </div>
                <div>
                    <p className="m-2 fs-6 fw-bold"><FontAwesomeIcon className="primary-color2" icon={faDotCircle} /> Legitimate interest</p>
                    <p className="m-2 fs-6">We sometimes share your personal information with other parts of the Amnesty International global movement (see below). This is done to fulfil our legitimate interest in promoting our organisation as effectively as possible and enabling our message to reach people who may be interested in human rights.</p>
                </div>
                <div>
                    <p className="m-2 fs-6 fw-bold"><FontAwesomeIcon className="primary-color2" icon={faDotCircle} /> To fulfil a contractual obligation</p>
                    <p className="m-2 fs-6">If you donate to us or express an interest in joining our movement we will process the personal data you provided to process that donation or action your membership request.</p>
                </div>
                <div>
                    <p className="m-2 fs-6 fw-bold"><FontAwesomeIcon className="primary-color2" icon={faDotCircle} /> Legal obligation</p>
                    <p className="m-2 fs-6">We will process your personal information to fulfil any legal obligations placed upon us, such as the prevention of fraud or money-laundering. We will also process your personal information if lawfully required to do so by a legal authority or a court of law.</p>
                </div>
                <div>
                    <p className="m-2 fs-6 fw-bold"><FontAwesomeIcon className="primary-color2" icon={faDotCircle} /> Necessity</p>
                    <p className="m-2 fs-6">For example where you are applying for an employment or volunteering opportunity with us, processing certain information is necessary for employment purposes.</p>
                </div>
                <div>
                    <p className="m-2 fs-6 fw-bold"><FontAwesomeIcon className="primary-color2" icon={faDotCircle} /> Security</p>
                    <p className="m-2 fs-6">We take appropriate security measures to ensure that we keep your information secure, accurate and up to date. We also take care to ensure that we have secure systems for processing payments through our payment services provider. However the transmission of information over the Internet is never completely secure, so while we do our best to protect personal information, we cannot guarantee the security of information transmitted to our websites.</p>
                </div>
            </div>
            <div className="termsPage">
                <h3 className="leftDesign">Access to and your rights over your personal information</h3>
                <p className="fs-6">The personal data we hold about you is yours. You have the following rights over your information:</p>
                <div>
                    <p className="m-2 fs-6"><FontAwesomeIcon className="primary-color2" icon={faDotCircle} /> To be informed how your data is being processed</p>
                    <p className="m-2 fs-6"><FontAwesomeIcon className="primary-color2" icon={faDotCircle} /> To access your data</p>
                    <p className="m-2 fs-6"><FontAwesomeIcon className="primary-color2" icon={faDotCircle} /> To rectify any data that is inaccurate</p>
                    <p className="m-2 fs-6"><FontAwesomeIcon className="primary-color2" icon={faDotCircle} /> To instruct us to delete your data</p>
                    <p className="m-2 fs-6"><FontAwesomeIcon className="primary-color" icon={faDotCircle} /> To restrict our processing of your data (which includes contacting you via email) at any time. All our email communications to you will contain an unsubscribe link.</p>
                    <p className="m-2 fs-6"><FontAwesomeIcon className="primary-color2" icon={faDotCircle} /> To object to your data being stored.</p>
                    <p className="m-2 fs-6"><FontAwesomeIcon className="primary-color2" icon={faDotCircle} /> To move your data</p>
                </div>
            </div>
            <div className="termsPage">
                <h3 className="leftDesign">Lawful grounds</h3>
                <p className="fs-6">Under certain laws, we are required to rely on one or more lawful grounds to collect and use the personal information that we have outlined above. We consider the grounds listed below to be relevant:</p>
                <div>
                    <p className="m-2 fs-6"><FontAwesomeIcon className="primary-color2" icon={faDotCircle} /> <span className="fw-bold">Legal obligation:</span> Where the processing of your personal information is necessary for us to comply with a legal obligation to which we are subject (e.g., because we have to provide information to tax authorities).</p>
                    <p className="m-2 fs-6"><FontAwesomeIcon className="primary-color2" icon={faDotCircle} /> <span className="fw-bold">Contractual relationship:</span> Where it is necessary for us to use your personal information in order to perform a contract to which you are a party (or to take steps at your request prior to entering a contract).</p>
                    <p className="m-2 fs-6"><FontAwesomeIcon className="primary-color2" icon={faDotCircle} /> <span className="fw-bold">Legitimate interests:</span> We rely on this basis where applicable law allows us to collect and use personal information for our legitimate interests and the use of your personal information is fair, balanced, and does not unduly impact your rights. For instance, it is in our legitimate interest to use personal information in association with planning of, delivery of, and monitoring of our programmatic work and grant making.</p>
                    <p className="m-2 fs-6"><FontAwesomeIcon className="primary-color2" icon={faDotCircle} /> <span className="fw-bold">Consent:</span> We may obtain your consent to use your personal information in certain circumstances (e.g., to send you email marketing or for the use of non-essential cookies). We will ensure that when we obtain your consent, you are free both to give it and to decline to give it. Additionally, you may always withdraw your consent at any time without any further detriment to you.</p>
                </div>
            </div>
            <div className="termsPage">
                <h3 className="leftDesign">How long do we keep your personal information?</h3>
                <p>We will keep your personal information for as long as necessary:</p>
                <div>
                <p className="m-2 fs-6"><FontAwesomeIcon className="primary-color2" icon={faDotCircle} /> to comply with any statutory or regulatory requirements we are subject to under applicable law</p>
                <p className="m-2 fs-6"><FontAwesomeIcon className="primary-color2" icon={faDotCircle} /> to fulfill the purposes for which the personal information was collected; or</p>
                <p className="m-2 fs-6"><FontAwesomeIcon className="primary-color2" icon={faDotCircle} /> to defend our or a third party’s legal rights.</p>
                <p className="m-2 fs-6"><FontAwesomeIcon className="primary-color2" icon={faDotCircle} /> If you have any questions, about the retention periods for holding your personal information, please contact us using the details at section 17 below.</p>
                </div>
            </div>
            <div className="termsPage">
                <h3 className="leftDesign">Children</h3>
                <p className="m-2 fs-6"><FontAwesomeIcon className="primary-color2" icon={faDotCircle} /> Our website is not intended to be used by individuals who are under the age of sixteen (16). If you are under the age of sixteen (16), please do not provide personal information to us or through our website.</p>
            </div>
            <div className="termsPage">
                <h3 className="leftDesign">How to contact us</h3>
                <p className="m-2 fs-6">WHSC Office of the General Counsel is responsible for monitoring compliance with relevant legislation in relation to personal information. You can contact the Office of the General Counsel if you have any questions about this privacy or our treatment of your personal information:</p>
                <p className="m-2 fs-6"><FontAwesomeIcon className="primary-color2" icon={faAt }/> <span className="fw-bold">Email: </span> whsc@gmail.com</p>
                <p className="m-2 fs-6"><FontAwesomeIcon className="primary-color2" icon={faEnvelopeOpen }/> <span className="fw-bold">Post: </span> Office of the General Counsel, Open Society Foundations, 224 West 57 St, New York, NY 10019</p>
            </div>
        </section>
    );
};

export default PrivacyComponents;