import React, { useState } from "react";
// import bannerImg from "../../images/activity.jpg";
import "./OurAcitivites.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
  faFacebookF,
  faTwitter,
  faLinkedinIn,
} from "@fortawesome/free-brands-svg-icons";
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
} from "react-share";
import BlogData2 from "./BlogData2";

//create your forceUpdate hook
function useForceUpdate() {
  const [value, setValue] = useState(0); // integer state
  return () => setValue((value) => value + 1); // update the state to force render
}

const OurActivity = () => {
  const forceUpdate = useForceUpdate();
  window.scrollTo({
    top: 0,
  });

  const splitUrl = window.location.pathname.split("/");
  const Id = splitUrl[splitUrl.length - 1];

  const blog2 = BlogData2.find((blog2) => blog2.id === Id);


  return (
    <section className="overflow-hidden position-relative">
      <div
        style={{
          background: `linear-gradient(10deg, #1c232798, #27303685), url(${blog2.img}) no-repeat  center center  fixed`,
          backgroundSize: "cover",
        }}
        className="  blog-banner-sec d-flex justify-content-center"
      ></div>
      <div className="container blog-desc pt-3 bottom-gap p-0 ">
        <div className="container px-5 py-2  blog-banner-heading text-center">
          <h2>{blog2.name}</h2>

          {/* new social media links will be added */}
          <div className="d-flex flex-column blog-icons py-0 py-md-3 pe-4">
            <a href=""><FontAwesomeIcon
              icon={faFacebookF}
              size="lg"
              className="primary-color blog-social-icon"
            /></a>
            <a href="" className="my-2"><FontAwesomeIcon
              icon={faTwitter}
              size="lg"
              className="primary-color blog-social-icon"
            /></a>
            <a href=""><FontAwesomeIcon
              icon={faLinkedinIn}
              size="lg"
              className="primary-color blog-social-icon"
            /></a>
          </div>


        </div>
        <div className="px-lg-5 px-3">
          <article className="pt-5  blog-para">{blog2.para}</article>
        </div>
        <div className="blog-bottom py-5 w-100 d-flex justify-content-evenly ">
          <h4 className="my-auto text-center">{blog2.name}</h4>
        </div>
      </div>
    </section>
  );
};

export default OurActivity;
