import React, { useState } from "react";
import "./../Card.css";
import boardMember1 from "../../../images/board-member.jpg";
import bannerImg2 from "../../../images/managementTeam.jpg";
import Banner2 from "../../../Shared/Banner2/Banner2";
import ContactAction from "../../../Shared/ContactAction/ContactAction";

const BoardMember = [
  {
    name: "Alice Nah",
    designation: "Chairperson",
    personImg: boardMember1,
    country: "singapore",
  },
  {
    name: "Alice Nah",
    designation: "Chairperson",
    personImg: boardMember1,
    country: "singapore",
  },
  {
    name: "Alice Nah",
    designation: "Chairperson",
    personImg: boardMember1,
    country: "singapore",
  },
  {
    name: "Alice Nah",
    designation: "Chairperson",
    personImg: boardMember1,
    country: "singapore",
  },
  {
    name: "Alice Nah",
    designation: "Chairperson",
    personImg: boardMember1,
    country: "singapore",
  },
  {
    name: "Alice Nah",
    designation: "Chairperson",
    personImg: boardMember1,
    country: "singapore",
  },
  {
    name: "Alice Nah",
    designation: "Chairperson",
    personImg: boardMember1,
    country: "singapore",
  },
  {
    name: "Alice Nah",
    designation: "Chairperson",
    personImg: boardMember1,
    country: "singapore",
  },
  {
    name: "Alice Nah",
    designation: "Chairperson",
    personImg: boardMember1,
    country: "singapore",
  },
  {
    name: "Alice Nah",
    designation: "Chairperson",
    personImg: boardMember1,
    country: "singapore",
  },
  {
    name: "Alice Nah",
    designation: "Chairperson",
    personImg: boardMember1,
    country: "singapore",
  },
];

const Management = () => {
  const [loadMore, setLoadMore] = useState(3);
  const LoadMoreHandle = () => {
    setLoadMore((prevValue) => prevValue + 3);
  };
  return (
    <section>
      <Banner2
        bannerImg={bannerImg2}
        bannerTitle="Management"
        link="Our People"
        link2="Management"
        url1="/ourPeople"
        className="position-relative"
      />
      <div className="container">
        <div className="py-5">
          <div className="text-center">
            <h1>Management Team</h1>
            <p>
              PI is governed by a Board of Directors which is responsible for
              the overall strategy and governance of the organisation.
            </p>
            <h5>Our current Board members are:</h5>
            <div className="board-member">
              {BoardMember.slice(0, loadMore).map((value) => (
                <div className="boardMemberCard">
                  <img src={value.personImg} alt="" className="teamImg" />

                  <div className="card-area">
                    <div className="team-content">
                      <h4 className="">{value.name}</h4>
                      <small>{value.designation}</small> <br />
                      <small className="secondary-color">
                        Country: {value.country}
                      </small>
                      <p className="pt-2">
                        PI is governed by a Board of Directors in India
                      </p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
            <button onClick={LoadMoreHandle} className="primary-btn2">
              Load More
            </button>
          </div>
        </div>
      </div>
      <ContactAction title={"Join our global team and support us"} link="contact us" url="/contact" />
    </section>
  );
};

export default Management;
