import React from 'react';
import '../Banner/Banner.css';

const Banner2 = ({ bannerTitle, bannerImg, link, link2,url1,url2 }) => {
    return (
        <div className="main-image">
            <div className="bannerImg">
                <img src={bannerImg} alt="" />
            </div>
            <div className="overlay">
                <div className="banner-content2">

                    <h1 className="text-white">{bannerTitle}</h1>

                    <div className="banner-link text-center">
                        <a href={url1} className="text-white">{link} / </a>
                        <a href={url2} className="primary-color">{link2}</a>
                    </div>

                </div>
            </div>
        </div>
    );
};

export default Banner2;