import React, { useState } from "react";
import CountryData from "./CountryData";
import "../Home/OurActivity/OurAcitivites.css";
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
} from "react-share";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
  faFacebookF,
  faTwitter,
  faLinkedinIn,
} from "@fortawesome/free-brands-svg-icons";

//create your forceUpdate hook
function useForceUpdate() {
  const [value, setValue] = useState(0); // integer state
  return () => setValue((value) => value + 1); // update the state to force render
}

const Country = () => {
  const forceUpdate = useForceUpdate();
  window.scrollTo({
    top: 0,
  });

  const splitUrl = window.location.pathname.split("/");
  const Id = splitUrl[splitUrl.length - 1];

  const data = CountryData.find((data) => data.id === Id);

  const pageUrl = window.location.href;
  console.log(window.location.href);
  return (
    <div className="pt-md-5">
      <div
        style={{
          background: `url(${data.img})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          backgroundColor: "linear-gradient(10deg, #1c232798, #27303685)",
        }}
        className="blog-banner-sec d-flex justify-content-center "
      ></div>
      <div className="container blog-desc pt-5 pt-md-5  p-0 ">
        <div className="container px-5 py-2 blog-banner-heading text-center">
          <h2 className="pt-5">{data.name}</h2>
          <div className="d-flex flex-column blog-icons pt-md-5 py-0 py-md-3 pe-4">
            <small className="mb-2">
              <FacebookShareButton url={pageUrl}>
                <FontAwesomeIcon
                  icon={faFacebookF}
                  size="lg"
                  className="primary-color blog-social-icon"
                />
              </FacebookShareButton>
            </small>
            <small className="mb-2">
              <TwitterShareButton url={pageUrl}>
                <FontAwesomeIcon
                  icon={faTwitter}
                  size="lg"
                  className="primary-color blog-social-icon"
                />
              </TwitterShareButton>
            </small>
            <small className="mb-2">
              <LinkedinShareButton url={pageUrl}>
                <FontAwesomeIcon
                  icon={faLinkedinIn}
                  size="lg"
                  className="primary-color blog-social-icon"
                />
              </LinkedinShareButton>
            </small>
          </div>
        </div>
        <p className="pt-md-5 px-lg-5 px-3 pt-sm-5  blog-para" style={{ whiteSpace: 'pre-line' }}>{data.countryDetails}</p>
        <div className="text-center">
          <h5 className="pb-3">Download the full report as a pdf file</h5>
          <a className="primary-btn2 text-decoration-none" href="https://drive.google.com/uc?export=download&id=1ORq1rfw_KvRJX1SjIBF8w4gWS7Pswk2Q">Download The Report</a>
        </div>

        <div className="blog-bottom py-md-5 w-100 d-flex justify-content-evenly ">
          <h4 className="share-text">Sharing is Caring</h4>
          <div className="my-auto">
            <small className=" px-2 px-sm-3 ">
              <FacebookShareButton url={pageUrl}>
                <FontAwesomeIcon
                  icon={faFacebookF}
                  size="lg"
                  className="text-black"
                />
              </FacebookShareButton>
            </small>
            <small className=" px-2 px-sm-3">
              <TwitterShareButton url={pageUrl}>
                <FontAwesomeIcon
                  icon={faTwitter}
                  size="lg"
                  className="text-black"
                />
              </TwitterShareButton>
            </small>
            <small className="px-2 px-sm-3">
              <LinkedinShareButton url={pageUrl}>
                <FontAwesomeIcon
                  icon={faLinkedinIn}
                  size="lg"
                  className="text-black"
                />
              </LinkedinShareButton>
            </small>
          </div>
        </div>
      </div>
    </div >
  );
};

export default Country;
