import React from "react";
import Banner2 from "../Shared/Banner2/Banner2";
import ContactAction from "../Shared/ContactAction/ContactAction";
import bannerImg2 from "../images/affiliated.jpg";
import org1 from "../images/safadi-center.jpg";
import org2 from "../images/alience-of-the-people.png";
import org3 from "../images/bajarangadala-kannada-logo.png";
import "./AffiliatedOrganization.css";

import { Link } from "react-router-dom";

const organizationData = [
  {
    id: 1,
    org: "Safadi Center for International Diplomacy",
    img: org1,
    url: "https://safadicenter.com/",
  },
  {
    id: 2,
    org: "Alliance of the Peoples of the World",
    img: org2,
    url: "https://www.ipaow.org/",
  },
  {
    id: 3,
    org: "Bajrang Dal",
    img: org3,
    url: "https://bajrangdal.wildapricot.org/",
  },
];

const AffiliatedOrganization = () => {
  return (
    <section className="org-background">
      <Banner2
        bannerImg={bannerImg2}
        bannerTitle="Affiliated Organization"
        link="HOME"
        link2="AFFILIATED ORGANIZATION"
        url1="/"
        className="position-relative"
      />
      <div className="text-center mt-5">
        <h2>Organizations - Members and Partners</h2>
      </div>
      <div className="org-container custom-container top-gap bottom-gap">
        {organizationData.map((card) => (
          <Link to={{ pathname: `${card.url}` }} target="_blank" className="org-card text-decoration-none">
            <div className="org-img-sec">
              <img src={card.img} className="img-fluid" alt="" />
            </div>
            <div className="px-4 pt-4 textJustify org-desc">
              <h3 className="text-center">{card.org}</h3>
              {/* <p>{card.desc}</p> */}
              <p>{card.url}</p>
            </div>
          </Link>
        ))}
      </div>
      <ContactAction
        title="Want to work with us ?"
        link="contact us"
        url="/contact"
      />
    </section>
  );
};

export default AffiliatedOrganization;
