//Footer resources data
const FooterData4 = [
  {
    id: 1,
    list: "News & Media",
    url: "/",
  },
  {
    id: 2,
    list: "",
    url: "/",
  },
  {
    id: 3,
    list: "Bangladesh",
    url: "/country-data/Bangladesh/1",
  },
  {
    id: 4,
    list: "India",
    url: "/country-data/India/2",
  },
  {
    id: 5,
    list: "Global Report",
    url: "/country-data/global-report/3",
  },
  {
    id: 6,
    list: "Pakistan",
    url: "/Pakistan",
  },
];
export default FooterData4;
