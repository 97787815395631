import React from "react";
import "./Mission&vision.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuoteLeft } from "@fortawesome/free-solid-svg-icons";
import "../Home/OurActivity/OurAcitivites.css";
import { Helmet } from 'react-helmet';

import {
  faFacebookF,
  faTwitter,
  faLinkedinIn,
} from "@fortawesome/free-brands-svg-icons";

const Mission = () => {
  const pageUrl = window.location.href;
  return (

    <section>
      <Helmet>
        <meta name="title" content="Mission of WHSC" data-react-helmet="true" />
        <meta name="description" content="WHSC is an international Human rights organization who protect the people rights" data-react-helmet="true" />
        <meta name="keyword" content="...." data-react-helmet="true" />


        <meta property="og:url" content={pageUrl} data-react-helmet="true" />
        <meta property="og:title" content="Mission of WHSC" data-react-helmet="true" />
        <meta property="og:description" content="WHSC is an international Human rights organization who protect the people rights" data-react-helmet="true" />
        <meta property="og:image" content="https://cdn.pixabay.com/photo/2015/04/23/22/00/tree-736885__480.jpg" data-react-helmet="true" />


      </Helmet>
      <div className="mission-banner position-relative">
        <div className="mission-banner-desc2">
          <FontAwesomeIcon icon={faQuoteLeft} className="text-white fs-1 " />
          <p className="pt-2 ">
            World Hindu Struggle Committee here to provide a global platform for minorities to connect, share ideas, inspire one another, and impart common good in the society.
          </p>
        </div>
      </div>
      <div className="container blog-desc2  pt-3  p-0 ">
        <div className="container px-5 py-2  blog-banner-heading text-center">
          <h2>Mission</h2>
          <div className="d-flex flex-column blog-icons py-0 py-md-3 pe-4">
            <a href="#"><FontAwesomeIcon
              icon={faFacebookF}
              size="lg"
              className="primary-color blog-social-icon"
            /></a>
            <a href="#" className="my-2"><FontAwesomeIcon
              icon={faTwitter}
              size="lg"
              className="primary-color blog-social-icon"
            /></a>
            <a href="#"><FontAwesomeIcon
              icon={faLinkedinIn}
              size="lg"
              className="primary-color blog-social-icon"
            /></a>
          </div>
        </div>
        <p className="pt-5 px-lg-5 px-3 blog-para">
          World Hindu Struggle Committee here to provide a global platform for Hindus to connect, share ideas, inspire one another, and impart common good in the society. The Hindu minority in particular and other minorities in general, especially the minorities living in the Middle East and Asia, are persecuted and threatened with ethnic cleansing, as in Bangladesh, Arab and Islamic countries. It is our obligation in the free world to stand against any attempt to harm Hindu minorities and other minorities such as Buddhism and Christians in the Middle East, Druze, Yazidis, Assyrians, and others.<br />
          Some of our Key Mission’s are:<br />
          # to protect Hindu Heritages globally. Spread out peace and harmony, untie world community by teaching ancient Vedic Culture.<br />
          # to protect every human being by educating them in ancient Vedic Culture. Consolidate, strengthen and make invincible the global Hindu fraternity by following the eternal and universal values based on Hindu Sanatan Dharma (Hindu Dharma) and work for the total welfare of humanity.<br />
          # to bring about universal love, peace and harmony in the world by reiterating and reaffirming the fundamental principles to liquidate public passions.<br />
          # to create a think tank that will proactively articulate the holistic and integral perspectives on all vital issues of Hindu Dharma and Hindu Heritages globally.<br />
          # to establish chair for Hindu learning in various internal institutions, corporate houses, research institutes and foundations and universities globally.<br />
          # to publish books, booklets, pamphlets, journals, newspapers, etc.; to produce audios, videos and visual materials to harness the modern electronic mass media, such as the Internet and TV channels.<br />
          # to provide immediate relief and help to victimized human beings.<br />
          # to raise funds for immediate relief and to help and educate the poor families worldwide.</p >

        <p className="pt-5 px-lg-5 px-3 blog-para">Our activity in recent years to bring the voices of minorities to the international arena focuses mainly on diplomatic and political issues. Still, we also examine the legal possibilities to bring to the international court petitions against governments that violate human rights and harm minorities. The government of Bangladesh is mostly accountable for unlawful and criminal act of harming the minorities. Systematic and Ethnic cleansing against Hindus in the country is going on since independence. It is likely to be the first government to be sued by the International Court of Justice to stop human rights violations and ethnic cleansing against minorities. Violent crime have been committed against the minorities in the country and we appeal to the International Community to take heed of the grave situation and the systematic ethnic cleansing that is going on for decades in the country.

        </p>

        <div className="blog-bottom py-5 w-100 d-flex justify-content-evenly ">
          <h2 className="text-center text-white">World Hindu Struggle Committee</h2>
        </div>
      </div>

    </section>
  );
};

export default Mission;
