import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebookF,
  faTwitter,
  faLinkedinIn,
} from "@fortawesome/free-brands-svg-icons";
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
} from "react-share";
import "./CountryData.css";
import Helmet from "react-helmet";

const Pakistan = () => {
  const pageUrl = window.location.href;
  return (
    <section>
      <Helmet>
        {/* <!-- HTML Meta Tags --> */}
        <title>Global Report | World Human Struggle Committee</title>
        <meta name="keywords" content="donate, help, jakat" />
        <meta
          name="description"
          content="This is our global report for world human srtruggle committee"
        />
        <meta name="robots" content="index,follow" />
        <meta name="url" content="http://www.whsc.com/globalReport" />

        {/* <!-- Google / Search Engine Tags --> */}
        <meta
          itemprop="name"
          content="Global Report | World Human Struggle Committee"
        />
        <meta
          itemprop="description"
          content="This is our global report for world human srtruggle committee"
        />
      </Helmet>
      <div className="blog-banner-sec2 d-flex justify-content-center "></div>
      <div className="container blog-desc pt-5  p-0 ">
        <div className="container px-5 py-2 pt-5 blog-banner-heading text-center">
          <h2 className="pt-5">Pakistan </h2>
          <div className="d-flex flex-column blog-icons py-0 py-md-3 pe-4">
            <small className="mb-2">
              <FacebookShareButton url={pageUrl}>
                <FontAwesomeIcon
                  icon={faFacebookF}
                  size="lg"
                  className="primary-color blog-social-icon"
                />
              </FacebookShareButton>
            </small>
            <small className="mb-2">
              <TwitterShareButton url={pageUrl}>
                <FontAwesomeIcon
                  icon={faTwitter}
                  size="lg"
                  className="primary-color blog-social-icon"
                />
              </TwitterShareButton>
            </small>
            <small className="mb-2">
              <LinkedinShareButton url={pageUrl}>
                <FontAwesomeIcon
                  icon={faLinkedinIn}
                  size="lg"
                  className="primary-color blog-social-icon"
                />
              </LinkedinShareButton>
            </small>
          </div>
          <h3>The Report of Hindu Genocide in Pakistan</h3>
          <h5>More than 7 million Hindus & minorities killed, displaced, and forcibly converted in Pakistan since 1947.</h5>
        </div>

        <div className="pt-5 px-lg-5 px-3 blog-para">
          <p> <span className="para-heading">1 The Partition Massacres, 1946-1947</span><br />
            The exact number of casualties during the Partition violence will always remain a matter of debate. Estimates range from around 200,000 to one-and-a-half million. A British contemporary estimates claimed that 180,000 people died (Moon, 1998: 269, 293). Another contemporary estimates from India put the death toll at 500,000, approximately 200,000-250,000 non-Muslims and 200,000-250000 Muslims (Khosla, 1999: 298-99). According to Butalia, it would be closer to 1,000,000 dead, half of which are Muslims and the other half non-Muslims (Butalia, 1998: 1). However, Brass noted that a consensus figure would today be around 500,000 but that the sources that are most likely closer to the truth give figures that range between 200,000 and 360,000 (Brass, 2006: 18). The estimate given by Corruccini and Kaul is close to this as they put a maximum of 400,000 deaths with a wide margin of error that is plus or minus 100,000 (Corruccini and Kaul, 1990: 37). It further generated at least 13 million refugees, out of which 10 million from Punjab alone, comprising four and half million non-Muslims and five and half million Muslims (Hansen, 2002: 1). Besides, approximately 75,000 women have been raped and/or abducted across the two sides of the new border, placing them as the first victims of the Partition Massacres (Butalia, 1998: 1).
          </p>
          <p>
            While some argue that the violence that erupted at the moment of Partition was popular and spontaneous and that it can’t be considered as a general phenomenon due to the non-involvement of large-scale organizations (Alam, 1998: 98), the nature and the extent of the violence clearly underline the organized and planned character of the attacks. Furthermore, it suggests the involvement of private armies such as the Muslim League National Guard, the Rashtriya Swayam Sevak Sangh (RSS) and the Akal Fauj, counting respectively 42,300, 59,200 and at least 8,000 members (Hansen, 2002: 135). Although the State did not directly participate in the violence, the communalized role of the police, the complicity if not direct involvement of the political leaderships and the State’s attitude of laissez-faire point to its responsibility (Virdee, 2007: 16-36; Khan, 2007: 36-60). During its existence, between August 1 and 31, the 50,000 men Punjab Boundary Force was unable or unwilling to maintain peace and order (Jeffrey, 1974: 491-520).
          </p>
          <p>
            Violence was not just a marginal phenomenon, a sudden and spontaneous communal frenzy that accompanied Partition. It was on the contrary at the very heart of the event. Nor was it merely a consequence of Partition but rather the principal mechanism for creating the conditions for Partition. Violence constituted the moral instrument through which the tension between the pre-Partition local character of identity and its postcolonial territorial and national redefinition was negotiated (Gilmartin, 1998: 1069-1089). Violence operated as the link between the community and its new national territory. That is precisely what gave it its organized and genocidal dimension as it was meant for control of social space so as to cleanse these territories from the presence of other religious communities (Hansen, 2002).
          </p>

          <p>
            The most frequent form of collective violence was the attack by huge crowds upon villages, trains, refugee camps and long files of migrants. It generally took the shape of tit-for-tat mass murders, raids on villages and train stations, abduction, loot, arson, derailment of train and stabbing of the passengers, castration, mutilation and rape. Sexual molestation of women was deliberately meant to emphasize the vulnerability of the community and the incapability of men as protectors. As a result, some women were killed by members of their own families and communities, in order to avoid the honor of the whole community to be tarnished by atrocities against them (Butalia, 1998: p. 171). This practice was used particularly among Sikhs. The Muslim League’s direct actions strongly contributed to the spread of violence from August 1946 to March 1947. The Rawalpindi massacre had a huge impact upon the Sikhs. According to many witnesses and analysts such as Governor of Punjab Sir Jenkins and Field Marshall Sir Claude Auchinleck, Sikhs, and especially their leaders such as Master Tara Singh and Giani Kartar Singh, bear an important responsibility in the development of violence, particularly in East Punjab in August 1947 (Brass, 2003: 81; Hansen, 2002: 14). In West Punjab, Muslims carried out the same kind of violence against non-Muslims. Thus, the Partition violence seems, on the one hand, to be best described as a retributive genocide characterized by the forced expulsion of the opposed communities on both sides of the new boundary to establish a Muslim State, free of troublesome minorities, and on the other hand a consolidation and regroupment of the Sikh community as well as revenge and retaliation. To this must also be added local acts of violence carried out for a multiplicity of reasons and motives that were not genocidal in intent: loot, capture of property, abduction of women (Brass, 2006: 12, 48).
          </p>
          <p>
            During the forties, the advent of independence generated increasing awareness among Hindus, Sikhs and Muslims – who composed respectively 31%, 15% and 53% of the population in Punjab; and 54.7% of Muslims and 41.5% of Hindus in Bengal (Census of India, 1941) – that it will imply the partition of the British Raj between two independent dominions, namely India and Pakistan. Whether the Partition of the British Raj was ineluctable or not will always remain a matter of debate. However, it appears that it was rather late, actually only after the failure of the Cabinet Mission in May-June 1946, that the project of partitioning British India acquired a territorial meaning. Until the May 16 Plan, the main demand was the establishment of a loose confederation formed by Hindu-majority and Muslim-majority provinces under the authority of a weak center. The impossibility or reluctance of reaching an agreement on that matter between the leaders of the Muslim League and the Indian National Congress made the territorial partition of the British Raj the only available option.
          </p>
          <p>
            Violence first erupted in Calcutta, Bengal, on August 16, 1946, one year before Independence. It then spread in the rest of the province, especially in Noakhali on October 1946, as well as in Bihar. It subsequently receded but then peaked again in March 1947, this time in Punjab, which was to become the epicenter of Partition violence. The hastily announcement on June 3, 1947, of the Partition Plan by Lord Mountbatten, which officialized the territorial partition of the British Raj on August 15, 1947, triggered unprecedented acts of communal violence, especially in the two Muslim majority provinces Bengal and Punjab that were to be divided. Communal violence climaxed between August 13 and 19, just before and after the Independence and the release of the Boundary Award. During the night of August 14 to 15, Pakistan and India became independent Nation States due to the dismantlement of the British Raj. The exact layout of the international border that was to divide Punjab between India and Pakistan was still not known at the time of Independence. It is only on August 16, 1947, that the Punjab Boundary Award was finally made public. This further aggravated the communal violence. During the following months until October 1947, the province of Punjab was the scene of numerous mass killings between Hindus and Sikhs on the one side, and Muslims on the other side.
          </p>
          <p>
            <span className="para-heading">1946; August 16, The Direct Action Day or  <a href="https://www.sciencespo.fr/mass-violence-war-massacre-resistance/en/document/calcutta-riots-1946.html"> the Great Calcutta Killings:</a> </span> Muhammad Ali Jinnah, leader of the Muslim League, chose August 16, 1946, to launch a “direct action for the achievement of Pakistan” following his refusal of the Cabinet Mission’s plan and his decision to boycott the newly elected Constituent Assembly. On that day, which was declared as a provincial public holiday by Hussain Shaheed Suhrawardy (Chief Minister of Bengal), Muslims in Calcutta were asked to stop their activity and to stage a protest in the streets of the city. In the morning, Muslim volunteers forced Hindu shopkeepers to close their shops in the Northern part of Calcutta. Then, Hindus answered by blocking the advance of the Muslim League’s processions toward the Ochterloney Monument. Violence spread quickly as both the police and the military were prevented by Suhrawardy from interfering in the riots.
          </p>
          <p>
            The official estimate of casualties, mostly Muslims, varies between 5,000 dead and 15,000 injured (Moon, 1998: 58) and 4,000 dead and 10,000 injured according to another (Hansen, 2002: 9) and 10,000 killed (Sengupta, 2007: 133). The role of the Muslim League in the organization of the riots is undeniable in regard of arming and transporting its followers. However, if Muslims were clearly the initial aggressors, non-Muslims, especially Sikhs, retaliated, hence aggravating the death toll dramatically. It is only on the fourth day of the riot, while violence was increasing, that the army intervened. Finally, violence decreased on August 22. Following the riot, thousands started fleeing Calcutta. Some were caught in the violence that spread from Calcutta to the entire Bengal region and even in Bihar. *** (Moon, 1998; Hansen, 2002; Sengupta, 2007).
          </p>
          <p>
            <span className="para-heading">1946; October 10, The Noakhali Anti-Hindu Massacre:</span> Retaliatory violence against Hindus then spread in Noakhali district and in some parts of the adjacent Tripura district. The majority of the population in the area was Muslim, around 82%, while most of the land belonged to Hindu landlords. As a consequence of the riots in Calcutta, a massive anti-Hindu pogrom was organized by Muslim locals so as to cleanse the region from Hindu presence either by killing them or by forcing them to flee the area. The death toll is close to 5,000 dead according to the press (Sengupta, 2007: 138), though Moon considered that it should rather be counted in hundreds (Moon, 1998: 59). It is claimed that nearly 75% of Hindus previously in the area left the place. ** (Sengupta, 2007; Moon, 1998).
          </p>
          <p><span className="para-heading">
            1947; The Bihar Anti-Muslim Riots:</span> The cycle of retributive violence went on in Bihar where anti-Muslim riots broke out at the beginning of 1947. The constant flux of non-Muslims refugees from Bengal to Bihar and the news they propagated, especially of killings of Hindu Biharis in Calcutta and Noakhali, propelled armed bands of Hindus to attack Muslim villages in the Bihar countryside as acts of revenge. Village after village were looted and burnt and their population killed, resulting in the death of thousands of Muslims in the province by armed groups of Hindus. ** (Sengupta, 2007: 143).
          </p>

          <p><span className="para-heading">
            1947; March 3, The Rawalpindi Massacre or the March Killings:</span> Khirz Hayat Khan Tiwana’s Punjabi government (a coalition government gathering Congressists, Akalis and Unionists, under the leadership of the latter) resigned under the pressure of Muslim League’s demonstrations and direct actions. The day after, Hindus and Sikhs staged a counter-demonstration and threatened Muslims. The latter then retaliated and launched large-scale attacks on Hindus and Sikhs in the frontier districts, especially in Rawalpindi division.<br />

            By March 20, when law and order were finally restored, 2,049 people were killed and 1,103 injured, most of them Sikhs (Jeffrey, 1974: 494). Another estimate, though biased against Muslims, counts 2,263 non-Muslims and 38 Muslims casualties in Rawalpindi district alone (Khosla, 1999: 112) while another one counts 3,000 killed and 1,200 injured (Hansen, 2002: 11). *** (Jeffrey, 1974; Khosla, 1999; Hansen, 2002).
          </p>
          <p>
            <span className="para-heading">1947; August, The Gory Climax or the August Anarchy</span><br />
            1947; August 6: More than sixty people, essentially Muslims, were declared killed in Amritsar district, allegedly by Sikhs opposing Partition as it would divide their community and reduce them to a mere minority in both new countries (Jeffrey, 1974: 502).<br />

            1947; August 8: Muslims retaliated and killed seventy-four Hindus and Sikhs near Jalalabad, north of the Ludhiana-Ferozepur road, in Ferozepur District (Jeffrey, 1974: 503). During the first week of August, casualties are estimated at an average daily killing of about 100 people with sporadic raids killing 70 to 80 people (Brass, 2003: 87).<br />

            1947; August 13 to 19: Violence peaked during this week.In addition to derailment – the first case being the one of a “Pakistani Special” train carrying Pakistani government employees and their families from Delhi to Karachi, next to Patiala State in East Punjab, killing one woman and one child (Aiyar, 1998: 18: Hansen, 2002: 14) – attacks on train and stabbing of the passengers began. Lahore train station also became the scene of bloody carnages. On August 13, 43 non-Muslims were stabbed in the Mughalpura Railway Workshops. On August 14, 35 Sikhs were stabbed in Lahore station. On August 15, a train was held up near Wazirabad. On August 15 to 16, a Pakistani Special train was derailed next to Amritsar. Two train derailments occurred in Sialkot between August 14 and 17. Three trains were attacked by Muslims mobs in the Wazirabad-Sialkot area. Two attacks on train happened in Rawalpindi area. (Aiyar, 1998: 18-19) Notwithstanding the train attacks, bands of armed men were going on raiding villages and killing their inhabitants.<br />

            1947; August 26: A group of Pakistani Baluchi soldiers killed around 10,000 non-Muslim civilians in Sheikhupura and raped girls and women so as to dishonor the whole community and to prove its inability to protect them (Hansen, 2002: 15).<br />

            1947; September 3: Bands of Muslims and Hindus fought each other at Harnoil in Mianwali district. Muslims were supported by Pakistani military and used tanks against their opponents. The total death toll is over 3,000 (Hansen, 2002: 17: Kirpal Singh, 1972: 150).<br />

            1947; September 21: A refugee train was attacked during the night by armed Muslims at Harbanspura making 1,500 non-Muslim victims (Aiyar, 1998: 21). Finally, by October 1947, the situation improved in both parts of Punjab. However, if law and order was relatively reinstated, the burden of the rehabilitation of refugees remained till the end of 1947.<br />

            <small>*** (Kirpal Singh, 1972; Jeffrey, 1974; Aiyar, 1998; Brass, 2003).</small>

          </p>
          <div className="text-center">
            <h5>If you want to know more about Hindu Genocide in Pakistan then download the pdf report from the link below.</h5>
            <p className="text-warning">Here is another resource to know about <a href="https://hindugenocide.com/islamic-jihad/4million-hindus-persecuted-west-pakistan-partition-plight-hindus-remained/" target="_blank">the Hindu Genocide</a> </p>
          </div>
        </div>
        <div className="text-center">
          <h5 className="pb-3">Download Full Report As a PDF File</h5>

          <a className="primary-btn2 text-decoration-none" href="https://drive.google.com/uc?export=download&id=1ORq1rfw_KvRJX1SjIBF8w4gWS7Pswk2Q">Download The Report</a>

        </div>


        <div className="blog-bottom py-5 w-100 d-flex justify-content-evenly ">
          <h4 className="share-text">Sharing is Caring</h4>
          <div className="my-auto">
            <small className=" px-2 px-sm-3 ">
              <FacebookShareButton url={pageUrl}>
                <FontAwesomeIcon
                  icon={faFacebookF}
                  size="lg"
                  className="text-black"
                />
              </FacebookShareButton>
            </small>
            <small className=" px-2 px-sm-3">
              <TwitterShareButton url={pageUrl}>
                <FontAwesomeIcon
                  icon={faTwitter}
                  size="lg"
                  className="text-black"
                />
              </TwitterShareButton>
            </small>
            <small className="px-2 px-sm-3">
              <LinkedinShareButton url={pageUrl}>
                <FontAwesomeIcon
                  icon={faLinkedinIn}
                  size="lg"
                  className="text-black"
                />
              </LinkedinShareButton>
            </small>
          </div>
        </div>


      </div>
    </section>
  );
};

export default Pakistan;
