import React, { useState } from "react";
import "./../Card.css";
import bannerImg2 from "../../../images/managementTeam.jpg";
import Banner2 from "../../../Shared/Banner2/Banner2";
import Sirwan from "../../../images/our-people/media-cell/Sirwan-Mansouri.png";
import Bishwajit from "../../../images/our-people/media-cell/Bishwajit-Guha.png";
import Selina from "../../../images/our-people/media-cell/Selina-Desirce-Nevilis.png";
import Sanjay from "../../../images/our-people/media-cell/Sanjay-Biswas.png";
import Nihar from "../../../images/our-people/media-cell/Nihar-Ranjan-Biswas.png";
import Avraham from "../../../images/our-people/media-cell/Rachel-Golda-Avraham.png";
import { Link } from "react-router-dom";

const MediaData = [
  {
    name: "Rachel Golda Avraham",
    designation: "Chief Advisor (Media Cell)",
    personImg: Avraham,
    country: "Israel",
  },
  {
    name: "Sirwan Mansouri",
    designation: "Media Cell Advisor",
    personImg: Sirwan,
    country: "Turkey",
  },
  {
    name: "Bishwajit Guha",
    designation: "Media Chief",
    personImg: Bishwajit,
    country: "india",
  },
  {
    name: "Selina Desirce Nevilis",
    designation: "Media Cell",
    personImg: Selina,
    country: "USA",
  },
  {
    name: "Sanjay Biswas",
    designation: "Media Cell Assistant",
    personImg: Sanjay,
    country: "Bangladesh",
  },
  {
    name: "Nihar Ranjan Biswas",
    designation: "Media Cell Assistant",
    personImg: Nihar,
    country: "Bangladesh",
  },

];

const MediaCell = () => {
  const [loadMore, setLoadMore] = useState(6);
  const LoadMoreHandle = () => {
    setLoadMore((prevValue) => prevValue + 6);
  };
  return (
    <section>
      <Banner2
        bannerImg={bannerImg2}
        bannerTitle="Board of Media Cell"
        link="Our People"
        link2="Media Cell"
        url1="/ourPeople"
        className="position-relative"
      />
      <div className="container">
        <div className="py-5">
          <div className="text-center ">
            <div className="heading-style4 ">
              <h2 className="text-black">Board of Media Cell</h2>
            </div>
            {/* <p>
              PI is governed by a Board of Directors which is responsible for
              the overall strategy and governance of the organisation.
            </p> */}

            <div className="py-5 row g-4">
              {MediaData.slice(0, loadMore).map((value) => (
                <div className="col-lg-4 col-md-6">
                  <div class="AdmissionCard">
                    <div class="admission-single-card">
                      <div class="imgBx">
                        <img src={value.personImg} />
                      </div>
                      <div class="contentBx">
                        <h2>{value.name}</h2>
                        <div className="admission-card-overlay">
                          <div className="card-designation">
                            <p>Country: {value.country}</p>
                            <small>{value.designation}</small>
                          </div>
                          {/* <p>
                            PI is governed by a Board PI is governed by a Board
                            of Directors in India
                          </p> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
            <button onClick={LoadMoreHandle} className="primary-btn2">
              Load More
            </button>
          </div>
        </div>
      </div>
      <div className="TopSubFooterBg">
        <div className="custom-container">
          <div className="row TopSubFooter">
            <div className="col-md-8 my-auto">
              <h3 className="my-auto text-uppercase mb-2">Become a Part of Our Global Team</h3>
            </div>
            <div className="my-auto col-md-4">
              <Link to="/membership"><button className="">Join Us Now</button></Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default MediaCell;
