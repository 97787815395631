import React from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import BlogData2 from "./OurActivity/BlogData2";

const Activities = () => {
  return (
    <section id="our-activity" className="top-gap ">
      <div className="heading-style3 ">
        <h2>Our Activities</h2>
      </div>
      <div className="activities middle-section">
        <div className="cards-section2 ">
          {BlogData2.map((card) => (
            <div className="card-area2  px-3 text-uppercase">
              <h2 className="m-auto text-center">{card.name}</h2>
              <a href="/" className="card-arrow">
                <FontAwesomeIcon
                  icon={faArrowRight}
                  className="primary-color fs-3"
                />
              </a>
              <Link
                to={`/our-activity/${card.address}/${card.id}`}
                className="card-arrow"
              >
                <FontAwesomeIcon
                  icon={faArrowRight}
                  className="primary-color fs-3"
                />
              </Link>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Activities;
