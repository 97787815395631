import React from "react";

const hexaInfo = [
  {
    id: 1,
    heading: "Education",
    desc: "To make sure education for all Minorities Children for self sustenance.",
  },
  {
    id: 2,
    heading: "Digital Transformation",
    desc: "To create new or modify existing business processes, culture to meet changing modern world requirements.",
  },
  {
    id: 3,
    heading: "Democracy Governance",
    desc: "Minorities are also the citizen of every state so they should have the same rights.",
  },
  {
    id: 4,
    heading: "Community Building",
    desc: "Building a field of practice directed towards the creation of community with a common need.",
  },
  {
    id: 5,
    heading: "Peace & Conflict Resolution",
    desc: "Works to the non-violent management and resolution of conflicts.",
  },
  {
    id: 6,
    heading: "Youth Empowerment",
    desc: "To make the youth aware of their rights and put them into practice we are working on.",
  },
  {
    id: 7,
    heading: "Online Business",
    desc: "Create online platform for Minorities and assist in self-sustained businesses.",
  },

];

const DevelopmentGoal = () => {
  return (
    <section id="development-goal" className="pt-5">
      <div className="goal-cover py-5">
        <div className="heading-style4 ">
          <h2>Our Development Goal</h2>
        </div>

        <div className="px-2 ">
          <ul id="hexGrid">
            {hexaInfo.map((info) => (
              <li class="hex">
                <div class="hexIn " href="#">
                  <h2>{info.heading}</h2>
                  <p className="honey-desc">{info.desc}</p>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </section>
  );
};

export default DevelopmentGoal;
