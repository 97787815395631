// import firebase from "firebase/app";
import firebase from 'firebase/compat/app';
import "firebase/compat/storage";

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
// const firebaseConfig = {
//     apiKey: "AIzaSyAipkB0aoIsDFg6oP4DfNDOQ2RR8WTGYM4",
//     authDomain: "whsc-images.firebaseapp.com",
//     projectId: "whsc-images",
//     storageBucket: "whsc-images.appspot.com",
//     messagingSenderId: "1072722325893",
//     appId: "1:1072722325893:web:e114685d2fd70c1188d4ec",
//     measurementId: "G-NBZE0B7J00"
// };

const firebaseConfig = {
    apiKey: "AIzaSyAy44RwAE9XWsS-UsLb4UQ-mXpOxXEtRQ8",
    authDomain: "whsc-certificate.firebaseapp.com",
    projectId: "whsc-certificate",
    storageBucket: "whsc-certificate.appspot.com",
    messagingSenderId: "92609293984",
    appId: "1:92609293984:web:03842f17c2657fd10b23c5",
    measurementId: "G-J9GQQHJEMD"
};

firebase.initializeApp(firebaseConfig);

const storage = firebase.storage();

export { storage, firebase as default };

// var app = firebase.initializeApp(firebaseConfig)