import React from "react";
import Banner from "../../Shared/Banner/Banner";
import "./OurPeople.css";
import bannerImg2 from "../../images/ourpeople.jpg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import ContactAction from "../../Shared/ContactAction/ContactAction";
import Chairman from "../../images/our-people/shipan-kumer-basu.png";


const TeamData = [
  {
    title: "Board of Advisors",
    desc: "The World Hindu Struggle Committee (WHSC) is an international organization that advocates for the rights and interests of Hindus worldwide. They work to address issues of discrimination, persecution, and human rights violations against Hindus, while also promoting Hindu culture and heritage.",
    link: "/advisors",
    id: "01",
  },
  {
    title: "Our Patrons",
    desc: "The World Hindu Struggle Committee (WHSC) is an international organization that advocates for the rights and interests of Hindus worldwide. They work to address issues of discrimination, persecution, and human rights violations against Hindus, while also promoting Hindu culture and heritage.",
    link: "/patron",
    id: "02",
  },

  {
    title: "Executive Committee",
    desc: "The World Hindu Struggle Committee (WHSC) is an international organization that advocates for the rights and interests of Hindus worldwide. They work to address issues of discrimination, persecution, and human rights violations against Hindus, while also promoting Hindu culture and heritage.",
    link: "/executive-committee",
    id: "03",
  },
  {
    title: "Religious and Social Welfare",
    desc: "The World Hindu Struggle Committee (WHSC) is an international organization that advocates for the rights and interests of Hindus worldwide. They work to address issues of discrimination, persecution, and human rights violations against Hindus, while also promoting Hindu culture and heritage.",
    link: "/religious-and-social-advisor",
    id: "04",
  },
  {
    title: "Board of Security",
    desc: "The World Hindu Struggle Committee (WHSC) is an international organization that advocates for the rights and interests of Hindus worldwide. They work to address issues of discrimination, persecution, and human rights violations against Hindus, while also promoting Hindu culture and heritage.",
    link: "/security-board",
    id: "05",
  },
  {
    title: "Legal Support Desk",
    desc: "The World Hindu Struggle Committee (WHSC) is an international organization that advocates for the rights and interests of Hindus worldwide. They work to address issues of discrimination, persecution, and human rights violations against Hindus, while also promoting Hindu culture and heritage.",
    link: "/support-desk",
    id: "06",
  },
  {
    title: "Board of Media Cell",
    desc: "The World Hindu Struggle Committee (WHSC) is an international organization that advocates for the rights and interests of Hindus worldwide. They work to address issues of discrimination, persecution, and human rights violations against Hindus, while also promoting Hindu culture and heritage.",
    link: "/media-cell",
    id: "07",
  },
  // {
  //   title: "Finance and Accounting",
  //   desc: "The World Hindu Struggle Committee (WHSC) is an international organization that advocates for the rights and interests of Hindus worldwide. They work to address issues of discrimination, persecution, and human rights violations against Hindus, while also promoting Hindu culture and heritage.",
  //   link: "/country-committee",
  //   id: "08",
  // },
  // {
  //   title: "Worldwide Volunteers",
  //   desc: "The World Hindu Struggle Committee (WHSC) is an international organization that advocates for the rights and interests of Hindus worldwide. They work to address issues of discrimination, persecution, and human rights violations against Hindus, while also promoting Hindu culture and heritage.",
  //   link: "/volunteers",
  //   id: "9",
  // },
];

const OurPeopleData = () => {
  return (
    <section>

      <div>
        <Banner
          bannerImg={bannerImg2}
          bannerTitle="Our People"
          bannerDesc="Children need your support, urgently. Kindly donate with an open arm and let us help the most vulnerable."
          className="position-relative"
        />
      </div>
      <div className="banner-box text-center custom-container pt-2">
        <h2 className="fw-bold">World Hindu Struggle Committee</h2>
        <p>
          World Hindu Struggle Committee works with international human rights
          organizations to establish Human Rights worldwide. The aim is to protect,
          empower, and support human rights organizations and defenders at the
          international level. Ultimately, the WHSC aim at strengthening civil
          society and advancing human rights.
        </p>
      </div>

      <div className="custom-container py-3">
        <div className="" id="scrollDown">
          {/* <div className="heading-style3 ">
            <h2 className="text-black">Team WHSC</h2>
          </div> */}

          <div className="text-center">
            <div>
              <img src={Chairman} height="200"></img>
              <h4>Shipan Kumer Basu</h4>
              <h6>Honorable President</h6>
            </div>
            <div className="pt-2">
              <p className="handwrite">Dear Friends,<br />“We strive to build a world that is wonderful, beautiful and charming by propagating Peace. We can achieve harmony, progress, tranquillity only through Peace. We work in connecting souls together from different parts of the world and to remove barbed wire boundaries that divide nations and plague our minds of divergence. We want to connect people to amalgamate culturally, morally, respect and inspire each other, share ideas and to uphold values of Human Right as paramount importance to impart a common good for our beloved people in this world. We aspire to build every human being resourcefully to shun animosity, greed and violence.” Because nothing is impossible, and the word itself says ‘i - m - possible’!</p>
            </div>
          </div>

          <div className="row gy-3 justify-content-center pt-5">
            {TeamData.map((data) => (
              <div className="col-xl-4 col-md-6">
                <div className="card team-card">
                  <span className="font-bold fs-2">{data.title}</span>
                  <p>{data.desc}</p>
                  <a href={data.link} rel="noreferrer">
                    View Details{" "}
                    <FontAwesomeIcon
                      className="primary-color"
                      icon={faArrowRight}
                    />
                  </a>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div>
        <ContactAction
          title="Become a Part of Our Global Team"
          link="Join Us Now"
          url="/membership"
        />
      </div>
    </section>
  );
};

export default OurPeopleData;
