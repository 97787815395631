import React from 'react';
import error from '../../images/error.gif';

const Error = () => {
    return (
        <div className="container py-5">
            <div className="text-center py-5">
                <h1 className="error">Oops!</h1>
                <img src={error} className="errorImg" alt="error"/>
                <h3>404 - PAGE NOT FOUND</h3>
                <p className="py-1">Something went wrong.
                    Please check your connection and try again.</p>
                <a href="/" className="primary-btn3 text-decoration-none">Go Back to Home Page</a>
            </div>
        </div>
    );
};

export default Error;