import React, { useState } from "react";
import "./../Card.css";
import cardTop from "../../../About/OurPeople/OurPeopleImg/cardTop.png";
import bannerImg2 from "../../../images/boardDirector.jpg";
import Banner2 from "../../../Shared/Banner2/Banner2";
import Amitav from "../../../images/our-people/advisor/Amitabha-Mookherjee.png";
import Safadi from "../../../images/our-people/patron/mendi-safadi.png";
import Frank from "../../../images/our-people/international-advisor/Frank-Creyelman.png";
import Dragana from "../../../images/our-people/international-advisor/Dragana-Trifkovicas.png";
import Prince from "../../../images/our-people/international-advisor/Prince-OF-Montenegro-and-Macedonia.png";
import Noor from "../../../images/our-people/international-advisor/Noor-Dahri.png";
import Morales from "../../../images/our-people/international-advisor/Morales-Cruz-Erika.png";
import Monayem from "../../../images/our-people/international-advisor/Monayem.png";
import Manel from "../../../images/our-people/international-advisor/Manel-Salmi.png";
import Ishkhan from "../../../images/our-people/international-advisor/Ishkhan-Rasoev.png";
import Aphinita from "../../../images/our-people/international-advisor/Aphinita-Chaichana.png";
import Jamila from "../../../images/our-people/international-advisor/Jamila-Abdullahyeva.png";
import Miri from "../../../images/our-people/advisor/Miri-Furstenbery.png";
import Tulashi from "../../../images/our-people/advisor/Tulasi-Narayana-Murthy-Kalyanadurgam.png";
import Portonov from "../../../images/our-people/advisor/Portonov-Pavel.png";
import Salih from "../../../images/our-people/advisor/Salih-Salih.png";
import Thirthnath from "../../../images/our-people/advisor/Thirthnath-Aghori.png";
import Norman from "../../../images/our-people/advisor/Norman-Simon.png";
import Ariela from "../../../images/our-people/advisor/Ariela-Levavi.png";
import Noam from "../../../images/our-people/advisor/Noam-Slonim.png";
import RajeshSharma from "../../../images/our-people/advisor/Prof-Rajesh-Sharma.png";
import Aslam from "../../../images/our-people/advisor/Aslam-Chowdhury.png";
import Mohammad from "../../../images/our-people/advisor/Mohamad-Najdat.png";
import Mariya from "../../../images/our-people/advisor/Mariya-Retali.png";
import Vadro from "../../../images/whsc.jpg";
import Bedri from "../../../images/our-people/advisor/Bedri-Yalcin.png";
import Chhek from "../../../images/our-people/advisor/Chhek-Dukpa.png";
import Mahmoud from "../../../images/our-people/advisor/Mahmoud-Morshedy.png";
import Fraidoon from "../../../images/our-people/advisor/Fraidoon-Hussein-Ahmed.png";
import Umberto from "../../../images/our-people/advisor/Umberto-Puato.png";
import Purbasha from "../../../images/our-people/advisor/Purbasha-Mondal.png";
import Saad from "../../../images/our-people/advisor/Saad-Yakeen.png";
import Winfred from "../../../images/our-people/advisor/Winfred-Wanjiku-Gitonga.png";

import Ahmad from "../../../images/our-people/advisor/Ahmad-Mrini.png";
import Humzan from "../../../images/our-people/advisor/Humzan-Abbas-Hamzan.png";
import Zaki from "../../../images/our-people/advisor/Zaki-Mustafa.png";
import Tusher from "../../../images/our-people/advisor/Tusher-Kanti-Sarker.png";
import Sathit from "../../../images/our-people/advisor/Sathit-Kumar.png";
import Hilbert from "../../../images/our-people/advisor/Hilbert-Nwachukwu.png";
import Yusuf from "../../../images/our-people/advisor/Yusuf-Saifuzzaman.png";
import Paolo from "../../../images/our-people/advisor/Paolo-Bragangna.png";
import David from "../../../images/our-people/advisor/David-Haivri.png";
import Paul from "../../../images/our-people/advisor/Paul-Taylor.png";
import Irina from "../../../images/our-people/advisor/Irina-Tsukerman.png";
import Jorg from "../../../images/our-people/advisor/Jorg-Sobolewski.png";
import Omarov from "../../../images/our-people/advisor/Omarov-Nariman-Sharabutinvich.png";
import Eric from "../../../images/our-people/advisor/Eric-Max-Gozlan.png";
import Tamar from "../../../images/our-people/advisor/Tamar-Lolishvili.png";
import Gaurav from "../../../images/our-people/advisor/Gaurav-Sachar.jpg";
import { Link } from "react-router-dom";




const BoardMember = [

  {
    name: "Mendi N Safadi",
    designation: "Chief International Counsil & Human Rights",
    personImg: Safadi,
    country: "Israel",
  },
  {
    name: "Frank Creyelman",
    designation: "International Counsil Advisor",
    personImg: Frank,
    country: "Belgium",
  },
  {
    name: "Dragana Trifkovicas",
    designation: "International Counsil Advisor",
    personImg: Dragana,
    country: "Syberia",
  },
  {
    name: "HRH Prince of Montenegro and Macedonia",
    designation: "International Counsil Advisor",
    personImg: Prince,
    country: "Italy",
  },
  {
    name: "Noor Dahri",
    designation: "International Affairs Advisor",
    personImg: Noor,
    country: "UK",
  },
  {
    name: "Dr Morales Cruz Erika",
    designation: "International Affairs Advisor",
    personImg: Morales,
    country: "Mexico",
  },
  {
    name: "MA Monayem",
    designation: "International Affairs Advisor",
    personImg: Monayem,
    country: "Bangbladesh",
  },
  {
    name: "Manel Salmi",
    designation: "International Affairs Advisor",
    personImg: Manel,
    country: "Belgium",
  },
  {
    name: "Ishkhan Rasoev",
    designation: "International Affairs Advisor",
    personImg: Ishkhan,
    country: "Russia",
  },
  {
    name: "Aphinita Chaichana",
    designation: "Public Relations And Youth Solidarity Advisor",
    personImg: Aphinita,
    country: "Thailand",
  },
  {
    name: "Jamila Abdullahyeva",
    designation: "Public Relations And Youth Solidarity Advisor",
    personImg: Jamila,
    country: "Azerbaijan",
  },

  {
    name: "Mr Amitabha Mookherjee",
    designation: "Geo-Political Advisor",
    personImg: Amitav,
    country: "India",
  },
  {
    name: "	Miri Furstenbery",
    designation: "Financial Advisor",
    personImg: Miri,
    country: "Israel",
  },
  {
    name: "Tulasi Narayana Murthy",
    designation: "Financial Advisor",
    personImg: Tulashi,
    country: "India",
  },
  {
    name: "Portonov Pavel",
    designation: "Cultural Advisor",
    personImg: Portonov,
    country: "Russia",
  },
  {
    name: "Salih Salih",
    designation: "Cultural Advisor",
    personImg: Salih,
    country: "Israel",
  },
  {
    name: "Thirthnath Aghori",
    designation: "Cultural Advisor",
    personImg: Thirthnath,
    country: "UK",
  },

  {
    name: "Norman Simon",
    designation: "Chief Human Rights Advisor",
    personImg: Norman,
    country: "Canada",
  },
  {
    name: "Ariela Levavi",
    designation: "Human Resource Development Advisor",
    personImg: Ariela,
    country: "Israel",
  },
  {
    name: "Noam Slonim",
    designation: "Human Resource Development Advisor",
    personImg: Noam,
    country: "Israel",
  },
  {
    name: "Prof Rajesh Sharma",
    designation: "Human Resource Development Advisor",
    personImg: RajeshSharma,
    country: "India",
  },
  {
    name: "Aslam Chowdhury",
    designation: "Human Rights And Political Advisor",
    personImg: Aslam,
    country: "Bangladesh",
  },
  {
    name: "Mohamad Najdat",
    designation: "Political Affairs Advisor",
    personImg: Mohammad,
    country: "Pakistan",
  },
  {
    name: "Mariya Retali",
    designation: "Human Rights Advisor",
    personImg: Mariya,
    country: "Greece",
  },
  {
    name: "Vadro Mohican",
    designation: "Human Rights Advisor",
    personImg: Vadro,
    country: "Israel",
  },
  {
    name: "Bedri Yalcin",
    designation: "Human Rights Advisor",
    personImg: Bedri,
    country: "Turkey",
  },
  {
    name: "Chhek Dukpa",
    designation: "Human Rights Advisor",
    personImg: Chhek,
    country: "Bhutan",
  },
  {
    name: "Dr Mahmoud Morshedy",
    designation: "Human Rights Advisor",
    personImg: Mahmoud,
    country: "Egypt",
  },
  {
    name: "Fraidoon Hussein Ahmed",
    designation: "Human Rights Advisor",
    personImg: Fraidoon,
    country: "Iraq",
  },
  {
    name: "Umberto Puato",
    designation: "Human Rights Advisor",
    personImg: Umberto,
    country: "Italy",
  },
  {
    name: "Purbasha Mondal",
    designation: "Human Rights Advisor",
    personImg: Purbasha,
    country: "India",
  },
  {
    name: "Saad  Yakeen",
    designation: "Human Rights Advisor",
    personImg: Saad,
    country: "Sweden",
  },
  {
    name: "Paolo Bragangna",
    designation: "Human Rights Advisor",
    personImg: Paolo,
    country: "Germany",
  },
  {
    name: "Winfred Wanjiku Gitonga",
    designation: "Human Rights Advisor",
    personImg: Winfred,
    country: "Jordan",
  },
  {
    name: "Ahmad Mrini",
    designation: "Human Rights Advisor",
    personImg: Ahmad,
    country: "Russia",
  },
  {
    name: "Humzan Abbas Hamzan",
    designation: "Human Rights Advisor",
    personImg: Humzan,
    country: "Georgia",
  },
  {
    name: "Mr. Zaki Mustafa",
    designation: "Human Rights Advisor",
    personImg: Zaki,
    country: "Morocco",
  },
  {
    name: "Tusher Kanti Sarker",
    designation: "Human Rights Advisor",
    personImg: Tusher,
    country: "UK",
  },
  {
    name: "Sathit Kumar",
    designation: "Human Rights Advisor",
    personImg: Sathit,
    country: "Thailand",
  },
  {
    name: "Hilbert Nwachukwu",
    designation: "Human Rights Advisor",
    personImg: Hilbert,
    country: "Nigeria",
  },
  {
    name: "Yusuf Saifuzzaman",
    designation: "Human Rights Advisor",
    personImg: Yusuf,
    country: "Bangladesh",
  },
  {
    name: "David Haivri",
    designation: "Political Advisor",
    personImg: David,
    country: "Israel",
  },
  {
    name: "Paul Taylor",
    designation: "Political Advisor",
    personImg: Paul,
    country: "USA",
  },
  {
    name: "Irina Tsukerman",
    designation: "Political Advisor",
    personImg: Irina,
    country: "USA",
  },
  {
    name: "Jorg Sobolewski",
    designation: "Political Advisor",
    personImg: Jorg,
    country: "Germany",
  },
  {
    name: "Omarov Nariman Sharabutinvich",
    designation: "Political Advisor",
    personImg: Omarov,
    country: "Russia",
  },
  {
    name: "Mr. Eric Max Gozlan",
    designation: "Political Advisor",
    personImg: Eric,
    country: "France",
  },
  {
    name: "Tamar Lolishvili",
    designation: "Education Advisor",
    personImg: Tamar,
    country: "Georgia",
  },
  {
    name: "Gaurav Sachar",
    designation: "Education Advisor",
    personImg: Gaurav,
    country: "India",
  },
];

const Advisors = () => {
  const [loadMore, setLoadMore] = useState(27);
  const LoadMoreHandle = () => {
    setLoadMore((prevValue) => prevValue + 6);
  };
  return (
    <section>
      <Banner2
        bannerImg={bannerImg2}
        bannerTitle="Advisors"
        link="Our People"
        link2="Advisors"
        url1="/ourPeople"
        className="position-relative"
      />
      <div className="container">
        <div className="py-5">
          <div className="text-center">
            <div className="heading-style4 ">
              <h2 className="text-black">Board of Advisors</h2>
            </div>
            {/* <p>
              Our honorable board of advisors.
            </p> */}
            {/* <h5>Our most honorable advisors:</h5> */}
            <div className="py-5 row">
              {BoardMember.slice(0, loadMore).map((value) => (
                <div className="col-md-4">
                  <div className="director-card">
                    <div className="bgg-image">
                      <img src={cardTop} alt="" className="teamImg" />
                    </div>
                    <div className="picc">
                      <img src={value.personImg} alt="" className="teamImg" />
                    </div>

                    <div className="infoo">
                      <div className="pb-5">
                        <h4 className="">{value.name}</h4>
                        <small className="fw-bold">{value.designation}</small> <br />
                        <small>Country: {value.country}</small>
                      </div>

                    </div>
                  </div>
                </div>
              ))}
            </div>

            <button onClick={LoadMoreHandle} className="primary-btn2">
              Load More
            </button>

          </div>
        </div>
      </div>
      <div className="TopSubFooterBg">
        <div className="custom-container">
          <div className="row TopSubFooter">
            <div className="col-md-8 my-auto">
              <h3 className="my-auto text-uppercase mb-2">Become a Part of Our Global Team</h3>
            </div>
            <div className="my-auto col-md-4">
              <Link to="/membership"><button className="">Join Us Now</button></Link>
            </div>
          </div>
        </div>
      </div>

    </section>
  );
};

export default Advisors;
