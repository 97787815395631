import React from "react";
import { Link } from "react-router-dom";
import { blogData } from "./BlogData";

import rightshadepic from "../images/right-shade.png";
import leftshadepic from "../images/left-shade.png";
import "./WeStandsFor.css";

const WeStandsFor = () => {
  window.scrollTo({
    top: 0,
  });

  return (
    <div className="overflow-hidden">
      <div className="stands-for-cover"></div>
      <div className="stands-for-title">
        <div className="stands-for-title-container">
          <div className="title-wraper">
            <h1>We Stands For</h1>
            <div className="title-description">
              <p>
                WORLD HINDU STRUGGLE COMMITTEE (WHSC) envisions a world in which
                everyone can freely and safely promote and enjoy all human
                rights. Empowered human rights defenders and strong and
                independent civil society are key to this vision.
              </p>
            </div>
            <img src={leftshadepic} className="left-shade" alt="left-shade" />
            <img
              src={rightshadepic}
              className="right-shade"
              alt="right-shade"
            />
          </div>
        </div>
      </div>

      <div className="post-content">
        <div className="post-container">
          <div className="row justify-content-center align-items-center">
            {blogData.map((blog) => (
              <div className="col-xs-6 col-md-3" key={blog.id}>
                <div className="post-wrapper">
                  <Link
                    to={`/we-stands-for/${blog.slug}/${blog.id}`}
                    style={{ backgroundImage: `url(${blog.image})` }}
                    className="post-link"
                  ></Link>
                  <div className="post-title-wraper">
                    <div className="post-title">{blog.title}</div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div>

      </div>
    </div>
  );
};

export default WeStandsFor;
