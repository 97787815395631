import React, { useState } from "react";
import { Link } from "react-router-dom";
import { blogData } from "./BlogData";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import Slider from "react-slick";
import rightshadepic from "../images/right-shade.png";
import leftshadepic from "../images/left-shade.png";
import { StickyShareButtons } from "sharethis-reactjs";
import "./WeStandsFor.css";

//create your forceUpdate hook
function useForceUpdate() {
  const [value, setValue] = useState(0); // integer state
  return () => setValue((value) => value + 1); // update the state to force render
}

const BlogDetails = (props) => {
  const forceUpdate = useForceUpdate();
  window.scrollTo({
    top: 0,
  });

  const pageUrl = window.location.href;

  const splitUrl = window.location.pathname.split("/");
  const Id = splitUrl[splitUrl.length - 1];
  const blog = blogData.find((blog) => blog.id === Number(Id));

  let allBlogs = blogData.filter((blog) => blog.id !== Number(Id));

  var settings = {
    dots: false,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    pauseOnHover: true,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
          dots: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 2,
          initialSlide: 2,
          dots: false,
        },
      },
    ],
  };



  return (
    <div className="overflow-hidden">
      <div
        className="stands-for-blog"
        style={{
          background: `url(${blog.image})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundAttachment: "fixed",
          backgroundPosition: "bottom",
          minHeight: "70vh",
          width: "100vw",
        }}
      ></div>

      <div className="stands-for-title">
        <div className="stands-for-title-container">
          <div className="title-wraper">
            <h1 className="text-center">{blog.title}</h1>
            <div className="title-description">
              <p>{blog.titleDescription}</p>
            </div>
            <img src={leftshadepic} className="left-shade" alt="left-shade" />
            <img
              src={rightshadepic}
              className="right-shade"
              alt="right-shade"
            />
          </div>
        </div>
      </div>

      <div className="sub-menu">
        <div className="container">
          <div className="sub-menu-content">
            <div className="row">
              {blog.subMenueTitles.map((title, index) => (
                <div
                  className={`${blog.subMenueTitles.length <= 2
                      ? `col-md-6`
                      : blog.subMenueTitles.length > 3
                        ? `col-md-3`
                        : `col-md-4`
                    } sub-menu-border`}
                  key={index}
                >
                  <div className="">
                    <a href={`#ti${index}`} className="">
                      {title}
                    </a>
                  </div>
                  <div className="">
                    <a href={`#ti${index}`}>
                      <FontAwesomeIcon
                        className="arrow-cursor"
                        icon={faChevronDown}
                        size="sm"
                        color="#ffb422"
                      ></FontAwesomeIcon>
                    </a>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>

      {blog.titleWithDescription.map((blogDes, index) => (
        <div className="blog-content" id={`ti${index}`} key={index}>
          <div className="section-top">
            <div className="container-700">
              <div className="blog-content-header">
                <h3>{blogDes.titleName}</h3>
              </div>
            </div>
            <div className="section-arrow"></div>
          </div>
          <div className="blog-detail-section mt-5">
            <div className="container blog-desc1">
              <div className="p-0">
                <article className="blog-para p-md-5 p-3 mb-0">
                  {blogDes.description}
                </article>
              </div>
            </div>
          </div>
        </div>
      ))}

      <div className="blog_programs">
        <div className="other_program">
          <div className="container">
            <Slider {...settings} className="row-blog p-3">
              {allBlogs.map((blog) => (
                <div className="shortcut p-3" key={blog.id}>
                  <div className="post-wrapper">
                    <Link
                      to={`/we-stands-for/${blog.slug}/${blog.id}`}
                      onClick={forceUpdate}
                      style={{ backgroundImage: `url(${blog.image})` }}
                      className="post-link"
                    ></Link>
                    <div className="post-title-wraper">
                      <div className="post-title">{blog.title}</div>
                    </div>
                  </div>
                </div>
              ))}
            </Slider>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BlogDetails;
