import React, { useState, useRef } from "react";
import { Link } from "react-router-dom";
// upload Image ==================
import { storage } from "./FireBase";
// upload Image ==================
import Certificate from "./Certificate";
import certificateSideImg from '../images/getCertificate.jpg';
import "./NewCertificate.css";
import axios from "axios";

const GetCertificate = () => {
  const [isCertified, setIsCertified] = useState(false);
  const [mName, setMName] = useState("");
  const [mEmail, setMEmail] = useState("");
  const [sheetData, setSheetData] = useState([]);
  const [isFind, setIsFind] = useState(true);

  // upload Image ==================
  const [image, setImage] = useState(null);
  const [url, setUrl] = useState("");
  const [progress, setProgress] = useState(0);
  // upload Image ==================


  // upload Image ==================
  const handleChange = e => {
    if (e.target.files[0]) {
      setImage(e.target.files[0]);
      e.preventDefault();
    }
  }

  console.log("image is: ", image);
  // upload Image ==================

  const componentRef = useRef();

  let today = new Date();
  let dd = String(today.getDate()).padStart(2, "0");
  let mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
  let yyyy = today.getFullYear();

  today = dd + "/" + mm + "/" + yyyy;
  // console.log(today);

  console.log(url);
  const getCertificateHandler = async (e) => {
    // Upload Image Function Start ==============
    const uploadTask = storage.ref(`upload_images/${image.name}`).put(image);
    uploadTask.on(
      "state_changed",
      snapshot => {
        const progress = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );
        setProgress(progress);
      },
      error => {
        console.log("hello", error);
      },
      () => {
        storage
          .ref("upload_images")
          .child(image.name)
          .getDownloadURL()
          .then(url => {
            console.log(url);

            setUrl(url);
          });
      }
    );
    e.preventDefault();
    // Upload Image Function End ==============
    try {
      const { data } = await axios.get(
        `https://v1.nocodeapi.com/whsc/google_sheets/YqgebqfwpETGYNFl/search?tabId=Sheet1&searchKey=email&searchValue=${mEmail}`
      );
      // console.log(data[0].lastName);
      setSheetData(data[0]);
      setIsCertified(true);
    } catch (error) {
      console.log(error);
      setIsFind(false);
    }

    console.log("name " + mName, mEmail);
  };

  // console.log(sheetData);

  return (
    <div className="certificate-banner">
      <div className="">
        <div className="">
          <div className="">
            {isCertified ? (
              <div>
                <Certificate
                  firstname={sheetData.firstName}
                  lastname={sheetData.lastName}
                  url={url}
                  date={today}
                  ref={componentRef}
                />
              </div>
            ) : (

              <div className="container certificate-design">
                <div className="row">
                  <div className="col-md-6 p-0 get-certificate-img z-index">
                    <img src={certificateSideImg} alt="" />
                  </div>
                  <div className="col-md-6 z-index get-certificate-right">
                    <div className="get-certificate-inner">
                      <h2 className="text-center primary-color py-2">
                        {" "}
                        GET A MEMBERSHIP CERTIFICATE
                      </h2>
                      <form
                        className="form-certificate contact-form"
                      >
                        <input
                          type="text"
                          value={mName}
                          onChange={(e) => setMName(e.target.value)}
                          className="mb-3 p-2"
                          placeholder="Your registered name"
                          required
                        ></input>
                        <input
                          type="email"
                          value={mEmail}
                          onChange={(e) => setMEmail(e.target.value)}
                          className="mb-3 p-2"
                          placeholder="Your registered email address"
                          required
                        ></input>
                        {!isFind && <p className="text-danger">Please give your registered email.</p>}

                        <input type="file" onChange={handleChange} className="form-control" accept="image/png, image/jpeg" />

                        <br />

                        <button type="submit" className="get-certificate-btn mb-2" onClick={getCertificateHandler}>
                          Get Certificate
                        </button>



                        <p className="pt-2">
                          {" "}
                          Not a member?{" "}
                          <Link
                            to="/membership"
                            className="text-decoration-none primary-color"
                          >
                            Register here....
                          </Link>
                        </p>
                      </form>
                      {/* </div> */}
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

    </div>
  );
};

export default GetCertificate;
