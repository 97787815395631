import React from "react";
import "../WorkWithUs/WorkWithUs.css";
import Event from "./Event";
import JoinUs from "../../images/workWithUs.jpg";
import Banner2 from "../../Shared/Banner2/Banner2";


const WorkWithUs = () => {
  return (
    <div className="">    
      <Banner2
        bannerImg={JoinUs}
        bannerTitle="JOIN US"
        link="ABOUT"
        link2="MEMBER"
      />
      <div className="custom-container">
        <Event />
      </div>
    </div>
  );
};

export default WorkWithUs;
