import React from "react";
import "./../Card.css";
import bannerImg2 from "../../../images/boardDirector.jpg";
import Banner2 from "../../../Shared/Banner2/Banner2";
import Jiban from "../../../images/our-people/advisor/Jiban-Maharaj.png";
import Agaysta from "../../../images/our-people/advisor/Agaysta-Giri-Maharaj.png";
import Mahant from "../../../images/our-people/advisor/Mahant-Kameshwar-Dass.png";
import Udayananda from "../../../images/our-people/advisor/Udayananda-Bramachari.png";
import Sura from "../../../images/our-people/advisor/Sura-Pati-Das.png";
import Trigunatitananda from "../../../images/our-people/advisor/Swami-Trigunatitananda-Puri.png";
import Rajesh from "../../../images/our-people/advisor/Rajesh-Nath-Aghori.png";
import Anaghanagda from "../../../images/our-people/advisor/Swami-Anaghanagda-Puri.png";
import Sudarshan from "../../../images/our-people/social-welfare-advisor/Sudarshan-Naidu.png";
import Smt from "../../../images/our-people/social-welfare-advisor/Smt-Ruby-Sain.png";
import Zina from "../../../images/our-people/social-welfare-advisor/Zina-Bestaeva.png";
import Sok from "../../../images/our-people/social-welfare-advisor/Sok-Somontha.png";
import Riad from "../../../images/our-people/social-welfare-advisor/Riad-Hassoun.png";
import Khaled from "../../../images/our-people/social-welfare-advisor/Khaled-Mohammad.png";
import Karina from "../../../images/our-people/social-welfare-advisor/Karina-Silvana-Krenn.png";
import Evgeny from "../../../images/our-people/social-welfare-advisor/Evgeny-Mikhalchenko.png";
import Tamara from "../../../images/our-people/social-welfare-advisor/Tamara-Omar.png";
import Gayatriben from "../../../images/our-people/social-welfare-advisor/Gayatriben-Ramkumar-Jat.png";
import Sikha from "../../../images/our-people/social-welfare-advisor/Sikha-Sharma.png";
import { Link } from "react-router-dom";


const SocialAdvisors = [
  {
    name: "Jiban Maharaj",
    designation: "Chief Religious Advisor",
    personImg: Jiban,
    country: "India",
  },
  {
    name: "Late Agaysta Giri Maharaj",
    designation: "Religious Advisor",
    personImg: Agaysta,
    country: "India",
  },
  {
    name: "Mahant Kameshwar Dass",
    designation: "Religious Advisor",
    personImg: Mahant,
    country: "India",
  },
  {
    name: "Udayananda Bramachari",
    designation: "Religious Advisor",
    personImg: Udayananda,
    country: "Bangladesh",
  },
  {
    name: "Sura Pati Das",
    designation: "Religious Advisor",
    personImg: Sura,
    country: "India",
  },
  {
    name: "Swami Trigunatitananda Puri",
    designation: "Religious Advisor",
    personImg: Trigunatitananda,
    country: "India",
  },
  {
    name: "Rajesh Nath Aghori",
    designation: "Religious Advisor",
    personImg: Rajesh,
    country: "India",
  },
  {
    name: "Swami Anaghanagda Puri",
    designation: "Religious Advisor",
    personImg: Anaghanagda,
    country: "India",
  },
  {
    name: "Dr Sudarshan Naidu",
    designation: "Advisor Sociologiocal Studies",
    personImg: Sudarshan,
    country: "India",
  },
  {
    name: "Smt Ruby Sain",
    designation: "Advisor Sociologiocal Studies",
    personImg: Smt,
    country: "India",
  },
  {
    name: "Zina Bestaeva",
    designation: "Advisor Sociologiocal Studies",
    personImg: Zina,
    country: "Georgia",
  },
  {
    name: "Sok Somontha",
    designation: "Social Welfare Advisor",
    personImg: Sok,
    country: "Cambodia",
  },
  {
    name: "Riad Hassoun",
    designation: "Social Welfare Advisor",
    personImg: Riad,
    country: "Israel",
  },
  {
    name: "Mr. Khaled Mohammad",
    designation: "Social Welfare Advisor",
    personImg: Khaled,
    country: "Netherland",
  },
  {
    name: "Karina Silvana Krenn",
    designation: "Social Welfare Advisor",
    personImg: Karina,
    country: "Argentina",
  },
  {
    name: "Evgeny Mikhalchenko",
    designation: "Social Welfare Advisor",
    personImg: Evgeny,
    country: "Russia",
  },
  {
    name: "Tamara Omar",
    designation: "Social Welfare Advisor",
    personImg: Tamara,
    country: "USA",
  },
  {
    name: "Gayatriben Ramkumar Jat",
    designation: "Social Welfare Advisor",
    personImg: Gayatriben,
    country: "India",
  },
  {
    name: "Sikha Sharma",
    designation: "Social Welfare Advisor",
    personImg: Sikha,
    country: "India",
  },





];

const SocialAdvisor = () => {
  return (
    <section>
      <Banner2
        bannerImg={bannerImg2}
        bannerTitle="Religious & Social Advisors"
        link="Our People"
        link2="Social & Religious"
        url1="/ourPeople"
        url2="/social-advisor"
        className="position-relative"
      />
      <div className="container">
        <div className="py-5">
          <div className="text-center ">
            <h1>Board of Religious & Social Advisors</h1>
            {/* <p>
              PI is governed by a Board of Directors which is responsible for
              the overall strategy and governance of the organisation.
            </p> */}

            <div className="row g-4 pt-5">
              {SocialAdvisors.map((value) => (
                <div className="col-md-4">
                  <div className="globalMemberCard">
                    <div className="global-img-box senior-img">
                      <img src={value.personImg} alt="" className="teamImg" />
                    </div>
                    <div className="senior-advisor-team-content">
                      <h4 className="primary-color">{value.name}</h4>
                      <h6>{value.designation}</h6> <br />
                      <div className="global-card-details">
                        <small>Country: {value.country}</small>
                        {/* <p className="pt-2">
                          PI is governed by a Board of Directors in India
                        </p> */}
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <div className="TopSubFooterBg">
        <div className="custom-container">
          <div className="row TopSubFooter">
            <div className="col-md-8 my-auto">
              <h3 className="my-auto text-uppercase mb-2">Become a Part of Our Global Team</h3>
            </div>
            <div className="my-auto col-md-4">
              <Link to="/membership"><button className="">Join Us Now</button></Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SocialAdvisor;
