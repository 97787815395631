import React from 'react';
import "./HelpThePeopleDetails.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebookF, faTwitter, faLinkedinIn, } from "@fortawesome/free-brands-svg-icons";
import campaign from "./Data";
import { FacebookShareButton, TwitterShareButton, LinkedinShareButton } from "react-share";
import { useLocation, Link } from 'react-router-dom';



const HelpThePeopleDetails = () => {

    // const pageUrl = useLocation();
    // console.log("pageUrl:", pageUrl);

    // let pageUrl = '';
    // if (typeof window === 'object') {
    //     pageUrl = String(window.location.href);
    // }
    // console.log("PageURL:", pageUrl);

    const pageUrl = window.location.href;
    console.log("PageURLCountry:", window.location.href);

    window.scrollTo({
        top: 0,
    });

    const splitUrl = window.location.pathname.split("/");
    const Id = splitUrl[splitUrl.length - 1];

    const data = campaign.find((data) => data.id === Id);
    console.log("data:", data);

    return (
        <div>
            <div
                style={{
                    background: `url(${data.img})`,
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center",
                    backgroundColor: "linear-gradient(10deg, #1c232798, #27303685)",

                }}
                className="blog-banner-sec  d-flex justify-content-center"
            >

            </div>

            <div className="container blog-desc pt-3  p-0 ">
                <div className="container px-5 py-2 help-the-people-details text-center">
                    <h2> {data.title}</h2>
                    <div className="pt-3">
                        <Link to="/donate" className="donateBtn">Donate Now</Link>
                    </div>
                    <div className="d-flex flex-column blog-icons py-3 pe-4">
                        <small className="mb-2">
                            <a href="/" className="primary-color ">
                                <FacebookShareButton url={"href"}>
                                    <FontAwesomeIcon icon={faFacebookF} size="lg" />
                                </FacebookShareButton>
                            </a>
                        </small>
                        <small className="mb-2">
                            <a href="/" className="primary-color ">
                                <TwitterShareButton url={pageUrl}>
                                    <FontAwesomeIcon icon={faTwitter} size="lg" />
                                </TwitterShareButton>
                            </a>
                        </small>
                        <small className="mb-2">
                            <a href="/" className="primary-color">
                                <LinkedinShareButton url={pageUrl}>
                                    <FontAwesomeIcon icon={faLinkedinIn} size="lg" />
                                </LinkedinShareButton>
                            </a>
                        </small>
                    </div>
                </div>
                <p className="pt-5 px-lg-5 px-3 blog-para">
                    {data.desc}
                </p>

                <div className="blog-bottom py-5 w-100 d-flex justify-content-evenly ">
                    <h4 className="share-text">Help The People Around The World</h4>
                    <Link to="/donate" className="donateBtn">Donate Now</Link>
                </div>

            </div>
        </div>
    );
};

export default HelpThePeopleDetails;