import React from "react";
import "./FAQ.css";
import Banner2 from "../../Shared/Banner2/Banner2";
import bannerImg2 from "../../images/faq.jpg";
import Helmet from "react-helmet";

const FAQ = () => {

  return (
    <section className="">
      <Helmet>
        {/* <!-- HTML Meta Tags --> */}
        <title>FAQ | World Human Struggle Committee</title>
        <meta name="title" content="FAQ | World Human Struggle Committee" />
        <meta
          name="keywords"
          content="faq, questions, what, most asked question"
        />
        <meta
          name="description"
          content="This is the faq where you can find the answers"
        />
        <meta name="robots" content="index,follow" />
        <meta name="url" content="http://www.whsc.com/faq" />

        {/* <!-- Google / Search Engine Tags --> */}
        <meta itemprop="name" content="FAQ | World Human Struggle Committee" />
        <meta
          itemprop="description"
          content="This is the faq where you can find the answers"
        />
      </Helmet>
      <Banner2
        bannerImg={bannerImg2}
        bannerTitle="Frequently Asked Question"
        link="ABOUT"
        link2="FAQ"
      />
      <div className="container top-gap bottom-gap">
        <div className="heading-style3 ">
          <h2>WHSC FAQ</h2>
        </div>
        <div class="accordion" id="accordionExample">
          <div class="accordion-item">
            <h2 class="accordion-header" id="headingOne">
              <button
                class="accordion-button"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseOne"
                aria-expanded="true"
                aria-controls="collapseOne"
              >
                What is the World Hindu Struggle Committee?
              </button>
            </h2>
            <div
              id="collapseOne"
              class="accordion-collapse collapse show"
              aria-labelledby="headingOne"
              data-bs-parent="#accordionExample"
            >
              <div class="accordion-body">
              The World Hindu Struggle Committee (WHSC) is an international human rights organization that advocates for the rights and interests of Hindus and others minorities around the world. To raise its voice against fundamentalism, terrorism, and inhuman groups, WHSC is working globally to keep the world peaceful.
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <h2 class="accordion-header" id="headingTwo">
              <button
                class="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseTwo"
                aria-expanded="false"
                aria-controls="collapseTwo"
              >
                What is the mission of the WHSC ?
              </button>
            </h2>
            <div
              id="collapseTwo"
              class="accordion-collapse collapse"
              aria-labelledby="headingTwo"
              data-bs-parent="#accordionExample"
            >
              <div class="accordion-body">
              The mission of the WHSC is to promote and protect the rights and interests of Hindus and the minorities around the world, while also preserving and promoting Hindu culture and heritage.
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <h2 class="accordion-header" id="headingThree">
              <button
                class="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseThree"
                aria-expanded="false"
                aria-controls="collapseThree"
              >
                Who can join the WHSC?
              </button>
            </h2>
            <div
              id="collapseThree"
              class="accordion-collapse collapse"
              aria-labelledby="headingThree"
              data-bs-parent="#accordionExample"
            >
              <div class="accordion-body">
              Anyone who shares the values and mission of the WHSC can join the organization.
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <h2 class="accordion-header" id="headingFour">
              <button
                class="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseFour"
                aria-expanded="false"
                aria-controls="collapseFour"
              >
                What issues does the WHSC advocate for?
              </button>
            </h2>
            <div
              id="collapseFour"
              class="accordion-collapse collapse"
              aria-labelledby="headingFour"
              data-bs-parent="#accordionExample"
            >
              <div class="accordion-body">
              The WHSC advocates for issues such as human rights violations against minorities around the world, discrimination, and persecution.
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <h2 class="accordion-header" id="headingFive">
              <button
                class="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseFive"
                aria-expanded="false"
                aria-controls="collapseFive"
              >
                Does the WHSC work with other organizations or governments?
              </button>
            </h2>
            <div
              id="collapseFive"
              class="accordion-collapse collapse"
              aria-labelledby="headingFive"
              data-bs-parent="#accordionExample"
            >
              <div class="accordion-body">
              Yes, the WHSC works with other organizations and governments to address issues affecting Hindus and minorities worldwide.
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <h2 class="accordion-header" id="headingSix">
              <button
                class="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseSix"
                aria-expanded="false"
                aria-controls="collapseSix"
              >
                How can I get involved with the WHSC?
              </button>
            </h2>
            <div
              id="collapseSix"
              class="accordion-collapse collapse"
              aria-labelledby="headingSix"
              data-bs-parent="#accordionExample"
            >
              <div class="accordion-body">
              To get involved with the WHSC, you can visit their website, attend their events, or contact them directly for more information.
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <h2 class="accordion-header" id="headingSix">
              <button
                class="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseSix"
                aria-expanded="false"
                aria-controls="collapseSix"
              >
                How can I donate to the WHSC and support their efforts?
              </button>
            </h2>
            <div
              id="collapseSix"
              class="accordion-collapse collapse"
              aria-labelledby="headingSix"
              data-bs-parent="#accordionExample"
            >
              <div class="accordion-body">
              You can donate to the WHSC through their website or by contacting them directly to support their efforts.
              </div>
            </div>
          </div>





        </div>
      </div>
    </section>
  );
};

export default FAQ;
