
import "./Donate.css";
// import paypalImg from "../../components/images/paypal.png";
// import stripeImg from "../../components/images/stripe-new.png";
// import razorpay from "../../components/images/razorpay.png";
import bannerImg2 from "../images/donateBanner1.jpg";
import Banner2 from "../Shared/Banner2/Banner2";
// import StripeCheckout from "react-stripe-checkout";



const DonateUs = () => {

  return (
    <section>
      <Banner2
        bannerImg={bannerImg2}
        bannerTitle="Donate and Become a Super Hero"
        link="HOME"
        link2="DONATE"
        url1="/"
        className="position-relative"
      />

      <div className="container pt-5">
        <div className="heading-style4">
          <h2 className="text-black">Donate Now</h2>
        </div>
        <div className="donate-frame">
          <div className="p-2 p-lg-5 p-md-5">
            <div className="row pb-5 g-5">
              <div className="col-md-7">
                <div className="">
                  <div className="select-amount">
                    <div>
                
                      <div className="pt-5">
                        <h4>Attention !</h4>
                        <p className="text-justify">
                          One drop of your generosity can make an oceanic change of the lives of millions of underprivileged children and families. Together we can work for the upliftment of minorities for a better future and living standards. Our dedication and efforts can change life of millions.

                          Donate for the education, malnutrition or poverty stricken children of ravaged minorities across the globe. Your kind co-operation and assistance will bring smiles and reach millions. Donate to make a difference and for a cause.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-5 mx-auto">
                <div className="payment-method">
                  <h3>You Can Donate Via Bank </h3>
                    <div className="pt-5">
                      <h6>ACOUNT DETAILS:</h6>
                      <div>
                      <p> WORLD HINDU STRUGGLE COMMITTEE<br/>
                        ACOUNT NO:  388105000480<br/>
                        IFSC CODE: ICIC0003881<br/>
                        BRANCH NAME: RAJARHAT GOPALPUR DLF GALLERIA<br/>
                        KOLKATA-700156<br/>
                        INDIA
                        
                        </p>
                      </div>
                    </div> 
                  
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default DonateUs;
