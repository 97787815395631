import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHandshake } from "@fortawesome/free-solid-svg-icons";
// import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { Link } from "react-router-dom";
import './Contact.css';

const ContactForm = () => {

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);

    const [modal, setModal] = useState(false);

    const [member, setMember] = useState({
        username: "",
        email: "",
        message: "",
    });

    const { username, email, message, } = member;

    const handleChange = (e) => {
        setMember({ ...member, [e.target.name]: e.target.value })
    }

    const handleSubmit = async (e) => {
        setModal(true)
        setShow(true);
        e.preventDefault()

        try {

            const res = await fetch("https://v1.nocodeapi.com/sundarbanlab/google_sheets/XjqnvnUAtuLGCilU?tabId=Sheet1", {

                method: "POST",
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify([[username, email, message]]),
            });
            await res.json()
            setMember({
                ...member,
                username: "",
                email: "",
                message: "",
            })
        } catch (err) {
            console.error(err)
            alert("Try again")
        }
    }

    return (
        <section>

            <form
                className="mt-4 contactForm"
                accept-charset="UTF-8"
                method="POST"
                enctype="multipart/form-data"
                target="_blank"
                onSubmit={handleSubmit}
            >

                <div className="primary-color">
                    <label for="exampleInputName">Name</label>
                    <input
                        type="text"
                        name="username"
                        value={username}
                        onChange={handleChange}
                        className="form-control"
                        placeholder="Enter your name "
                        required="required"
                    />
                </div>
                <br />
                <div className="primary-color">
                    <label for="exampleInputEmail1" required="required">
                        Email address
                    </label>
                    <input
                        type="email"
                        name="email"
                        value={email} onChange={handleChange}
                        className="form-control"
                        aria-describedby="emailHelp"
                        placeholder="Enter your email address"
                        required
                    />
                </div>
                <br />
                <div className="primary-color">

                    <label for="exampleInputName">Message</label>
                    <textarea
                        type="text"
                        rows="6"
                        name="message"
                        value={message} onChange={handleChange}
                        className="form-control"
                        placeholder="Your Message"
                        required
                    />

                </div>
                <br />
                <div className="my-2 mx-auto text-center">
                    <button className="fw-bold button w-100" type="submit" >
                        <FontAwesomeIcon icon={faHandshake} /> Submit
                    </button>
                </div>
            </form>


            {modal &&
                <Modal
                    show={show}
                    onHide={handleClose}
                    backdrop="static"
                    keyboard={false}
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >
                    <Modal.Header closeButton>
                        <h5>THANK YOU FOR CONTACT US.</h5>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="text-center">
                            <strong className="primary-color">We will reply you as soon as possible.!</strong>
                            <br />
                            <p className="fs-5">
                                You can also donate us
                            </p>
                            <Link to="/donate"><button className="primary-btn2">Donate Now</button></Link>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <div className="text-center">
                            <p>
                                By submitting your application you agree with our{" "}
                                <Link to="/privacy-policy">Privacy Policy</Link> &{" "}
                                <Link to="/terms-and-conditions">Terms and Conditions.</Link>
                            </p>
                        </div>
                    </Modal.Footer>
                </Modal>
            }
        </section>
    );
};

export default ContactForm;
