import React from "react";
import CountryData from "../CountryData/CountryData";
import { Link } from "react-router-dom";

const HomeCard = () => {
  return (
    <section>
      <div className="row overflow-hidden ">
        {CountryData.map((data) => (
          <div className="col-md-3 overflow-hidden donate-cards">
            <img src={data.img} alt="card-images" className="" />
            <span className="span-color2"></span>
            <div className="card-description">
              <h5>{data.semiTitle}</h5>
              <div className="pt-3">
                <Link
                  to={`/country-data/${data.address}/${data.id}`}
                  className="primary-btn text-decoration-none"
                >
                  Read More
                </Link>
              </div>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default HomeCard;
