import React from "react";
import GalleryImages from "./GalleryImages";
import "./gallery.css";

const GalleryComponent = () => {
  return (
    <div className="pt-80 overflow-hidden gallerySection">

      <GalleryImages />
    </div>
  );
};

export default GalleryComponent;
