import React from "react";
import "./Donor.css";
import bm from "../../images/donors/whsc.jpg";
import Banner2 from "../../Shared/Banner2/Banner2";
import bannerImg from "../../images/charityDonor.jpg";
const Donor = () => {
  const donorData = [
    {
      logo: bm,
      organaization: "Your Name Here",
      desc: "We are a nonprofit & human rights organization fighting for poverty, disease, and inequity around the world.Help the world and be a donor of WHSC.",
    },
    {
      logo: bm,
      organaization: "Your Name Here",
      desc: "We are a nonprofit & human rights organization fighting for poverty, disease, and inequity around the world.Help the world and be a donor of WHSC.",
    },
    {
      logo: bm,
      organaization: "Your Name Here",
      desc: "We are a nonprofit & human rights organization fighting for poverty, disease, and inequity around the world.Help the world and be a donor of WHSC.",
    },
  ];

  return (
    <section>
      <Banner2
        bannerImg={bannerImg}
        bannerTitle="OUR DONOR"
        link="ABOUT"
        link2="DONOR"
        url1="/"
        url2="/"
      />
      <div className="custom-container top-gap bottom-gap">
        <div className="heading-style3">
          <h2>Our Donors</h2>
        </div>

        <div className="grid-item2">
          {donorData.map((data) => (
            <div className="single-box">
              <div className="img-area">
                <img src={data.logo} alt="" />
              </div>
              <div class="module">
                <div class="module__top">
                  <div class="top__content text-black">
                    {data.organaization}
                  </div>
                </div>
                <div class="module__contents">{data.desc}</div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Donor;
