import React from "react";
import "./Home.css";
import { Card } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { Link } from 'react-router-dom';
import { faHandPointRight } from "@fortawesome/free-regular-svg-icons";
import campaign from "./HelpThePeopleData/Data";

const HelpThePeople = () => {

  return (
    <section className="section-bgg">
      <div className="pt-4">
        <div className="heading-style4">
          <h2 className="text-black">Help The People Around </h2>
        </div>
      </div>
      <div className="custom-container">
        <div className="row g-2">
          {campaign.map((data) => (
            <Card className="col-lg-4 bg-transparent col-md-4 border-0 col-sm-12">
              <div className="bg-white rounded-top px-3">
                <div className="overflow-hidden">
                  <Card.Img
                    variant="top"
                    src={data.img}
                    className="campaignImg mt-3"
                  />
                </div>
                <Card.Body className="help-the-people-card">
                  <Card.Text>
                    <span className="fw-bold fs-3">{data.title}</span>
                  </Card.Text>
                  <Card.Title className="fs-6 cardDetailEclipseHeme">
                    {data.desc}
                  </Card.Title>
                  <Link to={`/help-the-people-details/${data.slug}/${data.id}`}
                    className="text-decoration-none fw-bold primary-color read-detail">
                    <FontAwesomeIcon icon={faHandPointRight} /> Read Details
                  </Link>
                </Card.Body>
                <Card.Footer className="text-center p-0">
                  <div>

                    <Link to="/donate" className="text-decoration-none campaignButton">Support Humanity<FontAwesomeIcon className="ms-2 pt-1" icon={faArrowRight} /></Link>
                  </div>

                </Card.Footer>
              </div>
            </Card>
          ))}
        </div>
      </div>
    </section>
  );
};

export default HelpThePeople;
