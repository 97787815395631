import React from 'react';
import "./Terms.css";
import Banner2 from '../Shared/Banner2/Banner2';
import termsAndConditions from "../images/termsAndConditions.jpg";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDotCircle } from '@fortawesome/free-regular-svg-icons';

const Terms = () => {
    return (
        <section>
            <Banner2 bannerImg={termsAndConditions} bannerTitle="TERMS AND CONDITIONS" link="FOOTER" link2="TERMS AND CONDITIONS" />
            <div className="textJustify">
                <div className="text-center p-3">
                    <h1 className="primary-color text-uppercase">Terms and Conditions</h1>
                </div>
                <div className="container col-md-8 mt-3 mb-5 termsPage">
                    <div className="termsPage">
                        <p className="fs-6 text-justify">The following Terms and Conditions govern the use of this World Human Save Committee (“the Foundations” or “WHSC”) Site and any other site owned or operated by WHSC that links to these Terms and Conditions (“the Foundations’ website” or “this Site”). By visiting this Site, you specifically and automatically agree to abide by these Terms and Conditions. The World Human Save Committee reserve the right to modify these Terms and Conditions at any time. You agree that neither the Foundations nor any affiliate of the Foundations (collectively, “Affiliated Entities”) shall be liable to you or to any third party for any modification, suspension or discontinuance of this Site, in whole or in part, or of any service, content, feature, or product offered through this Site. Your continued use of the Site after such changes, including but not limited to any changes to these Terms and Conditions, will indicate your acceptance of such changes.
                        </p>
                    </div>
                    <div className="termsPage">
                        <h3 className="leftDesign">Use of Materials on this Site</h3>
                        <p className="fs-6">In keeping with the World Human Save Committee’ goals and mission, virtually all of the materials posted on this Site, except for (a) images, and (b) materials that contain a copyright notice for a third party other than the Foundations or the Open Society Institute, are licensed to the public through the Creative Commons Attribution-Non-commercial-No Derivatives license. This license allows users to download those materials from this Site (that are not specifically restricted) and to share them as long as (a) the particular article, section, audio, or video clip is reproduced in its entirety in its original form and is not edited or modified in any way; (b) all copies include attribution to the original author (if noted) and the World Human Save Committee and contain a link back to www.opensocietyfoundations.org as the original source of the work; and (c) the copies are distributed only for noncommercial purposes and are distributed at no charge.</p>
                        <br />
                        <p className="fs-6">Other than as specifically described in the preceding paragraph, you may not copy, reproduce, distribute, modify, display, republish, transmit, repost, or otherwise use the content on this Site without prior written permission from the World Human Save Committee. To request such permission, please contact us. The name “World Human Save Committee,” “Open Society Institute,” the swirl logo, and all related indicia are the trademarked properties of the Open Society Institute, and in no event may they be reproduced separately from the textual content associated with them on this website. Nothing contained on the Site should be construed as granting, by implication, estoppel, or otherwise, any license or right to use any of WHSC’s trade names, trademarks, or service marks without our express prior written consent. All trademarks and service marks on the Site not owned by WHSC are the property of their respective owners. The trade names, trademarks, and service marks owned by WHSC, whether registered or unregistered, may not be used in connection with any product or service that is not ours.</p>
                    </div>
                    <div className="termsPage">
                        <h3 className="leftDesign">Jurisdiction</h3>
                        <p className="fs-6">The Site is controlled and operated by the World Human Save Committee from the United States, and is not intended to subject the Foundations to the laws or jurisdiction of any state, country, or territory other than that of the United States. WHSC does not represent or warrant that the Site or any part thereof is appropriate or available for use in any particular jurisdiction other than the United States. Those who choose to access the Site do so on their own initiative and at their own risk, and are responsible for complying with all local laws, rules and regulations. You are also subject to United States export controls and are responsible for any violations of such controls, including any United States embargoes or other federal rules and regulations restricting exports. We may limit the Site's availability, in whole or in part, to any person, geographic area, or jurisdiction we choose, at any time and in our sole discretion.</p>
                    </div>
                    <div className="termsPage">
                        <h3 className="leftDesign">Information Submitted Through the Site</h3>
                        <p className="fs-6">Be aware that your decision to send personally identifiable information through your use of this Site will subject such information to the privacy laws and standards of the United States. Your submission of information through the Site is governed by WHSC's Privacy Policy, (the “Privacy Policy”). Your use of the Site is also subject to our Cookie Policy (the “Cookie Policy”). These Terms & Conditions incorporate by reference the terms and conditions of the Privacy Policy and Cookie Policy. You represent and warrant that any information you provide in connection with your use of the Site is and shall remain true, accurate, and complete, and that you will maintain and update such information regularly. You agree that if any information you provide is false, inaccurate, obsolete, or incomplete, we may terminate your use of the Site and/or any of the services. In addition, WHSC and the Affiliated Entities have no control over, and shall have no liability for, any damages resulting from the use (including without limitation republication) or misuse by any third party of information voluntarily made public through your profile, a forum or any other part of the Site. If you choose to make any of your personally identifiable or other information publicly available on the Site, you do so at your own risk.</p>
                    </div>
                    <div className="termsPage">
                        <h3 className="leftDesign">Rules of Conduct</h3>
                        <p className="fs-6">
                            While using the Site you will comply with all applicable laws, rules, and regulations, and with our Community Guidelines. In addition, we expect users of the Site to respect the rights and dignity of others. Your use of the Site is conditioned on your compliance with the following rules of conduct.
                            <br />
                            You will not post, transmit, or otherwise make available, through or in connection with the Site:
                        </p>
                        <p className="fs-6">
                            <FontAwesomeIcon className="primary-color2" icon={faDotCircle} /> Anything that is or may be (a) threatening, harassing, degrading, hateful, or intimidating; (b) defamatory; (c) fraudulent or tortious; (d) obscene, indecent, pornographic, or otherwise objectionable; or (e) protected by copyright, trademark, trade secret, right of publicity, or any other proprietary right without the express prior consent of the owner of such right;.
                        </p>
                        <p className="fs-6">
                            <FontAwesomeIcon className="primary-color2" icon={faDotCircle} /> any material that would give rise to criminal or civil liability; that encourages conduct that constitutes a criminal offense; or that encourages or provides instructional information about illegal activities or activities such as “hacking,” “cracking,” or “phreaking”;
                        </p>
                        <p className="fs-6">
                            <FontAwesomeIcon className="primary-color2" icon={faDotCircle} /> any virus, worm, Trojan horse, Easter egg, time bomb, spyware, or other computer code, file, or program that is harmful or invasive or may or is intended to damage or hijack the operation of, or to monitor the use of, any hardware, software, or equipment;
                        </p>
                        <p className="fs-6">
                            <FontAwesomeIcon className="primary-color2" icon={faDotCircle} /> any unsolicited or unauthorized advertising, promotional material, “junk mail,” “spam,” “chain letter,” “pyramid scheme,” or investment opportunity, or any other form of solicitation;
                        </p>
                        <p className="fs-6">
                            <FontAwesomeIcon className="primary-color2" icon={faDotCircle} /> use the Site for any fraudulent or unlawful purpose;
                        </p>
                        <p className="fs-6">
                            <FontAwesomeIcon className="primary-color2" icon={faDotCircle} /> use the Site to defame, abuse, harass, stalk, threaten, or otherwise violate the legal rights of others, including without limitation others' privacy rights or rights of publicity;
                        </p>
                        <p className="fs-6">
                            <FontAwesomeIcon className="primary-color2" icon={faDotCircle} /> use the Site to harvest or collect personally identifiable information about other users of the Site;
                        </p>
                        <p className="fs-6">
                            <FontAwesomeIcon className="primary-color2" icon={faDotCircle} /> impersonate any person or entity, including without limitation any representative of WHSC or an Affiliated Entity; falsely state or otherwise misrepresent your affiliation with any person or entity in connection with the Site; or express or imply that we endorse any statement you make;
                        </p>
                        <p className="fs-6">
                            <FontAwesomeIcon className="primary-color2" icon={faDotCircle} /> interfere with or disrupt the operation of the Site or the servers or networks used to make the Site available; or violate any requirements, procedures, policies, or regulations of such networks;
                        </p>
                        <p className="fs-6">
                            <FontAwesomeIcon className="primary-color2" icon={faDotCircle} /> restrict or inhibit any other person from using the Site, including without limitation by hacking or defacing any portion of the Site;
                        </p>
                        <p className="fs-6">
                            <FontAwesomeIcon className="primary-color2" icon={faDotCircle} /> use the Site to advertise or offer to sell or buy any goods or services without the Foundations’ express prior written consent;
                        </p>
                        <p className="fs-6">
                            <FontAwesomeIcon className="primary-color2" icon={faDotCircle} /> reproduce, duplicate, copy, sell, resell, or otherwise exploit for any commercial purposes, any portion of, use of, or access to the Site;
                        </p>
                        <p className="fs-6">
                            <FontAwesomeIcon className="primary-color2" icon={faDotCircle} /> modify, adapt, translate, reverse engineer, decompile, or disassemble any portion of the Site;
                        </p>
                        <p className="fs-6">
                            <FontAwesomeIcon className="primary-color2" icon={faDotCircle} /> remove any copyright, trademark, or other proprietary rights notice from the Site or materials originating from the Site;
                        </p>
                        <p className="fs-6">
                            <FontAwesomeIcon className="primary-color2" icon={faDotCircle} /> frame or mirror any part of the Site without WHSC’s express prior written consent;
                        </p>
                        <p className="fs-6">
                            <FontAwesomeIcon className="primary-color2" icon={faDotCircle} /> create a database by systematically downloading and storing Site content;
                        </p>
                        <p className="fs-6">
                            <FontAwesomeIcon className="primary-color2" icon={faDotCircle} /> use any robot, spider, site search/retrieval application or other manual or automatic device to retrieve, index, “scrape,” “data mine,” or in any way gather Site content or reproduce or circumvent the navigational structure or presentation of the Site without OSI’s express prior written consent; or
                        </p>
                        <p className="fs-6">
                            <FontAwesomeIcon className="primary-color2" icon={faDotCircle} /> post, email, or otherwise make available via the Site any of the following: “lobbying” or “partisan political activities” materials within the meaning of the United States Internal Revenue Code of 1986, as amended.
                        </p>
                    </div>
                    <div className="termsPage">
                        <h3 className="leftDesign">Security</h3>
                        <p className="fs-6">The World Human Save Committee work to maintain the privacy of any personally identifiable information (name, mailing address, email address, etc.) that may be collected through our websites. Please note, however, that, though this Site has security measures in place, the Foundations do not represent, warrant, or guarantee that personal information will be protected against unauthorized access, loss, misuse, or alterations. Similarly, the Foundations disclaim liability for personal information submitted through this Site. Users are hereby advised that they submit such personal information at their own risk.</p>
                    </div>
                    <div className="termsPage">
                        <h3 className="leftDesign">No Liability for Third Party Content</h3>
                        <p className="fs-6">Site visitors may not post messages or make statements in connection with profiles and/or forums that are inaccurate, misleading, or deceptive. WHSC and the Affiliated Entities neither endorse nor are responsible for any opinion, advice, information or statements made in profiles and/or forums by third parties. Without limitation, WHSC and the Affiliated Entities are not responsible for any information or materials made available through profiles and/or forums (including without limitation errors or omissions in postings or links or images embedded in messages) or results obtained by using any such information or materials. Under no circumstances will WHSC, the Affiliated Entities, or their respective sponsors, employees, officers, directors, shareholders, affiliates, agents, representatives, suppliers, or members be liable for any loss or damage caused by your reliance on such information or materials. The opinions expressed in profiles and/or forums reflect solely the opinions of the individuals who submitted such opinions, and may not reflect the opinions of WHSC.</p>
                    </div>
                    <div className="termsPage">
                        <h3 className="leftDesign">No personal Liability for any member or person who are related with WHSC</h3>
                        <p className="fs-6">Our organization will take no liability or responsibility for any misdeeds, mis-adventures or any financial frauds by any person at their personal level.</p>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Terms;