import React, { useState } from "react";
import "./App.css";
import Home from "./components/Home/Home";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Navbar from "./components/Shared/Navbar/Navbar";
import Footer from "./components/Shared/Footer/Footer";
import "bootstrap/dist/js/bootstrap.min.js";
import OurPeopleData from "./components/About/OurPeople/OurPeopleData";
import OurStory from "./components/About/OurStory/OurStory";
import Membership from "./components/About/Membership/Membership";
import WorkWithUs from "./components/About/WorkWithUs/WorkWithUs";
import Donor from "./components/About/Donors/Donor";
import MissionVision from "./components/About/MissionAndVision/MissionVision";
// import Donate from "./components/Donate/Donate";
import Contact from "./components/Contact/Contact";
import GoTop from "./components/Shared/GoTop/GoTop";
import GalleryComponent from "./components/Gallery/GalleryComponent";
import PrivacyPolicy from "./components/PrivacyPolicy/PrivacyPolicy";
import Terms from "./components/Terms/Terms";
import Management from "./components/About/OurPeople/OurPeopleCards/Management";
import WeStandsFor from "./components/WeStandsFor/WeStandsFor";
import BlogDetails from "./components/WeStandsFor/BlogDetails";
import CookieConsent from "react-cookie-consent";
import Mission from "./components/Mission&Vission/Mission";
import Vission from "./components/Mission&Vission/Vission";
import ExecutiveCommittee from "./components/About/OurPeople/OurPeopleCards/ExecutiveCommittee";
import OurActivity from "./components/Home/OurActivity/OurActivity";
import Country from "./components/CountryData/Country";
import CampaignMain from "./components/Campaign/CampaignMain";
import Error from "./components/Shared/Error/Error";

import FAQ from "./components/About/FAQ/FAQ";
import DonateUs from "./components/Donate/DonateUs";
import ProtectionDesk from "./components/About/OurPeople/OurPeopleCards/ProtectionDesk";
import AdmissionUnit from "./components/About/OurPeople/OurPeopleCards/MediaCell";
import Pakistan from "./components/CountryData/Pakistan";
import HelpThePeopleDetails from "./components/Home/HelpThePeopleData/HelpThePeopleDetails";
import SupportDesk from "./components/About/OurPeople/OurPeopleCards/SupportDesk";
import Patron from "./components/About/OurPeople/OurPeopleCards/Patron";
import CountryCommittee from "./components/About/OurPeople/OurPeopleCards/CountryCommittee";
import GetCertificate from "./components/Certificates/GetCertificate";
import AffiliatedOrganization from "./components/AffiliatedOrganization/AffiliatedOrganization";
import ScrollToTop from "./components/ScrollToTop";
import About from "./components/About/About/About";
import Advisors from "./components/About/OurPeople/OurPeopleCards/Advisors";
import SocialAdvisor from "./components/About/OurPeople/OurPeopleCards/SocialAdvisor";
import MediaCell from "./components/About/OurPeople/OurPeopleCards/MediaCell";



function App() {
  return (
    <div className="position-relative">
      <Router >
        <ScrollToTop />
        <GoTop />

        <Navbar />
        <Switch>
          <Route exact path="/">
            <Home />
          </Route>

          <Route path="/ourPeople">
            <OurPeopleData />
          </Route>
          <Route path="/mission">
            <Mission />
          </Route>
          <Route path="/vision">
            <Vission />
          </Route>
          <Route path="/advisors">
            <Advisors />
          </Route>
          <Route path="/affiliated-organization">
            <AffiliatedOrganization />
          </Route>

          <Route path="/management">
            <Management />
          </Route>
          <Route path="/about-us">
            <About />
          </Route>

          <Route path="/religious-and-social-advisor">
            <SocialAdvisor />
          </Route>

          <Route path="/security-board">
            <ProtectionDesk />
          </Route>

          <Route path="/media-cell">
            <MediaCell />
          </Route>

          <Route path="/executive-committee">
            <ExecutiveCommittee />
          </Route>

          <Route path="/support-desk">
            <SupportDesk />
          </Route>

          <Route path="/patron">
            <Patron />
          </Route>

          <Route path="/country-committee">
            <CountryCommittee />
          </Route>

          <Route path="/gallery">
            <GalleryComponent />
          </Route>
          <Route path="/workWithUs">
            <WorkWithUs />
          </Route>
          <Route path="/ourStory">
            <OurStory />
          </Route>
          <Route path="/ourDonors">
            <Donor />
          </Route>
          <Route path="/faq">
            <FAQ />
          </Route>
          <Route path="/donate">
            <DonateUs />
          </Route>
          <Route path="/contact">
            <Contact />
          </Route>
          <Route path="/membership">
            <Membership />
          </Route>

          <Route path="/mission-and-vision">
            <MissionVision />
          </Route>
          <Route path="/privacy-policy">
            <PrivacyPolicy />
          </Route>
          <Route path="/terms-and-conditions">
            <Terms />
          </Route>
          <Route path="/campaign">
            <CampaignMain />
          </Route>
          <Route path="/we-stands-for" exact>
            <WeStandsFor />
          </Route>
          <Route path="/we-stands-for/:title">
            <BlogDetails />
          </Route>

          <Route path="/our-activity/:title">
            <OurActivity />
          </Route>
          <Route path="/country-data/:title">
            <Country />
          </Route>
          <Route path="/help-the-people-details/:title">
            <HelpThePeopleDetails />
          </Route>
          <Route path="/Pakistan">
            <Pakistan />
          </Route>

          <Route path="/get-certificate">
            <GetCertificate />
          </Route>

          <Route path="*">
            <Error />
          </Route>
        </Switch>

        <Footer />
      </Router>

      <CookieConsent
        location="bottom"
        buttonText="I understand"
        style={{ background: "#273036", padding: "0 30px " }}
        buttonStyle={{
          color: "#273036",
          background: "#ffb422",
          fontWeight: "bold",
        }}
        expires={365}
      >
        This website uses cookies to enhance the user experience.
      </CookieConsent>
    </div>
  );
}

export default App;
