import React from "react";
import "./Mission&vision.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuoteLeft } from "@fortawesome/free-solid-svg-icons";
import "../Home/OurActivity/OurAcitivites.css";
import {
  faFacebookF,
  faTwitter,
  faLinkedinIn,
} from "@fortawesome/free-brands-svg-icons";

const Vission = () => {
  const pageUrl = window.location.href;
  return (
    <section>
      <div className="mission-banner position-relative">
        <div className="mission-banner-desc2">
          <FontAwesomeIcon icon={faQuoteLeft} className="text-white fs-1 " />
          <p className="pt-2 fs-4 ">
            We dream of peace and tranquility around the world especially in the regions of minority torture, suppression and violations of Human Rights.
          </p>
        </div>
      </div>
      <div className="container blog-desc2 pt-3  p-0 ">
        <div className="container px-5 py-2  blog-banner-heading text-center">
          <h2>Vision</h2>
          <div className="d-flex flex-column blog-icons py-0 py-md-3 pe-4">
            <a href="#"><FontAwesomeIcon
              icon={faFacebookF}
              size="lg"
              className="primary-color blog-social-icon"
            /></a>
            <a href="#" className="my-2"><FontAwesomeIcon
              icon={faTwitter}
              size="lg"
              className="primary-color blog-social-icon"
            /></a>
            <a href="#"><FontAwesomeIcon
              icon={faLinkedinIn}
              size="lg"
              className="primary-color blog-social-icon"
            /></a>
          </div>
        </div>
        <p className="pt-5 px-lg-5 px-3 blog-para ">
          We dream of peace and tranquility around the world especially in the regions of minority torture, suppression and violations of Human Rights. People of every religions, sects or groups should live the same quality of life void of fear from persecution and violent attacks. We believe that all humans have the same equal rights regardless of the nationality, race, religion or culture. Our vision is to create an impending bondage in strengthening the pillars of mutual respect, empathy and cultural exchanges across different communities and religions. Our vision is to establish unity of the mankind and all religions of the world which will pave the way for advanced civilization shun of violence. One of the greatest threats of humanity is violence. Ironically, humanity is a greatest threat to itself. We must put an end to violence or violence will put an end to mankind.<br />
          We visualize ceasing of this violence of mankind by uprising the cultural and spiritual conjugation of different sect, ethnic groups and religions. When the society is reeling under self-oblivion and pivoting around personal preferences following strict doctrines, we want to create a more relaxed, peaceful and interconnected society. To swerve the society to the direction that will spread peace, harmony, wisdom, spirituality and prosperity is our prime vision. To shun the overarching numerous myths, prejudices and beliefs that plague the society for centuries. Spreading the true values of Hinduism and other religions and shielding the citizens with proper intellectual wisdom for co-existence.<br />
          We are focusing for uplifting the people from poverty and slums for a better future and more hygienic social living standards. Socio-economic boost for co-existence and better acceptance of the down trodden to the society. The need for a healthy nutrition of poor children and adequate facilities of basic education is also paramount to us. We will encourage and create think tanks of dynamic and energetic youth to find cure for the panacea that is plaguing our present rat racing society. We want to strengthen every society and make them contribute for Nation building throughout the globe

        </p>

        <div className="blog-bottom py-5 w-100 d-flex justify-content-evenly ">
          <h2 className="text-center text-white">World Hindu Struggle Committee</h2>
        </div>
      </div>
    </section>
  );
};

export default Vission;
