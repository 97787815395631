
//what we do data
const FooterData2 = [
  {
    id: 1,
    list: "Our Activities",
    url: "/#our-activity",
  },
  {
    id: 2,
    list: "We Stand For",
    url: "/we-stands-for",
  },
  {
    id: 3,
    list: "Our Campaign",
    url: "/campaign",
  },

];
export default FooterData2;
