import React from 'react';
import './Banner.css';

const Banner = ({ bannerTitle, bannerDesc, bannerImg }) => {
    return (
        <div className="main-image">
            <div className="bannerImg">
                <img src={bannerImg} alt="" />
            </div>
            <div className="overlay">
                <div className="banner-content">
                    <h1><span>{bannerTitle}</span></h1>
                    <p>{bannerDesc}</p>
                    <a href="/membership" className="bannerBtn">Join Us</a>
                </div>
            </div>
        </div>
    );
};

export default Banner;