import React from "react";
import Banner2 from "../Shared/Banner2/Banner2";
import privacyPolicy from "../images/privacy.jpg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDotCircle } from "@fortawesome/free-regular-svg-icons";
import PrivacyComponents from "./PrivacyComponents";


const PrivacyPolicy = () => {
  return (
    <section>
      <Banner2
        bannerImg={privacyPolicy}
        bannerTitle="PRIVACY POLICY"
        link="HOME"
        link2="PRIVACY POLICY"
      />
      <div className="text-justify">
        <div className="text-center pt-5">
          <h1 className="primary-color text-uppercase">
            WHSC Privacy Statement
          </h1>
        </div>
        <div className="container col-md-8 mt-5 mb-5 termsPage">
          <div className="termsPage">
            <h3 className="leftDesign">What is this Privacy Statement?</h3>
            <p className="fs-6 text-wrap">
              This Privacy Statement sets out "WHSC" information processing
              practices in relation to you and your personal data and applies
              when we collect and process personal data from you through our
              website and mobile applications. It explains WHSC’s information
              processing practices and describes your rights regarding your
              personal data.
            </p>
          </div>
          <div className="termsPage">
            <h3 className="leftDesign">
              Who is responsible for your information?
            </h3>
            <p className="fs-6">
              "WHSC" refers to World Human Save Committee and its various parts
              around the world, including its affiliated charities, branch
              offices and other registered entities.
            </p>
          </div>
          <div className="termsPage">
            <h3 className="leftDesign">
              When and how do we collect your information?
            </h3>
            <p className="fs-6">
              "WHSC" collects personal data about you when you:{" "}
            </p>
            <ol>
              <li className="m-2 fs-6">
                <FontAwesomeIcon
                  className="primary-color2"
                  icon={faDotCircle}
                />{" "}
                Visit our website or use an WHSC mobile app.
              </li>
              <li className="m-2 fs-6">
                <FontAwesomeIcon
                  className="primary-color2"
                  icon={faDotCircle}
                />{" "}
                Make a donation.
              </li>
              <li className="m-2 fs-6">
                <FontAwesomeIcon
                  className="primary-color2"
                  icon={faDotCircle}
                />{" "}
                Volunteer.
              </li>
              <li className="m-2 fs-6">
                <FontAwesomeIcon
                  className="primary-color2"
                  icon={faDotCircle}
                />{" "}
                Register for an WHSC event.
              </li>
              <li className="m-2 fs-6">
                <FontAwesomeIcon
                  className="primary-color2"
                  icon={faDotCircle}
                />{" "}
                Engage in a campaign by signing an online petition.
              </li>
              <li className="m-2 fs-6">
                <FontAwesomeIcon
                  className="primary-color2"
                  icon={faDotCircle}
                />{" "}
                Made an inquiry.
              </li>
              <li className="m-2 fs-6">
                <FontAwesomeIcon
                  className="primary-color2"
                  icon={faDotCircle}
                />{" "}
                Subscribe to an online publication or Apply for employment.
              </li>
            </ol>
          </div>
          <div className="termsPage">
            <h3 className="leftDesign">What personal data do we collect?</h3>
            <p className="fs-6">
              We may collect some or all of the following types of information
              including:
            </p>
            <div>
              <p className="fs-6">
                <FontAwesomeIcon
                  className="primary-color2"
                  icon={faDotCircle}
                />{" "}
                <span className="fw-bold">Personal data:</span> Our websites may
                collect personal data, information that either directly
                identifies you or could reasonably be used to identify you.
                Examples of personal data include your name, contact
                information, email address, and other information in combination
                with such identifiers.
              </p>
              <p className="fs-6">
                <FontAwesomeIcon
                  className="primary-color2"
                  icon={faDotCircle}
                />{" "}
                <span className="fw-bold">Mobile devices information:</span> If
                you access our websites on your mobile telephone or mobile
                device, we may also collect your unique device identifier and
                mobile device IP address, as well as information about your
                device's operating system, mobile carrier and your location
                information. We may also ask you to consent to providing your
                mobile phone number (for example, so that we can send you push
                notifications).
              </p>
              <p className="fs-6">
                <FontAwesomeIcon
                  className="primary-color2"
                  icon={faDotCircle}
                />{" "}
                <span className="fw-bold">Other information:</span> In some
                instances, we automatically collect other basic information
                about you which does not directly identify you, but which may
                correspond with you or a particular device. We use this
                information to learn more about how our website is used and to
                otherwise improve and administer the site. Automated
                technologies may include the use of web server logs to collect
                IP addresses, "cookies" and web beacons. Further information
                about our use of cookies can be found below in the Cookies and
                other tracking technologies section at the end of this
                Statement.
              </p>
            </div>
          </div>
          <div className="termsPage">
            <h3 className="leftDesign">How do we use your personal data?</h3>
            <p className="fs-6">
              We use the personal data you provide to us to:
            </p>
            <div>
              <p className="fs-6">
                <FontAwesomeIcon
                  className="primary-color2"
                  icon={faDotCircle}
                />{" "}
                Provide you with personalized service.
              </p>
              <p className="fs-6">
                <FontAwesomeIcon
                  className="primary-color2"
                  icon={faDotCircle}
                />{" "}
                Operate our website and understand its use, for statistical
                purposes (number of site visits, average time visitors spend at
                the site, etc.) and security purposes.
              </p>
              <p className="fs-6">
                <FontAwesomeIcon
                  className="primary-color2"
                  icon={faDotCircle}
                />{" "}
                Manage and process inquiries, registrations, donations and other
                interactions with you.
              </p>
              <p className="fs-6">
                <FontAwesomeIcon
                  className="primary-color2"
                  icon={faDotCircle}
                />{" "}
                Send you information you have requested or consented to
                receiving such as newsletters, breaking news, and other
                information regarding relevant Human Rights Watch Activities.
              </p>
            </div>
          </div>
          <PrivacyComponents />
        </div>

      </div>
    </section>
  );
};

export default PrivacyPolicy;
