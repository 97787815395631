import React from "react";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import "./Home.css";

// import "../../App.css";

const HeroSection = () => {
  const settings = {
    dots: true,
    fade: true,
    infinite: true,
    autoplay: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    speed: 1000,
    autoplaySpeed: 6000,
    cssEase: "linear",
  };

  return (
    <section className="hero-section overflow-hidden ">
      <Slider {...settings}>
        <div className="hero-bg1">
          <div className="slider-description slide-content  ">
            <h3>
              Help the Minorities <br />Around
              <span className="primary-color"> The World</span>
            </h3>
            <h4>Raise Your Voice & Stand with WHSC</h4>
            <p className="py-2">
              Fundamentalist attacks minorities every day, and the government silently supports them. Specifically, in Bangladesh and Pakistan, it happened openly and steadily.
              No one cares about minorities. Everything is going against minorities day by day, and the government covertly supports ethnic cleansing.
              This is the time to protect them who are violating human rights around the world.</p>
            <div>

              <Link to="/workWithUs">

                <button className="primary-btn">Join With Us</button>

              </Link>
            </div>
          </div>
        </div>
        <div className="hero-bg2">
          <div className="slider-description slide-content ">
            <h3>
              Help The Child Care <br />And
              <span className="primary-color"> Education Project</span>
            </h3>
            <h4>Help to Build Better & Secure world </h4>
            <p className="py-2">
              The development objective of the Early Childhood Education (ECE) Project for Lao People's Democratic Republic is to increase coverage and improve the quality of early childhood education services for 3 to 5 year olds in targeted countries.
              It is the time when a child’s brain is most flexible and experiences rapid growth. But brain development is sensitive to experience. A child exposed to neglect or violence will very likely suffer cognitive, behavioral or emotional difficulties.

              But most parents are unaware of this scientific fact, which forms the core of Early Childhood Care and Development. The approach is equipped to provide children, parents are caregivers the mandatory elements of development - early nutrition, stimulation, protection and learning.
            </p>
            <div>
              <Link to="/donate">
                <button className="primary-btn">Donate</button>
              </Link>
            </div>
          </div>
        </div>
        <div className="hero-bg3">
          <div className="slider-description slide-content">
            <h3>
              Stop Human Rights Violations<br />
              <span className="primary-color">Around You</span>
            </h3>
            <h4>Raise Your Voice & Stand For Humanities</h4>
            <p className="py-2">
              Human Rights Violations are happening around the world. They are  happening in the Middle East, Africa, Asia, Europe, and North America.
              The organization’s objective is to conduct research and generate action to prevent and end grave abuses of human rights, and to demand justice for Hindus & other minorities those whose rights have been violated.
              In many cases, the government is increasingly militarized, and police and judicial systems are corrupted. Abductions, arbitrary arrests, detentions without trial, and other human rights violations are happening every day.
            </p>
            <div>
              <Link to="/donate">
                <button className="primary-btn">Donate</button>
              </Link>
            </div>
          </div>
        </div>
      </Slider>
    </section >
  );
};

export default HeroSection;
