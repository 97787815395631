import React from 'react';
import { Link } from 'react-router-dom';
import './ContactAction.css';
import Fade from 'react-reveal/Fade';

const ContactAction = ({ title, link, url }) => {
    return (
        <section className="TopSubFooterBg">
            <div className="custom-container">
                <div className="row TopSubFooter">
                    <Fade left>
                        <div className="col-md-8 my-auto">
                            <h3 className="my-auto text-uppercase mb-2">{title}</h3>
                        </div>
                    </Fade>
                    <Fade right>
                        <div className="my-auto col-md-4">
                            <Link to={url}><button className="">{link}</button></Link>
                        </div>
                    </Fade>
                </div>
            </div>
        </section>
    );
};

export default ContactAction;