import React from "react";
import VisibilitySensor from "react-visibility-sensor";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import missionImage from "../../images/missionImg.jpg";
import visionImage from "../../images/visionImg.jpg";
import "./MissionVision.css";
import Banner2 from "../../Shared/Banner2/Banner2";
import bannerImg from "../../images/joinUs.png";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

// Animation
import { easeQuadInOut } from "d3-ease";
import AnimatedProgressProvider from "./AnimatedProgressProvider";
import { Link } from "react-router-dom";

const MissionVision = () => {
  const targetCounter1 = 1500;
  const targetCounter2 = 800;
  const targetCounter3 = 30;
  const targetCounter4 = 50;

  return (
    <div className="overflow-hidden">
      <Banner2
        bannerImg={bannerImg}
        bannerTitle="OUR MISSION AND VISION"
        link="ABOUT"
        link2="MISSION & VISION"
      />
      <section className="overflow-hidden">
        <div className="custom-container py-5">
          <div className="row justify-content-center align-items-center">
            <div className="col-md-6 margin-bottom-mv">
              <div className="heading-style2">
                <span>Our Mission</span>
              </div>
              <div className="title">LET'S STAND TOGETHER FOR HUMAN RIGHTS</div>
              <p className="mision-vision-description">
                World Hindu Struggle Committee here to provide a global platform for Hindus to connect, share ideas, inspire one another, and impart common good in the society. The Hindu minority in particular and other minorities in general, especially the minorities living in the Middle East and Asia, are persecuted and threatened with ethnic cleansing, as in Bangladesh, Arab and Islamic countries. It is our obligation in the free world to stand against any attempt to harm Hindu minorities and other minorities such as Jews and Buddhist, in the Middle East, Druze, Yazidis, Assyrians, and others.<br />
                Let's know about our Mission:
              </p>

              <Link to="/mission">
                <button className="mission-vision-btn">
                  Our Mission
                  <FontAwesomeIcon
                    className="mx-2 primary-color"
                    icon={faArrowRight}
                  />
                </button>
              </Link>
            </div>
            <div className="col-md-6">
              <div className="bg-dark">
                <img
                  className="w-100  bg-dark opacity-50"
                  src={missionImage}
                  alt="mission"
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="counter">
        <div className="counter-sec">
          <div className="container p-2">
            <h2 className="text-center fs-1">Working Globally</h2>
            <div className="row p-5">
              <div className="col-lg-3 col-sm-6 center-content">
                <div className="circle">
                  <VisibilitySensor>
                    {({ isVisible }) =>
                      isVisible ? (
                        <AnimatedProgressProvider
                          valueStart={0}
                          valueEnd={targetCounter1}
                          duration={6}
                          easingFunction={easeQuadInOut}
                        >
                          {(value) => {
                            const roundedValue = Math.round(value);
                            return (
                              <CircularProgressbar
                                value={value}
                                text={`${roundedValue}+`}
                                styles={buildStyles({ pathTransition: "none" })}
                              />
                            );
                          }}
                        </AnimatedProgressProvider>
                      ) : (
                        <CircularProgressbar value={100} text={`${500}+`} />
                      )
                    }
                  </VisibilitySensor>
                </div>
                <h4 className="mb-3">Global Volunteers</h4>
              </div>

              <div className="col-lg-3 col-sm-6 center-content">
                <div className="circle">
                  <VisibilitySensor>
                    {({ isVisible }) =>
                      isVisible ? (
                        <AnimatedProgressProvider
                          valueStart={0}
                          valueEnd={targetCounter2}
                          duration={6}
                          easingFunction={easeQuadInOut}
                        >
                          {(value) => {
                            const roundedValue = Math.round(value);
                            return (
                              <CircularProgressbar
                                value={value}
                                text={`${roundedValue}+`}
                                styles={buildStyles({ pathTransition: "none" })}
                              />
                            );
                          }}
                        </AnimatedProgressProvider>
                      ) : (
                        <CircularProgressbar value={100} text={`${733}+`} />
                      )
                    }
                  </VisibilitySensor>
                </div>
                <h4 className="mb-3">Registered Members</h4>
              </div>

              <div className="col-lg-3 col-sm-6 center-content">
                <div className="circle">
                  <VisibilitySensor>
                    {({ isVisible }) =>
                      isVisible ? (
                        <AnimatedProgressProvider
                          valueStart={0}
                          valueEnd={targetCounter3}
                          duration={6}
                          easingFunction={easeQuadInOut}
                        >
                          {(value) => {
                            const roundedValue = Math.round(value);
                            return (
                              <CircularProgressbar
                                value={value}
                                text={`${roundedValue}+`}
                                styles={buildStyles({ pathTransition: "none" })}
                              />
                            );
                          }}
                        </AnimatedProgressProvider>
                      ) : (
                        <CircularProgressbar value={100} text={`${30}+`} />
                      )
                    }
                  </VisibilitySensor>
                </div>
                <h4 className="mb-3">Organization With Us</h4>
              </div>
              <div className="col-lg-3 col-sm-6 center-content">
                {/* <h2 className="mb-3">Member</h2> */}
                <div className="circle">
                  <VisibilitySensor>
                    {({ isVisible }) =>
                      isVisible ? (
                        <AnimatedProgressProvider
                          valueStart={0}
                          valueEnd={targetCounter4}
                          duration={6}
                          easingFunction={easeQuadInOut}
                        >
                          {(value) => {
                            const roundedValue = Math.round(value);
                            return (
                              <CircularProgressbar
                                value={value}
                                text={`${roundedValue}+`}
                                styles={buildStyles({ pathTransition: "none" })}
                              />
                            );
                          }}
                        </AnimatedProgressProvider>
                      ) : (
                        <CircularProgressbar value={100} text={`${40}+`} />
                      )
                    }
                  </VisibilitySensor>
                </div>
                <h4 className="mb-3">Working Country</h4>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="vision overflow-hidden">
        <div className="custom-container">
          <div className="row justify-content-center align-items-center">
            <div className="col-md-6 margin-bottom-mv">
              <div className="bg-dark">
                <img
                  className="w-100  bg-dark opacity-50"
                  src={visionImage}
                  alt="vision"
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="heading-style2">
                <span>Our Vision </span>
              </div>
              <div className="title">LET'S KNOW ABOUT OUR VISION</div>
              <p className="mision-vision-description">
                We dream of peace and tranquility around the world especially in the regions of minority torture, suppression and violations of Human Rights. People of every religions, sects or groups should live the same quality of life void of fear from persecution and violent attacks.We believe that all humans have the same equal rights regardless of the nationality, race, religion or culture.
              </p>

              <Link to="/vision">
                <button href="/vision-details" className="mission-vision-btn">
                  Our Vision
                  <FontAwesomeIcon
                    className="mx-2 primary-color"
                    icon={faArrowRight}
                  />
                </button>
              </Link>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default MissionVision;
