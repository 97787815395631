import React from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";

const GalleryCarousel = ({ content }) => {
  return (
    <div>
      <Carousel
        showThumbs={false}
        autoPlay={true}
        centerMode={false}
        infiniteLoop={true}
        showStatus={false}
      >
        {content.map((img) => (
          <div>
            <img
              className="carosel-image-size"
              src={img.image}
              alt="gallery-Images"
            />
            <p className="legend">
              <h2 className="caption">{img.caption}</h2>
            </p>
          </div>
        ))}
      </Carousel>
    </div>
  );
};

export default GalleryCarousel;
