import React from "react";
import { Link } from "react-router-dom";

const TopFooter = () => {
  return (
    <section className="">
      <div className="py-5  top-footer border-gradient ">
        <div className="row ">
          <div className="col-md-8 donate-desc ps-4">
            <h2>Protecting Rights, Saving lives</h2>
            <p>
              WHSC is a dedicated international team who are fighting to raise voice against fundamentalism, terrorism, and anti-human group.
            </p>
          </div>
          <div className="col-md-4 m-auto text-center">
            <Link to="/donate">
              <button className="primary-btn3 ">Donate Now</button>
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
};

export default TopFooter;
