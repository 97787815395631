import React, { useState, useEffect } from "react";
import { Card } from "react-bootstrap";

const AllCampaign = () => {

  // ?Date Formate //
  var moment = require("moment");

  const [allCampaign, setAllCampaign] = useState([]);
  useEffect(() => {
    fetch("https://whsc.in/wp-json/wp/v2/posts?categories=699")
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        setAllCampaign(data);
      });
  }, []);

  return (
    <section className="mt-5">
      <div>
        <div className="container-fluid">
          <div className="heading-style4 ">
            <h2 className="text-dark">ALL CAMPAIGNS</h2>
          </div>
          <div className="mt-5">
            <div className="row gx-2 gy-4 d-flex justify-content-center">
              {allCampaign.slice(4).map((data) => (
                <Card
                  id="animation"
                  className="border-0 bg-transparent col-lg-3 col-md-4 col-sm-12"
                >
                  <div className="single-campaign-card bg-white">
                    <div className="overflow-hidden">
                      <Card.Img
                        variant="top"
                        src={data.jetpack_featured_media_url}
                        className="campaignImg p-2"
                      />
                    </div>
                    <Card.Body className="px-3">
                      <Card.Text className="text-dark fw-bold">
                        <small>{moment(data.date).format('D MMM YYYY')}</small>
                      </Card.Text>
                      <Card.Title className="text-dark fs-6 cardDetailEclipse">
                        {data.title.rendered}
                      </Card.Title>
                    </Card.Body>
                    <Card.Footer className="text-center border-0 p-0">
                      <a
                        className="runningCampaign"
                        href={data.link}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Campaign Details
                      </a>
                    </Card.Footer>
                  </div>
                </Card>
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AllCampaign;
