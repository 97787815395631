import React from "react";
import img from "../images/output-onlinepngtools (3).png";
import img2 from "../images/output-onlinepngtools.png";
import img3 from "../images/output-onlinepngtools (1).png";
import img4 from "../images/output-onlinepngtools (2).png";

const WhatWeDo = () => {
  return (
    <section className=" top-gap">
      <div className="heading-style3 ">
        <h2>What We Do</h2>
      </div>
      <div className="row  custom-container  mx-auto">
        <div className="col-md-4 pt-lg-4 pt-md-5 p-0">
          <div className="our-description">
            <h3> Deliver Legal Support To Stop Ethnic Persecution</h3>
            <p className="py-2 content-font ">
              WHSC condemns all forms of Ethnic Cleansing and Persecution. We can deliver Legal or Moral support to the affected communities or groups worldwide through our strong Legal Cell.
            </p>
          </div>
          <div className="our-description">
            <h3 className="pt-3">Promote Sustainable Development For The Underprivileged</h3>
            <p className="py-2 content-font ">
              We facilitate projects for the alleviation of poverty. Uplift people morally and socially and empower them with basic needs for education residing in slums, villages or urban areas of a country.
            </p>
          </div>
        </div>
        <div className="col-md-4">
          <div className="py-3 ">
            <div class="honeycomb">
              <div class="ibws-fix">
                <div class="hexagon">
                  <div class="hexagontent">
                    <img src={img} className="img-fluid w-50" alt="" />
                  </div>
                </div>
                <div class="hexagon">
                  <div class="hexagontent">
                    <img src={img2} className="img-fluid w-50" alt="" />
                  </div>
                </div>
              </div>
              <div class="ibws-fix">
                <div class="hexagon">
                  <div class="hexagontent">
                    <img src={img4} className="img-fluid w-50" alt="" />
                  </div>
                </div>
                <div class="hexagon">
                  <div class="hexagontent">
                    <img src={img3} className="img-fluid w-50" alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-4 pt-3 mt-lg-2 mt-md-5 p-0">
          <div className="our-description pt-5 mt-lg-0 mt-md-5">
            <h3 className="primary-color">Protect Minorities Rights & Human Rights Worldwide</h3>
            <p className="py-2 content-font ">
              We work to protect minorities like The Hindus, Buddhists, Druzes, Yazidis, Assyrians and other small and ethnic groups across the globe.
            </p>
          </div>
          <div className="our-description">
            <h3 className="pt-3 primary-color">Maintain International Peace and Human Rights</h3>
            <p className="py-2 content-font ">
              To bring about universal love, peace and harmony worldwide by reiterating and reaffirming the fundamental principles of Humanity and supporting Human Rights groups and activists.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default WhatWeDo;
