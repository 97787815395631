import React from "react";
import Banner from "../../Shared/Banner/Banner";
import "../OurStory/OurStory.css";
import bannerImg1 from "../../images/bannerImg1.jpg";
import story1 from "../../images/ac1.jpg";
const StoryInfo = [
  {
    title: "Started The Journey",
    img: "story1",
    desc: "All the parties that came to power in Bangladesh, including the Awami League (AL) or the Bangladesh Nationalist Party (BNP), did not help the cause of the minorities of the country. BNP and Jamat Party openly indulged in the ethnic cleansing, while AL did the same thing with more vigor but clandestinely. The indifference shown towards the minorities brought in bitter resentment within the minorities which paved the way of creating an united forum. So, Mr. Basu started approaching Indian Political Parties and Organizations.",
    date: "2001",
  },

  {
    title: "Contated with international Human Rights Activists",
    desc: "Mr. Basu knew that nobody can help from Bangladesh, so after a strenuous, painstaking and dedicated effort he looked for support from outside Bangladesh. He contacted many Indian Political Parties and Organizations, and unveiled the true story of the fate of minorities in Bangladesh.",
    date: "2002",
  },

  {
    title: "Started The Tough Journey",
    desc: "He was discouraged by the administration, because he refused to recognize the subsequent governments, and didn’t even go to vote for his mother, who fought an election of the local body. His thought was that, voting means he approved or recognized the government or administration, which he refused to do, where there is no security for the minorities. As days passed, he started to contact Human Rights.Activists from the far West and appealed to them to come forward and assist the minorities of the country. He rigorously started working from within his community since 2003.",
    date: "2003",
  },

  {
    title: "Contacted with international Human Rights Activists",
    desc: "Mr. Basu realized that the minorities are born in the same country, paying the same amount of taxes but do not enjoy the same security and benefits as the majority. The successive governments simply by-passed and brushed aside the basic support from them. All the governments turns a blind eye about the minority torture and suppression issue.\n\nIn 2005 Mr. Basu contacted Human Rights activist Dr Richard L. Benkin and started to give information’s about “Human Rights violations on Hindus” in Bangladesh.",
    date: "2005",
  },
  {
    title: "Spread tortured report of Minorities",
    desc: "In the years 2006, 2007 Mr. Basu quietly worked and kept on passing information about the perilous condition of the minorities and the backlash they face form civil and political fronts. Reports of that, even the government was in favor of ethnic cleansing of the minorities which was previously unknown to the world.",
    date: "2006-2007",
  },


  {
    title: "Issues Placed Internationally",
    desc: "Mr. Basu started raising his voice loud and clear in 2008 at Siliguri in India, Maitree Sambelani Hall, under the banner Hindu Struggle Committee.  Many foreign Human Rights Activists were present, including Dr. Benkin who came to India to attend the conference. Mr. Basu pointed out about the persecution, insecurity and injustice that the minorities are facing and the dire situation prevailing within the country and also the refugees who came to India. Awestruck, the foreign delegate activists apprised him of support for his endeavor ahead. Dr. Richard Benkin was present at the conference to address the problems of people, and the commencement of strong ties between Hindus and Jews started. One can find the references in his book “A Quiet Case of Ethnic Cleansing: The Murder of Bangladesh’s Hindus” by Dr.Richard L. Benkin.",
    date: "2008",
  },

  {
    title: "Raising voice internationally for Minorities",
    desc: "The team of silent workers worked tirelessly to make this ethnic cleansing a big focus in the International arena which was previously unknown to the world. The Bangladesh government was good in suppressing the story of the dwindling population of the Hindus and other minority groups. The population of the minorities had decreased drastically. In 1947, as per as population statistics go, minorities were 29.7% of the population. In 1971, the number came down to 20%. In this sense, partition politics of persecution persisted even after 1947.",
    date: "2010-2011",
  },
  {
    title: "Arrange International Human Rights Conference In Kolkata Press Club",
    desc: "Mr. Basu arranged for a major press meet at the Kolkata Press Club on 8 th February, to address the previously unknown facts of the minority Hindu persecution going on in Bangladesh. Many dignitaries were present in the meet including warriors of the Hindu society of Bangladesh like Mr. Anik Roy,Mr. Ajoy Thander, Late Anirban Halder and many others whose combined efforts made the press meet a grand success. Dr. Richard Benkin was also present at the meet and he was facilitated to unveil his new book “A Quite Case Of Ethnic Cleansing: The Murder Of Bangladesh Hindus”. Most of the information on the book was passed on by Mr. Basu at that time when internet was scarce in Bangladesh and there were equally scare social media platforms. Mr. Basu took the pain on himself to travel distances to pass on the information through different sources",
    date: "2012",
  },
  {
    title: "Invite Human Rights Activists to visit Bangladeshi Hindus",
    desc: "In 2013, from 16th to 21st February, Dr. Richard L Benkin and others visited Bangladesh to see the plight of Hindus, and Mr. Basu took him to various places of concern where he could get a first hand information. As part of the International Human Rights Community in Bangladesh he met Hindu MPs, Home Minister, Law Minister and Foreign Minister. The Hindu MP’s were asked to raise their voices for the Hindu community, but he got the reply that on raising their voices for Hindus, their membership in the party would be canceled. On 25th Feb 2013, Dr. Benkin met with the then CM of Gujrat and currently the PM of India, Mr. Narendra Modi and presented him the book ‘A Quite Case of Ethnic Cleansing: The Murder Of Bangladesh Hindus’.  In Bangladesh, Mr. Basu submitted a detailed documentation of the plight of Hindus in Bangladesh and requested him to raise his voice and to open the eyes of the International Community to secure the lives of the Bangladeshi Hindus. Mr. Basu was also involved in the ‘Ganajagaran Mancha’ (a public awakening forum) after non-stop violence for almost a month on the minority Hindus because of a court ordered death sentence to Jamamt-e-Islami leader Maulana Delawar Hossain Saidi.  People set up a platform of a large movement called 'Ganajagaran Mancha' in Shahbag, Dhaka the capital of Bangladesh, demanding severe punishment for the war criminals. Later, after the verdict of Delwar Hossain Saidi was announced on 28th February, the protest program against the violence of Jamaat-e-Islami all over the country continued from this 'Ganajagaran Mancha'. Mr. Shipan Kumar Basu also played a significant role against injustice by being directly involved in the 'Ganajagaran Mancha' of that day.  ",
    date: "2013",
  },
  {
    title: "Working Officially With International Human Rights Activists",
    desc: "In 2014 during general elections, with a renewed energy the Muslim radicals started doing all types of atrocities on Hindus. In this year the leader of Jamaat-e-Islam got hanged, who was Rajakars (people of Bangladesh who helped the enemy, the Pakistan Army) during 1971 war of Independence and never supported the formation of Bangladesh. In that year in Bangladesh, many temples were demolished by the radical Islamists. Mr, Basu was Working President of ‘In Search of Roots’ affiliated with ‘Interfaith Strength’, an organization of Dr Richard L Benkin, USA.  At the same time Mr. Basu contacted with Mr. Mendi Safadi, who is an Israeli diplomat and they exchanged information about the gravity of the situation and promised Mr. Basu that he will soon meet him in person. ",
    date: "2014",
  },

  {
    title: "Working With Israeli Diplomats",
    desc: "Mr. Mendi flew down as a messiah to Mr. Basu and after hearing the grave situation of the Bangladeshi Hindus he promised to put up the matter internationally. Mr. Basu was able to convey the message that the matter is no longer a single state issue and have catapulted to a regional issue. When the Indian Subcontinent was divided into three countries, Hindus were severely tortured, tormented, raped, killed and their land grabbed in countries like Bangladesh and Pakistan. Gauging the severity of the matter, he placed the whole issue at the highest levels of the European Union (EU) and United Nations (UN). Mr. Mendi worked tirelessly for the cause at the International level forums. He hoped that he will be able to complete the mountainous task and bestow justice on the pitiful minorities in the country. Mr. Mendi Safadi came to New Delhi, India, in response to Mr. Basu’s appeal for Hindu genocide in Bangladesh. Mr. Aslam Chowdhury (Member of Bangladesh Nationalist Party and Governor of Lions Club) had a meeting at Constitution Club of India. On return to Bangladesh, Mr. Aslam Chowdhury got arrested and is still in prison.",
    date: "2015",
  },

  {
    title: "Officially Start Brotherhood",
    desc: "Mr.Basu gave a fully fledged documentation, of the ongoing atrocities of the minority Hindus and other small and ethnic groups, at the Israeli Embassy in New Delhi and also sent a copy, along with a letter, to the then Israeli PM Mr. Benjamin Netanyahu in 2016 and officially established the brotherhood between Hindus and Jews. The letter of Mr.Basu to the Israeli Prime Minister was published in Jerusalem Online.",
    date: "2016",
  },

  {
    title: "Internationally Placed Issues",
    desc: "A major conference was held under the banner Hindu Struggle Committee at the Kolkata Press Club on the 18 th of March 2017, where Mr. Frank Creyelman was present. Mr. Creyelman was a Member of Parliament of The European Union, and Vice President of Flemish Party, Belgium.",
    date: "2017",
  },

  {
    title: "World Hindu Struggle Committee Committed to the Fight against the atrocities of the Hindus",
    desc: "The organization was getting bigger and many national and international dignitaries were getting involved in the organization. The tentacles were spreading beyond the borders and minorities from different countries were also facing the same grave persecutions. Minorities form other counties were also facing the same tortures and persecutions and the matter was no more a regional issue anymore. So, Mr. Basu thought that his organization should go global and not remain confined within a state or country. He then changed and registered the name of Hindu Struggle Committee to World Hindu Struggle Committee, thus a legendary organization was born. Keeping the dedication and effort put in by Mr. Mendi Safadi in mind, Mr. Basu honored him as the sole mentor of the organization. He proposed bringing the insecure, distressed and hapless Hindu minorities to come together under a common platform and raise their voices to the international community about their plight and the insecurity they face as minorities.\n\n",
    date: "2018",
  },
];

const OurStory = () => {
  return (
    <section>
      <Banner
        bannerImg={bannerImg1}
        bannerTitle="The Untold Story Of WHSC"
        bannerDesc="It was not easy to start raising a group's voice against fundamentalism and start a humanitarian organization, but it happened."
      />
      <div className="pt-5" id="scrollDown">
        <div class="container py-5">
          <div className="heading-style2">
            <span>Our Story</span>
          </div>
          <div className="py-3">
            <p className="text-justify">
              The story started when Mr. Shipan Kumar Basu realized that the situation of minority Hindus in Bangladesh is precarious. Every day new reports of atrocities, torture, rape, arson, and cultural or social discrimination against the minorities surface from many quarters of the country. At a very young age, he realized that he had to dedicate his life to bringing back the lost glory of the minorities in Bangladesh.
              In 1986, he was just a toddler when he tasted political turmoil after the administration arrested his close relative. Steadily he started the mountainous task. Mr.Basu’s turning point came after his political mentor Mr.Kalidas Boral was killed in 20 th August, 2000. A gruesome political murder against the Hindu minorities of the country forced him to think differently from the rest. He protested peacefully but was subsequently jailed. He was released after a month and seventeen days later.
            </p>

          </div>


          {StoryInfo.map((data) => (
            <div class="timeline-item" date-is={data.date}>
              <h3>{data.title}</h3>
              <p>{data.desc}</p>

            </div>
          ))}
          <div className="pt-5">
            <p>World Hindu Struggle Committee (WHSC) likes to foresee a society that will spread peace, harmony, wisdom, spirituality and prosperity. To shun the overarching numerous myths, prejudices and beliefs that plague our society for centuries. Spreading the true values of Hinduism and other religions and shielding the citizens with proper intellectual wisdom for co-existence. We are focusing for uplifting the people from poverty and slums for a better future and more hygienic social living standards. Socio-economic boost for co-existence and better acceptance of the down trodden to the society. The need for a healthy nutrition of poor children and adequate facilities of basic education is also paramount to us. </p>
          </div>
        </div>

      </div>
    </section>
  );
};

export default OurStory;
