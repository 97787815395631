import React from "react";
import "./../Card.css";
import { Link } from "react-router-dom";
import cardTop from "../../../About/OurPeople/OurPeopleImg/cardTopBlack.png";
import bannerImg2 from "../../../images/boardDirector.jpg";
import Banner2 from "../../../Shared/Banner2/Banner2";
import Moshe from "../../../images/our-people/security-advisor/Moshe-Shohani.png";
import General from "../../../images/our-people/security-advisor/General-Robert-Biazon.png";
import Carlos from "../../../images/our-people/security-advisor/Lieutenant-General-Carlos-Augusto-Berros.png";
import Munindra from "../../../images/our-people/security-advisor/Munindra-Rajbhandari.png";
import Taron from "../../../images/our-people/security-advisor/Taron-Abranamyan.png";
import Lzaltino from "../../../images/our-people/security-advisor/Lzaltino-Donizeti-Oliveira.png";
import Boukhalef from "../../../images/our-people/security-advisor/Mohamed-Boukhalef.png";


const ProtectionDeskData = [
  {
    name: "Moshe Shohani",
    designation: "Chief Security Advisor",
    personImg: Moshe,
    country: "Israel",
    bio: "Moshe Shohani is the Chief security advisor of WHSC.",
  },
  {
    name: "General Robert S Biazon",
    designation: "Security Advisor",
    personImg: General,
    country: "Philippines",
  },
  {
    name: "LTG Carlos Augusto ODE Berros",
    designation: "Security Advisor",
    personImg: Carlos,
    country: "Brazil",
  },
  {
    name: "Munindra Rajbhandari",
    designation: "Security Advisor",
    personImg: Munindra,
    country: "Nepal",
  },
  {
    name: "Taron Abranamyan",
    designation: "Security Advisor",
    personImg: Taron,
    country: "Albenia",
  },
  {
    name: "Dr Lzaltino Donizeti De Oliveira",
    designation: "Security Advisor",
    personImg: Lzaltino,
    country: "Brazil",
  },
  {
    name: "Mohamed Boukhalef",
    designation: "Security Advisor",
    personImg: Boukhalef,
    country: "France",
  },
];

const ProtectionDesk = () => {
  return (
    <section>
      <Banner2
        bannerImg={bannerImg2}
        bannerTitle="Security Advisor"
        link="Our People"
        link2="Security Advisor"
        url1="/ourPeople"
        className="position-relative"
      />
      <div className="container">
        <div className="py-5">
          <div className="text-center ">
            <div className="heading-style4 ">
              <h2 className="text-black">Security Advisor</h2>
            </div>
            <p>
              PI is governed by a Board of Directors which is responsible for
              the overall strategy and governance of the organisation.
            </p>
            <div className="py-5 row">
              {ProtectionDeskData.map((value) => (
                <div className="col-md-4">
                  <div className="director-card">
                    <div className="bgg-image">
                      <img src={cardTop} alt="" className="teamImg" />
                    </div>
                    <div className="picc">
                      <img src={value.personImg} alt="" className="teamImg" />
                    </div>

                    <div className="infoo protection-info">
                      <div className="pb-5">
                        <h4 className="">{value.name}</h4>
                        <small>{value.designation}</small> <br />
                        <small>Country: {value.country}</small>
                      </div>

                      <div className="card-overlay">
                        <p className="fs-6">
                          {value.bio}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>


      <div className="TopSubFooterBg">
        <div className="custom-container">
          <div className="row TopSubFooter">
            <div className="col-md-8 my-auto">
              <h3 className="my-auto text-uppercase mb-2">Become a Part of Our Global Team</h3>
            </div>
            <div className="my-auto col-md-4">
              <Link to="/membership"><button className="">Join Us Now</button></Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ProtectionDesk;
