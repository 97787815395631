//About us data
const FooterData1 = [
  {
    id: 1,
    list: "Our Story",
    url: "/ourStory",

  },
  {
    id: 2,
    list: "Mission & Vision",
    url: "/mission-and-vision",
  },
  {

    id: 3,
    list: "Development Goal",
    url: "/#development-goal",

  },
  {
    id: 4,
    list: "Our People",
    url: "/ourPeople",

  },
  {
    id: 5,
    list: "Our Donor",
    url: "/ourDonors",
  },

];
export default FooterData1;
