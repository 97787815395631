import React from "react";
import { Link } from "react-router-dom";

const Registration = () => {
  return (
    <section className="overflow-hidden top-gap">
      <div className="row">
        <div className="col-md-5 registration-cover">
          <h2>Join us</h2>
        </div>
        <div className="col-md-7 dark-color text-white reg-description px-4 ">
          <div className="w-lg-75 px-lg-4">
            <h2 className=" primary-color">
              Registration for volunteer program
            </h2>
            <h5 className="content-font">Serve the humanity</h5>
            <p className="content-font  py-3">
              To stop human rights violation and save the humanity, you can join with World Hindu Struggle Committee as a volunteer.To help the people, raise their voice and protest yourself and your community, WHSC is always standing with you.<br />
              Become a proud member of WHSC.
            </p>
            <Link to="/membership">
              <button>Registration Now</button>
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Registration;
