import React from "react";
import Pdf from "react-to-pdf";
import logo from '../images/certificate-logo.png';
import redCircle from '../images/redCircle.png';
import qrCode from '../images/qrCode.png';
import barCode from '../images/barCode.png';

const Certificate = React.forwardRef((props, ref) => {
  return (
    <div className="">
      <div ref={ref} className="download-certificate" id="myDIV">
        <div className="text-center">
          <img src={logo} alt="" className="w-25" />
          <h2>ARBITRATORS MEDIA INFORMATION</h2>
          <div className="mx-auto">
            <ul className="certificate-info">
              <li><span>Corporate Address:</span> Plot 10 Along District. Nigeria West Africa</li>
              <li><span>2nd Annex Office:</span> Plot 10 Along District. Nigeria West Africa</li>
              <li><span>Tell:</span> +88 01759696957 || +88 01712345678 || +88 01921436587</li>
              <li>
                <div className="d-flex justify-content-center">
                  <li><span>Gmail:</span> joydippaul72@gmail.com -- </li>
                  <li><span> Website:</span> www.semiclone.com</li>
                </div>
              </li>
            </ul>
          </div>
          <h1 className="certificate-title">Certificate of appreciation</h1>
          <small>To</small>
          <h4>{props.firstname} {props.lastname}</h4>
          <hr />
          <p>This certificate is refered to <i><b>{props.firstname} {props.lastname}</b></i>. He is honest person in the world.He/She is so khub valo manush.Amra tar ujjol vibissot kamona kori.
            For your Tremendous contribution to our arbitration, mediation,media,peace,human-rights,humanitarian service.
          </p>
          <strong>Date: {props.date}</strong>
        </div>

        <div className="d-flex py-2 justify-content-around">
          <div className="certificate-user">
            <img src={props.url} alt={props.title} className="user-image"
              allowDangerousPaths={true}
            />
          </div>
          <div className="certificate-user">
            <img src={redCircle} alt="" />
          </div>
          <div className="certificate-user">
            <img src={qrCode} alt="" />
          </div>
        </div>
        <div className="text-center">
          <div className="signature">
            <small><i>Anik Chowdhury</i></small>
          </div>
          <strong>Anik Chowdhury</strong><br />
          <small>Head of the department.</small><br />
          <small>CEO / Founder</small>
        </div>
        <div className="barCode">
          <img src={barCode} alt="" />
        </div>

      </div>
      <div className="text-center">
        <Pdf targetRef={ref} filename="whsc-membership-certificate.pdf">
          {({ toPdf }) => <button onClick={toPdf} className="my-2 primary-btn2 z-in">Download Certificate</button>}
        </Pdf>
      </div>
    </div>
  );
});

export default Certificate;