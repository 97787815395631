import React from 'react';
import Banner from '../../Shared/Banner/Banner';
import './About.css';
// import BannerImg from "../../images/whsc-logo.png";

const About = () => {
    return (
        <div>

            <div className="about-banner text-center">
                <Banner
                    // bannerImg={BannerImg}
                    bannerTitle="About WHSC"
                    bannerDesc="Who What Why & How ?"

                />
            </div>
            <div className="container pt-5 about-us-content p-0">
                <p className="px-md-3 ">
                    World Hindu Struggle Committee is a global platform for Human Rights and envisions promoting a free and safe world for every human to enjoy.  WHSC propagates harmony between all races, creed and color. We want to see a just, peaceful, non-discriminatory, inclusive and rights based tolerant society that would not only serve the people of all socio-economic classes, but also support the structures of society, the Administration, the Executive Branch, the Judiciary and lastly the Legislative Branch for transparency and accountability to drive our day-to-day lives more modestly and with tranquility.
                </p>
                <p className="px-md-5">
                    <strong>World Hindu Struggle Committee’s Core Beliefs:</strong><br />
                    1. WHSC is a not-for-profit, non-governmental, religious-based human rights organization. <br />
                    2. WHSC performs Civil Society’s role in some of the pressing matters of the global politics and social mismanagement and injustice today. Including:   <br />
                    a. Raise voices against minority violence.  <br />
                    b. Safeguard Hindu Communities around the world from Jihadi extremism, social and economic persecution. <br />
                    c. Raise voices and lodge concerns at the international level regarding the persecution of minority Hindus in Bangladesh and around the world.  <br />
                    d. Promote harmony between all race, creed and colour in order to resolve some of the world’s pressing humanitarian matters and critical issues.<br />
                    e. Fight against political oppression propagated through undemocratic, totalitarian and violent regimes around the world.   <br />
                    f. Protect Hindu Heritages globally. Spread out peace and harmony, untie world community by teaching ancient Vedic Culture. <br />
                    g. Protect every human being by educating them in ancient Vedic Culture. Consolidate, strengthen and make invincible the global Hindu fraternity by following the eternal and universal values based on Hindu Sanatan Dharma (Hindu Dharma) and work for the total welfare of humanity. <br />
                    h. Bring about universal love, peace and harmony in the world by reiterating and reaffirming the fundamental principles to liquidate public passions.   <br />
                    i. Create a think tank that will proactively articulate the holistic and integral perspectives on all vital issues of Hindu Dharma and Hindu Heritages globally.    <br />
                    j. Establish chair for Hindu learning in various internal institutions, corporate houses, research institutes and foundations and universities globally.    <br />
                    k. Publish books, booklets, pamphlets, journals, newspapers, etc.; to produce audios, videos and visual materials to harness the modern electronic mass media, such as the Internet and TV channels.    <br />
                    l. Provide immediate relief and help to victimized human beings.<br />
                    m. Raise funds for immediate relief and to help and educate the poor families worldwide.

                </p>
                <p className="px-md-3">
                    WHSC along with partner organizations, we advocate for the fundamental freedoms of assembly, association, and expression, and the right to be a human rights defender and activist. We support the passage, and effective implementation, of national policies and legislation, by robust and independent state institutions, based on strong international standards and mechanisms. We like to foresee a society that will spread peace, harmony, wisdom, spirituality and prosperity. To shun the overarching numerous myths, prejudices and beliefs that plague our society for centuries. Spreading the true values of Hinduism and other religions and shielding the citizens with proper intellectual wisdom for co-existence. We are focusing for uplifting the people from poverty and slums for a better future and more hygienic social living standards. Socio-economic boost for co-existence and better acceptance of the down trodden to the society. The need for a healthy nutrition of poor children and adequate facilities of basic education is also paramount to us.
                    WHSC is independent of any political ideology or economic interests. We empower human rights defenders and activists for a strong and independent civil society. WHSC establishes, supports, and connects Human Rights Organizations to establish and uphold human rights at home and around the world. WHSC is determined to raise voices for any discrimination and violence against any Hindus across the length and breadth of the globe.

                </p>

                <div className="blog-bottom py-5">
                    <h2 className="text-center">World Hindu Struggle Committee</h2>
                </div>
            </div>

        </div >
    );
};

export default About;