import React from "react";
import "./Overlay.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import {
  faFacebookF,
  faTwitter,
  faYoutube,
  faLinkedinIn,
} from "@fortawesome/free-brands-svg-icons";

const navInfo = [
  {
    id: 1,
    heading: "About",
    navList: [
      {
        id: 1,
        p1: "Our Story",
        url: "/ourStory",
      },
      {
        id: 2,
        p1: "Mission & Vision",
        url: "/mission-and-vision",
      },
      {
        id: 3,
        p1: "Development Goal",
        url: "/#development-goal",
      },
      {
        id: 4,
        p1: "Our People",
        url: "/ourPeople",
      },
      {
        id: 5,
        p1: "Our Donors",
        url: "/ourDonors",
      },
    ],
  },
  {
    id: 2,
    heading: "Our Global Team",
    navList: [
      {
        id: 1,
         p1: "Advisory Board",
         url: "/advisors",
       },
      {
        id: 2,
       p1: "Security Board",
         url: "/security-board",
       },
       {
         id: 3,
         p1: "Religious & Social Advisor",
       url: "/religious-and-social-advisor",
      },
      {
        id: 4,
        p1: "Executive Committee",
      url: "/executive-committee",
     },

    ],
  },
];
const navInfo2 = [
  {
    id: 1,
    heading: "Get Involved",
    navList: [
      {
        id: 1,
        p1: "Join Us",
        url: "/workWithUs",
      },
      {
        id: 2,
        p1: "Become A Member",
        url: "/membership",
      },
      {
        id: 3,
        p1: "Work For Us",
        url: "/workWithUs",
      },
      {
        id: 4,
        p1: "Contact Us",
        url: "/contact",
      },
      {
        id: 5,
        p1: "Donate US",
        url: "/donate",
      },
    ],
  },
  {
    id: 2,
    heading: "What We Do",
    navList: [
      {
        id: 1,
        p1: "Our Activities",
        url: "/#our-activity",
      },
      {
        id: 2,
        p1: "We Stand For",
        url: "/#our-activity",
      },
      {
        id: 3,
        p1: "Our Campaign",
        url: "/campaign",
      },

    ],
  },
];

const rowInfo = [
  {
    id: 1,
    heading: "Contact us",
    url: "/contact",
  },
  {
    id: 2,
    heading: "We Stand For",
    url: "/we-stands-for",
  },
  {
    id: 3,
    heading: "Our Activities",
    url: "/#our-activity",
  },
  {
    id: 4,
    heading: "Work With Us",
    url: "/workWithUs",
  },
  {
    id: 5,
    heading: "Become a Member",
    url: "/membership",
  },
  {
    id: 6,
    heading: "Support Us",
    url: "/donate",
  },
];

const OverlayMenu = () => {
  return (
    <div>
      <div>
        <div className="text-center heading-gap   ">
          <span className="overlay-heading">
            {" "}
            World Hindu Struggle Committee
          </span>
        </div>
        <div className="row custom-container side-menu-container py-3 mx-auto">
          <div className="col-md-3 side-menu-content">
            {navInfo.map((nav) => (
              <div className="pb-3">
                <h4 className="pb-3 position-relative">{nav.heading}</h4>
                <div className="side-nav-list heading-font ">
                  {nav.navList.map((list) => (
                    <p className="p-0 text-decoration-none">
                      <a href={list.url} className="nav-list p-0 ">
                        {list.p1}
                      </a>
                    </p>
                  ))}
                </div>
              </div>
            ))}
          </div>
          <div className="col-md-3  side-menu-content ">
            {navInfo2.map((nav) => (
              <div className="pb-3">
                <h4 className="pb-3 position-relative">{nav.heading}</h4>
                <div className="side-nav-list heading-font ">
                  {nav.navList.map((list) => (
                    <p className="p-0 text-decoration-none">
                      <a href={list.url} className="nav-list p-0 ">
                        {list.p1}
                      </a>
                    </p>
                  ))}
                </div>
              </div>
            ))}
          </div>
          <div className="col-md-3  side-menu-content">
            <div className="pb-3">
              <h4 className="pb-3 position-relative">Media & Resources</h4>
              <div className="side-nav-list heading-font ">
                <p className="p-0 text-decoration-none">
                  <a href="https://www.whsc.in/" target="_blank" rel="noopener noreferrer" className="nav-list p-0 ">
                    WHSC News
                  </a>
                </p>
                <p className="p-0 text-decoration-none">
                  <a href="/" className="nav-list p-0 ">
                    {/* Gallery */}
                  </a>
                </p>
                <p className="p-0 text-decoration-none">
                  <a
                    href="/country-data/Bangladesh/1"
                    className="nav-list p-0 "
                  >
                    Bangladesh
                  </a>
                </p>
                <p className="p-0 text-decoration-none">
                  <a href="/country-data/India/2" className="nav-list p-0 ">
                    India
                  </a>
                </p>
                <p className="p-0 text-decoration-none">
                  <a href="/country-data/Pakistan/3" className="nav-list p-0 ">
                    Pakistan
                  </a>
                </p>
                <p className="p-0 text-decoration-none">
                  <a href="/global-report" className="nav-list p-0 ">
                    Global Report
                  </a>
                </p>
              </div>
            </div>
            <div className=" pt-5 social-icons-style">
              <small className="pe-5 pb-5">
                <a href="https://www.facebook.com/WorldHinduStruggleCommittee" className="text-white" target="_blank" rel="noreferrer">
                  <FontAwesomeIcon icon={faFacebookF} size="2x" />
                </a>
              </small>
              <small className="pe-5 pb-5">
                <a href="https://twitter.com/whscorg" className="text-white" target="_blank" rel="noreferrer">
                  <FontAwesomeIcon icon={faTwitter} size="2x" />
                </a>
              </small>
              <small className="pe-5 ">
                <a href="https://www.youtube.com/channel/UCYbPQUCpjr6RQTTd1TcJhNQ" className="text-white" target="_blank" rel="noreferrer">
                  <FontAwesomeIcon icon={faYoutube} size="2x" />
                </a>
              </small>
            </div>
            <div className=" pt-3 social-icons-style">
              {/* <small className="pe-5 ">
                <a href="/" className="text-white">
                  <FontAwesomeIcon icon={faInstagram} size="2x" />
                </a>
              </small> */}
              <small className="pe-5 ">
                <a href="https://www.linkedin.com/company/world-hindu-struggle-committee/" target="_blank" rel="noreferrer" className="text-white">
                  <FontAwesomeIcon icon={faLinkedinIn} size="2x" />
                </a>
              </small>
              {/* <small className="pe-5 ">
                <a href="/" className="text-white" target="_blank">
                  <FontAwesomeIcon icon={faWhatsapp} size="2x" />
                </a>
              </small> */}
            </div>
          </div>
          <div className="col-md-3 ">
            {rowInfo.map((info) => (
              <a
                href={info.url}
                className=" mb-2 box-info  d-flex justify-content-between text-decoration-none"
              >
                <h4 className="my-auto">{info.heading}</h4>

                <FontAwesomeIcon
                  icon={faArrowRight}
                  className="my-auto primary-color "
                />
              </a>
            ))}
          </div>
        </div>
      </div>
      <div className="mx-5  text-center sidemenu-footer">
        <hr className="hr-color" />
        <small className="primary-color text-center">
          <span className="text-white">&copy; {new Date().getFullYear()}</span> World Hindu Struggle
          Committee
        </small>
      </div>
    </div>
  );
};

export default OverlayMenu;
