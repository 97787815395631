import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebookF,
  faTwitter,
  faYoutube,
  faLinkedinIn,
} from "@fortawesome/free-brands-svg-icons";
import "./Footer.css";
import FooterData1 from "./FooterData/FooterData1";
import FooterData2 from "./FooterData/FooterData2";
import FooterData3 from "./FooterData/FooterData3";
import FooterData4 from "./FooterData/FooterData4";

const Footer = () => {
  return (
    <section className="overflow-hidden">
      <div className="row dark-color text-white px-4 ">
        <div className="col-md-4 footer-left-box py-2">
          <div className="footer-left ms-auto ps-lg-3 pt-4 ">
            <h3>
              <span className="primary-color">World Hindu Struggle Committee</span>
            </h3>
            <p className="pt-lg-2 pt-md-2 footer-content">
              The World Hindu Struggle Committee is a dedicated international team fighting to raise its voice against fundamentalism, terrorism, and inhuman groups. WHSC is a globally organized community, and we are working to build the world peaceful again. The WHSC also provides a forum for its members to express their views in the General Assembly, the Security Council, the Economic and Social Council, and other bodies and committees. By enabling dialogue between its members and hosting negotiations, the Organization has become a mechanism for governments to find areas of agreement and solve problems together.
            </p>

            <div className="social-icons pt-3 pb-4">
              <p className="primary-color fs-5 pb-2">Follow us</p>
              <small>
                <a href="https://www.facebook.com/WorldHinduStruggleCommittee" className="text-white fb-padding" target="_blank" rel="noreferrer">
                  <FontAwesomeIcon icon={faFacebookF} />
                </a>
              </small>
              <small className="icons-style">
                <a href="https://twitter.com/whscorg" className="text-white" target="_blank" rel="noreferrer">
                  <FontAwesomeIcon icon={faTwitter} />
                </a>
              </small>
              <small className="icons-style">
                <a href="https://www.linkedin.com/company/world-hindu-struggle-committee/" className="text-white" target="_blank" rel="noreferrer">
                  <FontAwesomeIcon icon={faLinkedinIn} />
                </a>
              </small>
              <small className="icons-style">
                <a href="https://www.youtube.com/channel/UCYbPQUCpjr6RQTTd1TcJhNQ" className="text-white" target="_blank" rel="noreferrer">
                  <FontAwesomeIcon icon={faYoutube} />
                </a>
              </small>
            </div>
          </div>
        </div>
        <div className="col-md-8 py-4">
          <div className="row footer-description pt-3">
            <div className="col-md-3 col-sm-6 ps-3 description-card">
              <h5 className="primary-color pb-2 ">ABOUT US</h5>
              {FooterData1.map((info) => (
                <ul className="ps-0 description-list">
                  <li className="pb-2">
                    <a href={info.url}>{info.list}</a>
                  </li>
                </ul>
              ))}
            </div>
            <div className="col-md-3 col-sm-6 description-card px-3 ">
              <h5 className="primary-color pb-2">WHAT WE DO </h5>
              {FooterData2.map((info) => (
                <ul className="ps-0 description-list">
                  <li className="pb-2 ">
                    <a href={info.url}>{info.list}</a>
                  </li>
                </ul>
              ))}
            </div>
            <div className="col-md-3 col-sm-6 description-card px-3">
              <h5 className="primary-color pb-2">GET INVOLVED</h5>
              {FooterData3.map((info) => (
                <ul className="ps-0 description-list">
                  <li className="pb-2 ">
                    <a href={info.url}>{info.list}</a>
                  </li>
                </ul>
              ))}
            </div>
            <div className="col-md-3 col-sm-6 description-card px-3  ">
              <h5 className="primary-color pb-2">RESOURCES</h5>
              {FooterData4.map((info) => (
                <ul className="ps-0 description-list">
                  <li className="pb-2 ">
                    <a href={info.url}>{info.list}</a>
                  </li>
                </ul>
              ))}
            </div>
          </div>

          <div className="pt-3 ps-1 w-100">
            <p className="primary-color">
              Indian Govt. Registration No: 150 200 055  | Belgium Govt. Registration No: 799 228 134
            </p>
            <div className="text-justify reg-desc pe-lg-5">
              
            </div>
          
          </div>
        </div>
      </div>

      <div className="text-center sub-footer p-2 pt-4">
        <p className="p-0 lh-1">
          {" "}
          <a href="/privacy-policy">Privacy Policy</a> |{" "}
          <a href="/terms-and-conditions">Terms & Conditions</a> |{" "}
          <a href="/faq">FAQ</a>
        </p>
        <small className="lh-1 text-muted">
          &copy; {new Date().getFullYear()} WHSC | Developed with &#x2764; by: <span>WHSC™</span>
          || AppVersion: 1.1.0
        </small>
      </div>
    </section>
  );
};

export default Footer;
