import React, { useState } from "react";
import "./../Card.css";
import boardMember1 from "../../../images/global_team.jpg";
import bannerImg2 from "../../../images/boardDirector.jpg";
import Banner2 from "../../../Shared/Banner2/Banner2";
import Helmet from "react-helmet";
import ContactAction from "../../../Shared/ContactAction/ContactAction";

const BoardMember = [
  {
    name: "katrina kaif",
    designation: "Chairperson",
    personImg: boardMember1,
    country: "singapore",
  },
  {
    name: "Bill Gates",
    designation: "Chairperson",
    personImg: boardMember1,
    country: "singapore",
  },
  {
    name: "Kim jom Ungh",
    designation: "Chairperson",
    personImg: boardMember1,
    country: "singapore",
  },
  {
    name: "Shihab Khan",
    designation: "Chairperson",
    personImg: boardMember1,
    country: "singapore",
  },
  {
    name: "Rony Sarkar",
    designation: "Chairperson",
    personImg: boardMember1,
    country: "singapore",
  },
  {
    name: "Himel Mahmud",
    designation: "Chairperson",
    personImg: boardMember1,
    country: "singapore",
  },
  {
    name: "Moushumi Mitu",
    designation: "Chairperson",
    personImg: boardMember1,
    country: "singapore",
  },
  {
    name: "Joydip Paul",
    designation: "Chairperson",
    personImg: boardMember1,
    country: "singapore",
  },
  {
    name: "Joydip Paul",
    designation: "Chairperson",
    personImg: boardMember1,
    country: "singapore",
  },
];

const CountryCommittee = () => {
  const [loadMore, setLoadMore] = useState(6);
  const LoadMoreHandle = () => {
    setLoadMore((prevValue) => prevValue + 6);
  };
  return (
    <section>

      <Helmet>

        <title>Country Committee | World Human Struggle Committee</title>
        <meta
          name="title"
          content="Country Committee | World Human Struggle Committee"
        />
        <meta
          name="keywords"
          content="people, team, Board of Directors, Management Team, Global Team Staff, Country Committee, Executive Committee, Senior Advisors, Protection Desks, Administration Unit"
        />
        <meta
          name="description"
          content="Children need your support, urgently. Kindly donate with an open arm and let us help the most vulnerable."
        />
        <meta name="robots" content="index,follow" />
        <meta name="url" content="http://www.whsc.com/admission-unit" />


        <meta
          itemprop="name"
          content="Country Committee | World Human Struggle Committee"
        />
        <meta
          itemprop="description"
          content="Children need your support, urgently. Kindly donate with an open arm and let us help the most vulnerable."
        />
      </Helmet>

      <Banner2
        bannerImg={bannerImg2}
        bannerTitle="Country Committee"
        link="Our People"
        link2="Country Committee"
        url1="/ourPeople"
        className="position-relative"
      />

      <div className="container">
        <div className="py-5">
          <div className="text-center ">
            <div className="heading-style4">
              <h2 className="text-black"> Country Committee</h2>
            </div>
            <p>
              PI is governed by a Board of Directors which is responsible for
              the overall strategy and governance of the organisation.
            </p>
            <h5>Our current Board members are:</h5>
            <div className="py-5 row">
              {BoardMember.slice(0, loadMore).map((value) => (
                <div className="col-md-4">
                  <div className="director-card">
                    <div className="">
                      <img src={value.personImg} alt="" className="teamImg" />
                    </div>

                    <div className="infoo country-committee-info">
                      <div className="pb-5">
                        <h4 className="text-white">{value.name}</h4>
                        <small>{value.designation}</small> <br />
                        <small>Country: {value.country}</small>
                      </div>

                      <div className="card-overlay">
                        <p className="pt-2">
                          PI is governed by a Board PI is governed by a Board of
                          Directors in India
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
            <button onClick={LoadMoreHandle} className="primary-btn2">
              Load More
            </button>
          </div>
        </div>
      </div>
      <ContactAction title={"Join our global team and support us"} link="contact us" url="/contact" />
    </section>
  );
};

export default CountryCommittee;
