import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHandshake } from "@fortawesome/free-solid-svg-icons";
import "./Membership.css";
import Banner2 from "../../Shared/Banner2/Banner2";
import becomeAMember from "../../images/becomeAMember.jpg";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { Link } from "react-router-dom";

const Membership = () => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);

  const [modal, setModal] = useState(false);

  const [member, setMember] = useState({
    firstName: "",
    lastName: "",
    email: "",
    country: "",
    address: "",
    cityName: "",
    zipCode: "",
    number: "",
    attachment: "",
  });

  const {
    firstName,
    lastName,
    email,
    country,
    address,
    cityName,
    zipCode,
    number,
    file,
  } = member;

  const handleChange = (e) => {
    setMember({ ...member, [e.target.name]: e.target.value });
  };

  // image upload to google drive
  function guardarArchivo(e) {

    var file = e.target.files[0] //the file
    var reader = new FileReader() //this for convert to Base64 
    reader.readAsDataURL(e.target.files[0]) //start conversion...
    reader.onload = function (e) { //.. once finished..
      var rawLog = reader.result.split(',')[1]; //extract only thee file data part
      var dataSend = { dataReq: { data: rawLog, name: file.name, type: file.type }, fname: "uploadFilesToGoogleDrive" }; //preapre info to send to API
      fetch('https://script.google.com/macros/s/AKfycbytOiGsuWZacTSr0TfdsnNRLt9i4e2TqcMVgNmkf6ysVqjsr14pwVz_ClIFiayyJUrdpg/exec', //your AppsScript URL
        { method: "POST", body: JSON.stringify(dataSend) }) //send to Api
        .then(res => res.json()).then((a) => {
          console.log(a) //See response
        }).catch(e => console.log(e)) // Or Error in console
    }
    handleFile();
  }

  // Image Validation

  const handleFile = (e) => {
    console.log("e", e.target.files);
    setMember({ ...member, [e.target.name]: e.target.value });
    const file = e.target.files[0];
    if (file.size > 2097152 || 'Max 10MB') {
      alert("File size is too big!");
    } else if (!file.type.includes(".png") || !file.type.includes(".jpg")) {
      alert("File type is not supported!");
      e.target.files = [0];
    } else {
      console.log("file valid");
    }
  };

  const handleSubmit = async (e) => {
    setModal(true);
    setShow(true);
    e.preventDefault();

    try {
      const res = await fetch(
        "https://v1.nocodeapi.com/sundarbanlab/google_sheets/nntvuDMLJlUpfxnH?tabId=Sheet1",
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify([
            [
              firstName,
              lastName,
              email,
              country,
              address,
              cityName,
              zipCode,
              number,
              file,
              new Date().toLocaleString(),
            ],
          ]),
        }
      );
      await res.json();
      setMember({
        ...member,
        firstName: "",
        lastName: "",
        email: "",
        country: "",
        address: "",
        cityName: "",
        zipCode: "",
        number: "",
        file: "",
        date: "",
      });
    } catch (err) {
      console.error(err);
      alert("Try again");
    }
  };

  return (
    <section>
      <Banner2
        bannerImg={becomeAMember}
        bannerTitle="BECOME A PROUD MEMBER OF WHSC"
        link="ABOUT"
        link2="MEMBERSHIP"
      />
      <div className="justify-content-center container d-flex shadow-lg p-3  bg-white rounded membership-form">
        <div className="col-md-8">
          <div>
            <h1 className="text-center primary-color mt-2">
              BECOME A PART OF WHSC
            </h1>
            <form
              className="mt-4"
              accept-charset="UTF-8"
              method="POST"
              enctype="multipart/form-data"
              target="_blank"
              onSubmit={handleSubmit}
            >
              <div className="info">
                <div className="col-md-6 primary-color">
                  <label for="exampleInputName">First Name</label>
                  <input
                    type="text"
                    name="firstName"
                    value={firstName}
                    onChange={handleChange}
                    className="form-control primary-color"
                    placeholder="Enter your first name "
                    required="required"
                  />
                </div>
                <div className="col-md-5 primary-color">
                  <label for="exampleInputName">Last Name</label>
                  <input
                    type="text"
                    name="lastName"
                    value={lastName}
                    onChange={handleChange}
                    className="form-control"
                    placeholder="Enter your last name"
                    required="required"
                  />
                </div>
              </div>
              <br />
              <div className="primary-color">
                <label for="exampleInputEmail1" required="required">
                  Email address
                </label>
                <input
                  type="email"
                  name="email"
                  value={email}
                  onChange={handleChange}
                  className="form-control"
                  aria-describedby="emailHelp"
                  placeholder="Enter your email address"
                  required
                />
              </div>
              <br />
              <div className="info primary-color">
                <div className="col-md-6">
                  <label for="exampleInputName">Country Name</label>
                  <input
                    type="text"
                    name="country"
                    value={country}
                    onChange={handleChange}
                    className="form-control"
                    placeholder="Bangladesh"
                    required
                  />
                </div>
                <div className="col-md-5 primary-color">
                  <label for="inputAddress">Address</label>
                  <input
                    type="text"
                    name="address"
                    value={address}
                    onChange={handleChange}
                    className="form-control"
                    placeholder="Your Address"
                    required
                  />
                </div>
              </div>
              <br />
              <div className="info">
                <div className="col-md-6 primary-color">
                  <label for="inputCity">City Name</label>
                  <input
                    type="text"
                    name="cityName"
                    value={cityName}
                    onChange={handleChange}
                    className="form-control"
                    placeholder="City Name"
                    required
                  />
                </div>
                <div className="col-md-5 primary-color">
                  <label for="inputZip">Zip Code</label>
                  <input
                    type="number"
                    name="zipCode"
                    value={zipCode}
                    onChange={handleChange}
                    className="form-control"
                    placeholder="1000"
                    required
                  />
                </div>
              </div>
              <br />
              <div className="form-row primary-color">
                <div className="col-md-12">
                  <label for="example-tel-input">Contact Number</label>
                  <input
                    type="number"
                    name="number"
                    value={number}
                    onChange={handleChange}
                    className="form-control"
                    placeholder="+880 1111111111"
                    required
                  />
                </div>
              </div>
              <div className="info pt-3">

              </div>

              <div className="info pt-3">
                <div className="col-md-6 primary-color">
                  <label for="inputPhoto">Upload Your Photo</label>
                  <input type="file" accept="image/*" id="customFile" onChange={(e) => guardarArchivo(e)}
                    required="required"
                    className="form-control" />

                </div>
                <div className="col-md-5 primary-color">
                  <label for="inputNID">Your NID/Passport </label>
                  <input
                    type="file"
                    accept="image/*"
                    id="customFile"
                    onChange={(e) => guardarArchivo(e)}
                    required="required"
                    className="form-control"
                  />

                </div>
              </div>
              <div className="my-5 mx-auto text-center">
                <button className="fw-bold button w-100" type="submit">
                  <FontAwesomeIcon icon={faHandshake} /> Registration Now
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>

      {modal && (
        <Modal
          show={show}
          onHide={handleClose}
          backdrop="static"
          keyboard={false}
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <h5>THANK YOU FOR JOINING US.</h5>
          </Modal.Header>
          <Modal.Body>
            <h4 className="text-center primary-color">Congratulations!</h4>
            <br />
            <p className="text-center">
              We receive your application. We will reply you soon.
            </p>
            <div className="text-center text-dark">
              <Link to="/donate">
                <Button variant="outline-warning">Support Us</Button>
              </Link>
            </div>
            <div className="text-center text-dark pt-2">
              <Link to="/">
                <Button variant="outline-dark">Go back to home page</Button>
              </Link>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div className="text-center">
              <p>
                By submitting your application you agree with our{" "}
                <Link to="/privacy-policy">Privacy Policy</Link> &{" "}
                <Link to="/terms-and-conditions">Terms and Conditions.</Link>
              </p>
            </div>
          </Modal.Footer>
        </Modal>
      )}
    </section>
  );
};

export default Membership;
