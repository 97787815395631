import React, { useEffect, useState } from "react";
import "./WorkWithUs.css";
import { Modal } from "react-bootstrap";
// import { default as guardarArchivo } from "../Membership/Membership.js";
import image1 from "../../images/membership.png";
import image2 from "../../images/Become-a-CCSPCA-Volunteer.jpg";
import image3 from "../../images/researcher.jpg";
import image4 from "../../images/online-activist.png";
import image5 from "../../images/news-reporter.png";
import image6 from "../../images/Country-Representative.png";
import image7 from "../../images/think-tank.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import Swal from "sweetalert2";



const events = [
  {
    number: 1,
    img: image1,
    topic: "Member",
    description:
      "Work selflessly with us to build our society and spread peace and harmony across the globe.",
  },
  {
    number: 2,
    img: image2,
    topic: "Volunteer",
    description:
      "Work selflessly with us to build our society and spread peace and harmony across the globe.",
  },

  {
    number: 4,
    img: image4,
    topic: "Online Activist",
    description:
      "Enrich us with your thoughts on our column, even if you are not physically present.",
  },

  {
    number: 5,
    img: image5,
    topic: "News Reporter",
    description:
      "Become a reporter by updating us about current news in and around your surroundings in our media channel.",
  },
  {
    number: 6,
    img: image6,
    topic: "Country Representative",
    description:
      "Become our country representative to represent our country in the media and to spread peace and harmony across the globe.",
  },
  {
    number: 3,
    img: image3,
    topic: "Researcher",
    description:
      "We invite intellectual minds to join our folds and collect, organize, analysis and interpret data for betterment of humanity.",
  },
  {
    number: 7,
    img: image7,
    topic: "Think Tank",
    description:
      "Join us and guide us in your area of excellence. Share your ideas to shape our organization to perform better in our social policies, planning and global outreach.",
  },
];

const Event = () => {
  // const [name, setName] = useState("");

  const [event, setEvent] = useState([]);

  useEffect(() => {
    setEvent(events);
  }, []);

  const [result, setResult] = useState(false);

  const eventBook = event.find((e) => e.number === result);

  const handelClick = (e) => {
    setResult(e);
  };

  console.log("EventBook click", eventBook ? eventBook.topic : events[0].topic);

  const [show, setShow] = useState();

  let evenData = eventBook ? eventBook.topic : events[0].topic;
  console.log("click data", evenData);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  // Modal Registration From

  const [data, setData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    number: "",
    address: "",
    country: "",
    zipCode: "",
    companyName: "",
    comment: "",
    date: new Date().toLocaleDateString(),
  });

  const {
    firstName,
    lastName,
    email,
    number,
    address,
    country,
    zipCode,
    companyName,

    comment,
  } = data;

  const handelChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };
  const handelSubmit = async (e) => {
    e.preventDefault();
    setShow(false);

    const inputVal = document.getElementById("myInput").value;
    const position = inputVal;
    console.log("try", inputVal);

    try {
      const res = await fetch(
        "https://v1.nocodeapi.com/sundarbanlab/google_sheets/fYtNtAhVVRYFPLyT?tabId=Sheet1",
        {
          method: "POST",
          headers: {
            "content-type": "application/json",
          },
          body: JSON.stringify([
            [
              firstName,
              lastName,
              email,
              number,
              address,
              country,
              zipCode,
              companyName,
              comment,
              position,
              new Date().toLocaleString(),
            ],
          ]),
        }
      );
      await res.json();
      setData({
        ...data,
        firstName: firstName,
        lastName: "",
        email: "",
        number: "",
        address: "",
        country: "",
        zipCode: "",
        companyName: "",
        comment: "",
        date: "",
      });
    } catch (err) {
      console.log(err);
    }
    Swal.fire({
      icon: "success",
      title: `Thank You, ${firstName}`,
      text: "Your application has been successfully submitted",
    });
  };

  return (
    <div>
      <div className="" id="apply">
        <div className="d-flex flex-wrap pt-5">
          <div className="col-md-8 lh-lg shadow p-2">
            <h2>{eventBook ? eventBook.topic : events[0].topic}</h2>

            <img
              className="event-img-fluid my-4 "
              src={eventBook ? eventBook.img : events[0].img}
              alt=""
            />
            <p>{eventBook ? eventBook.description : events[0].description}</p>
            <div className="pb-2">
              <button onClick={handleShow} className="primary-btn2">
                Apply Now
              </button>
            </div>
          </div>
          <div className="col-md-4 col-12 primary-bg ">
            <div className="apply-for-desc ">
              <h3 className=" pb-3">WORK FOR US AS A</h3>
              <div className="">
                {events.map((event) => (
                  <div className=" apply-btn">
                    <div
                      onClick={() => handelClick(event.number)}
                      className="apply-btn-desc"
                    >
                      {" "}
                      <FontAwesomeIcon
                        icon={faArrowLeft}
                        className="me-2"
                      />{" "}
                      {event.topic}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* modal */}
      <div>
        {data.natureEvent === "" ? (
          ""
        ) : (
          <Modal show={show} onHide={handleClose} dialogClassName="my-modal">
            <Modal.Body>
              <div className="px-5">
                <h3 className="py-3 text-center">FILL UP YOUR INFO</h3>
                <form
                  className="mt-4"
                  accept-charset="UTF-8"
                  method="POST"
                  enctype="multipart/form-data"
                  target="_blank"
                  onSubmit={handelSubmit}
                >
                  <div className="info">
                    <div className="col-md-6">
                      <label for="exampleInputName">FIRST NAME</label>
                      <input
                        type="text"
                        name="firstName"
                        className="form-control"
                        id="exampleInputName"
                        placeholder="Enter your first name "
                        value={firstName}
                        onChange={handelChange}
                        required="required"
                      />
                    </div>
                    <div className="col-md-5">
                      <label for="exampleInputName">LAST NAME</label>
                      <input
                        type="text"
                        name="lastName"
                        className="form-control"
                        id="exampleInputName"
                        placeholder="Enter your last name"
                        value={lastName}
                        onChange={handelChange}
                        required="required"
                      />
                    </div>
                  </div>
                  <br />
                  <div className="">
                    <label for="address">ADDRESS</label>
                    <input
                      type="text"
                      name="address"
                      className="form-control"
                      id="address"
                      aria-describedby="emailHelp"
                      placeholder="Enter your address"
                      value={address}
                      onChange={handelChange}
                      required="required"
                    />
                  </div>
                  <br />
                  <div className="info">
                    <div className="col-md-6">
                      <label for="exampleInputName">COUNTRY </label>
                      <input
                        type="text"
                        name="country"
                        className="form-control"
                        id="exampleInputName"
                        placeholder="Your country name "
                        value={country}
                        onChange={handelChange}
                        required="required"
                      />
                    </div>

                    <div className="col-md-5">
                      <label for="inputAddress">zipCode</label>
                      <input
                        type="text"
                        name="zipCode"
                        className="form-control"
                        id="inputAddress"
                        placeholder="zipCode"
                        value={zipCode}
                        onChange={handelChange}
                      />
                    </div>
                  </div>

                  <br />
                  <div className="info">
                    <div className=" col-md-6">
                      <label for="exampleInputName">PHONE NUMBER</label>
                      <input
                        type="number"
                        name="number"
                        className="form-control"
                        id="exampleInputName"
                        placeholder="Your phone number"
                        value={number}
                        onChange={handelChange}
                        required="required"
                      />
                    </div>
                    <div className=" col-md-5">
                      <label for="exampleInputEmail1">EMAIL ADDRESS</label>
                      <input
                        type="email"
                        name="email"
                        className="form-control"
                        id="exampleInputEmail1"
                        aria-describedby="emailHelp"
                        placeholder="Enter your email address"
                        value={email}
                        onChange={handelChange}
                        required="required"
                      />
                    </div>
                  </div>
                  <br />
                  <div className="form-row">
                    <div className=" col-md-12">
                      <label for="example-tel-input">
                        ORGANIZATION / COMPANY NAME
                      </label>
                      <input
                        type="text"
                        name="companyName"
                        tel="tel"
                        className="form-control"
                        id="inputCity"
                        placeholder="Enter your company name"
                        value={companyName}
                        onChange={handelChange}
                        required="required"
                      />
                    </div>
                  </div>

                  <div className="pt-3">
                    <label for="myInput">Apply Position for</label>
                    <input
                      type="text"
                      name="applyPosition"
                      className="form-control"
                      id="myInput"
                      aria-describedby="emailHelp"
                      value={eventBook ? eventBook.topic : events[0].topic}
                      // placeholder={
                      //   eventBook ? eventBook.topic : events[0].topic
                      // }
                      onChange={handelChange}
                      required="required"
                    />
                  </div>
                  <br />

                  <div className="">
                    <label for="exampleInputEmail1">
                      Why you want to apply for ?
                    </label>
                    <textarea
                      rows="2"
                      cols="25"
                      type="textarea"
                      name="comment"
                      className="form-control"
                      id="exampleInputEmail1"
                      aria-describedby="emailHelp"
                      placeholder="Why you want to join with WHSC ?"
                      value={comment}
                      onChange={handelChange}
                      required="required"
                    />
                  </div>
                  <br />

                  {/* <div className="col-md-6 primary-color">
                    <label for="inputPhoto">Upload Your Photo</label>
                    <input type="file" accept="image/*" id="customFile" onChange={(e) => guardarArchivo(e)}
                      required="required"
                      className="form-control" />

                  </div>
                  <div className="col-md-5 primary-color">
                    <label for="inputNID">Your NID/Passport </label>
                    <input
                      type="file"
                      accept="image/*"
                      id="customFile"
                      onChange={(e) => guardarArchivo(e)}
                      required="required"
                      className="form-control"
                    />

                  </div> */}

                  <br />
                  <div className="text-center">
                    <small>
                      By submitting your application, you agree with our
                      <a
                        href="/privacy-policy"
                        className="text-decoration-none link-color fs-bold ps-1"
                      >
                        privacy policy
                      </a>{" "}
                      and{" "}
                      <a
                        href="/terms-and-conditions"
                        className="text-decoration-none link-color fs-bold"
                      >
                        terms & condition
                      </a>
                      .
                    </small>
                  </div>
                  <br />
                  <div className="pb-3">
                    <button className="w-100 button" type="submit">
                      Register Now
                    </button>
                  </div>
                </form>
              </div>
            </Modal.Body>
          </Modal>
        )}
      </div>
    </div>
  );
};

export default Event;
