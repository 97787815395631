import React, { useEffect, useState } from "react";
import Gallery from "react-grid-gallery";
import GalleryCarousel from "./GalleryCarousel";
import {
  FacebookIcon,
  TwitterIcon,
  WhatsappIcon,
  LinkedinIcon,
} from "react-share";
import axios from "axios";
import Helmet from "react-helmet";
import ContactAction from "../Shared/ContactAction/ContactAction";

const GalleryImages = () => {
  const [images, setImages] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();
  const [isError, setIsError] = useState(false);

  const [imageUrl, setImageUrl] = useState("");

  const [wpImage, setWpImage] = useState([]);

  const fetchWpData = async () => {
    try {
      const { data } = await axios.get(
        `https://whsc.in/wp-json/wp/v2/posts?categories=704`
      );

      setWpImage(data);
    } catch (error) {
      console.log(error);
    }
  };

  // useEffect(() => {
  //   const NewApi = async () => {
  //     fetchWpData();
  //   }
  //   NewApi();
  // },[])

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data } = await axios.get(
          `https://api.unsplash.com/photos/random?client_id=mEWR_kuTS41ydcsg_ivYaRGrjjuvlZ9jAfWQ9MUNwYE&&count=50`
        );
        
        setImages(data);
        setLoading(false);
      } catch (error) {
        setError(error.message);

        setIsError(true);

        setLoading(false);
      }
    };
    fetchData();
    fetchWpData();
  }, []);

  // fetchWpData();

  console.log(wpImage);

  const photos = wpImage.map((img, index) => {
    if (index % 3 === 0) {
      return {
        src: img.jetpack_featured_media_url,
        thumbnail: img.jetpack_featured_media_url,
        thumbnailWidth: 271,
        thumbnailHeight: 320,
        caption: img.description,
      };
    } else if (index % 2 === 0) {
      return {
        src: img.jetpack_featured_media_url,
        thumbnail: img.jetpack_featured_media_url,
        thumbnailWidth: 320,
        thumbnailHeight: 183,
        caption: img.description,
      };
    }

    return {
      src: img.jetpack_featured_media_url,
      thumbnail: img.jetpack_featured_media_url,
      thumbnailWidth: 320,
      thumbnailHeight: 174,
      caption: img.description,
    };
  });

  console.log(photos);
  const shuffleArray = (arr) => arr.sort(() => 0.5 - Math.random());

  const photo = images.map((img, index) => {
    if (index % 3 === 0) {
      return {
        src: img.urls.regular,
        thumbnail: img.urls.regular,
        thumbnailWidth: 271,
        thumbnailHeight: 320,
        caption: img.alt_description ? img.alt_description : img.description,
      };
    } else if (index % 2 === 0) {
      return {
        src: img.urls.regular,
        thumbnail: img.urls.regular,
        thumbnailWidth: 320,
        thumbnailHeight: 183,
        caption: img.alt_description ? img.alt_description : img.description,
      };
    }

    return {
      src: img.urls.regular,
      thumbnail: img.urls.regular,
      thumbnailWidth: 320,
      thumbnailHeight: 174,
      caption: img.alt_description ? img.alt_description : img.description,
    };
  });

  let ImageData = photo.map((image) => ({
    image: image.src,
    caption: image.caption,
  }));

  ImageData = [...ImageData];

  const shuffledPhotosGallery = shuffleArray(photos);
  const shuffledPhotosEvent = shuffleArray(photo);
  console.log(shuffledPhotosGallery);

  console.log(shuffleArray(ImageData));

  const shareBtn = () => {
    const lightBox = document.getElementById("lightboxBackdrop");
    const imageElement = lightBox.getElementsByTagName("img");
    setImageUrl(imageElement[0].currentSrc);
    console.log(imageUrl);
  };
  // console.log(shuffledPhotosGallery[0].src);

  return (
    <section className="p-0">
      <Helmet>
        {/* <!-- HTML Meta Tags --> */}
        <title>Gallery | World Human Struggle Committee</title>
        <meta name="title" content="Gallery | World Human Struggle Committee" />
        <meta name="keywords" content="gallery, picture, album" />
        <meta
          name="description"
          content="This our gallery to show people about the struggled human in the world"
        />
        <meta name="robots" content="index,follow" />
        <meta name="url" content="https://www.whsc.com/gallery" />

        {/* <!-- Google / Search Engine Tags --> */}
        <meta
          itemprop="name"
          content="Gallery | World Human Struggle Committee"
        />
        <meta
          itemprop="description"
          content="This our gallery to show people about the struggled human in the world"
        />
      </Helmet>
      <div className="overflow-hidden">
        {isError && (
          <div className="text-center spinner-padding">
            <h2 className="alert alert-danger" role="alert">
              {error}{" "}
            </h2>
          </div>
        )}
        {loading && !isError ? (
          <div class="text-center spinner-padding">
            <div className="spinner-border" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        ) : (
          <GalleryCarousel content={ImageData} />
        )}

        <div className="pt-3">
          <div className="border-section  text-center">
            <h1>News Images</h1>
          </div>
        </div>
        <div className="container-fluid pt-3 overflow-hidden " id="more-images">
          {isError && (
            <div className="text-center spinner-padding">
              <h2 className="alert alert-danger" role="alert">
                {error}{" "}
              </h2>
            </div>
          )}
          {loading && !isError ? (
            <div class="text-center spinner-padding">
              <div className="spinner-border" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            </div>
          ) : (
            <Gallery
              images={shuffledPhotosGallery}
              enableImageSelection={false}
              enableLightbox={true}
              customControls={[
                <div className="share-section">
                  <div
                    className="fb-share-button sh-button"
                    data-href="https://developers.facebook.com/docs/plugins/"
                    data-layout="button_count"
                    data-size="small"
                    onClick={shareBtn}
                  >
                    <a
                      target="_blank"
                      href={`https://www.facebook.com/sharer/sharer.php?u=${imageUrl}`}
                      class="fb-xfbml-parse-ignore"
                    >
                      <FacebookIcon
                        size={30}
                        logoFillColor="white"
                        borderRadius={8}
                      />
                    </a>
                  </div>
                  <div className="sh-button" onClick={shareBtn}>
                    <a
                      target="_blank"
                      href={`https://linkedin.com/shareArticle/?url=${imageUrl}`}
                    >
                      <LinkedinIcon
                        size={30}
                        logoFillColor="white"
                        borderRadius={8}
                      />
                    </a>
                  </div>
                  <div className="sh-button" onClick={shareBtn}>
                    <a
                      target="_blank"
                      href={`https://twitter.com/share?url=${imageUrl}`}
                    >
                      <TwitterIcon
                        size={30}
                        logoFillColor="white"
                        borderRadius={8}
                      />
                    </a>
                  </div>
                  <div className="sh-button" onClick={shareBtn}>
                    <a
                      target="_blank"
                      href={`https://web.whatsapp.com/send?text=${imageUrl}`}
                    >
                      <WhatsappIcon
                        size={30}
                        logoFillColor="white"
                        borderRadius={8}
                      />
                    </a>
                  </div>
                </div>,
              ]}
            />
          )}
        </div>

        <div className="pt-3">
          <div className="border-section  text-center">
            <h1>Event Images</h1>
          </div>
        </div>
        <div className="container-fluid py-4 overflow-hidden">
          {isError && (
            <div className="text-center spinner-padding">
              <h2 className="alert alert-danger" role="alert">
                {error}{" "}
              </h2>
            </div>
          )}
          {loading && !isError ? (
            <div class="text-center spinner-padding">
              <div className="spinner-border" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            </div>
          ) : (
            <Gallery
              images={shuffledPhotosEvent}
              enableImageSelection={false}
              enableLightbox={true}
              customControls={[
                <div className="share-section">
                  <div
                    className="fb-share-button sh-button"
                    data-href="https://developers.facebook.com/docs/plugins/"
                    data-layout="button_count"
                    data-size="small"
                    onClick={shareBtn}
                  >
                    <a
                      target="_blank"
                      href={`https://www.facebook.com/sharer/sharer.php?u=${imageUrl}`}
                      class="fb-xfbml-parse-ignore"
                    >
                      <FacebookIcon
                        size={30}
                        logoFillColor="white"
                        borderRadius={8}
                      />
                    </a>
                  </div>
                  <div className="sh-button" onClick={shareBtn}>
                    <a
                      target="_blank"
                      href={`https://linkedin.com/shareArticle/?url=${imageUrl}`}
                    >
                      <LinkedinIcon
                        size={30}
                        logoFillColor="white"
                        borderRadius={8}
                      />
                    </a>
                  </div>
                  <div className="sh-button" onClick={shareBtn}>
                    <a
                      target="_blank"
                      href={`https://twitter.com/share?url=${imageUrl}`}
                    >
                      <TwitterIcon
                        size={30}
                        logoFillColor="white"
                        borderRadius={8}
                      />
                    </a>
                  </div>
                  <div className="sh-button" onClick={shareBtn}>
                    <a
                      target="_blank"
                      href={`https://web.whatsapp.com/send?text=${imageUrl}`}
                    >
                      <WhatsappIcon
                        size={30}
                        logoFillColor="white"
                        borderRadius={8}
                      />
                    </a>
                  </div>
                </div>,
              ]}
            />
          )}
        </div>
      </div>
      <ContactAction
        title={"Become a Part of Our News"}
        link="Join Us Now"
        url="/membership"
      />
    </section>
  );
};

export default GalleryImages;
