import React, { useEffect, useState } from 'react';
import CampaignSection from './CampaignSection';
import GridLoader from "react-spinners/GridLoader";


const CampaignMain = () => {
    const [loading, setLoading] = useState(false);
    useEffect(() => {
        setLoading(true);
        setTimeout(() => {
            setLoading(false);
        }, 5000)
    }, [])
    return (
        <div>
            {
                loading ?
                    <div className="loaderBg">
                        <GridLoader
                            color={'#ffb422'}
                            loading={loading}
                            size={20}
                        />
                    </div>
                    :
                    <CampaignSection />
            }
        </div>
    );
};

export default CampaignMain;