//Get involved data
const FooterData3 = [
  {
    id: 1,
    list: "Become A Member",
    url: "/membership",
  },
  {
    id: 2,
    list: "Work For Us",
    url: "/workWithUs",
  },
  {
    id: 3,
    list: "Contact Us",
    url: "/contact",
  },
  {
    id: 4,
    list: "Donate US",
    url: "/donate",
  },
  {
    id: 5,
    list: "Affiliated Organization",
    url: "/affiliated-organization",
  },
  {
    id: 6,
    list: "Membership Certificate",
    url: "/get-certificate",
  },
];
export default FooterData3;
