import React from "react";
import img from "../images/whoWeare.png";
import infoImg1 from "../images/creative.png";
import infoImg2 from "../images/professional.png";
import infoImg3 from "../images/group (1).png";

const ourInfo = [
  {
    id: 1,
    img: infoImg2,
    heading: "Global Human Rights Defenders",
    description:
      "The organization uses advocacy, litigation, and public campaigns to advance people's rights globally. Through advocacy, the WHSC provides expertise and support to human rights defenders while holding those in power accountable for their actions.",
  },
  {
    id: 2,
    img: infoImg1,
    heading: "Minority Rights Group International ",
    description:
      "WHSC works on promotion of rights of minorities and cooperation between communities.The organization also actively works on improving people's access to justice and freedom through increased respect for their civil and political rights.",
  },
  {
    id: 3,
    img: infoImg3,
    heading: "International Society for Human Rights",
    description:
      "Our main areas of work include support of individuals who are discriminated, persecuted and imprisoned because of their religious beliefs or political affiliation, public relations related to human rights issues and education on human rights issues for those who live in countries that are transitioning to democracy.",
  },
];

const WhoWeAre = () => {
  return (
    <section className="top-gap   ">
      <div className="heading-style3 ">
        <h2>Who We Are</h2>
      </div>

      <div className="row custom-container mx-auto">
        <div className="col-md-6  our-info  p-0">
          <img src={img} alt="" className="" />
          <span></span>
        </div>
        <div className="col-md-6 pt-md-0 pt-5">
          {ourInfo.map((info) => (
            <div className="row   ps-md-3 our-info-desc">
              <div className="col-md-2  m-auto p-0 ">
                <div className="our-info-img my-auto mb-md-0 mb-3 ms-lg-2">
                  <img src={info.img} alt="" className="mt-lg-4 mt-3" />
                </div>
              </div>
              <div className="col-md-10  ps-lg-5 who-we-are-desc">
                <h3 className="who-we-are-title">{info.heading}</h3>
                <p className="content-font">{info.description}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default WhoWeAre;
