import React, { useState } from "react";
import "./../Card.css";
import Banner2 from "../../../Shared/Banner2/Banner2";
import bannerImg2 from "../../../images/bannerImg2.jpg";
import Mendi from "../../../images/our-people/patron/mendi-safadi.png";
import Gagan from "../../../images/our-people/patron/gagan--deep-bakshi.png";
import Dileep from "../../../images/our-people/patron/Dileep-Kumar-Thankappan.png";
import Swami from "../../../images/our-people/patron/Swami-Shivananda-Saraswati.png";
import Sadguru from "../../../images/our-people/patron/Sadguru-Brahmeshananda-Acharya.png";
import Tersilio from "../../../images/our-people/patron/Tersilio-Benedetti.png";
import Yakov from "../../../images/our-people/patron/Yakov-David-Cohen.png";
import { Link } from "react-router-dom";


const BoardMember = [
  {
    name: "Mendi N Safadi",
    designation: "Chief Patron & Chief International Counsel",
    personImg: Mendi,
    country: "Israel",
  },
  {
    name: "Gagan Deep Bakshi",
    designation: "Patron",
    personImg: Gagan,
    country: "India",
  },
  {
    name: "Guruji H H Dileep Kumar Thankappan",
    designation: "Patron",
    personImg: Dileep,
    country: "India",
  },
  {
    name: "Guruji Swami Shivananda Saraswati",
    designation: "Patron",
    personImg: Swami,
    country: "Italy",
  },
  {
    name: "Sadguru Brahmeshananda Acharya",
    designation: "Patron",
    personImg: Sadguru,
    country: "India",
  },
  {
    name: "Tersilio Benedetti",
    designation: "Patron",
    personImg: Tersilio,
    country: "Italy",
  },
  {
    name: "Yakov David Cohen",
    designation: "Patron",
    personImg: Yakov,
    country: "USA",
  },
];

const Patron = () => {
  const [loadMore, setLoadMore] = useState(12);
  const LoadMoreHandle = () => {
    setLoadMore((prevValue) => prevValue + 6);
  };
  return (
    <section>
      <Banner2
        bannerImg={bannerImg2}
        bannerTitle="Our Patron"
        link="Our People"
        link2="Patron"
        url1="/ourPeople"
        className="position-relative"
      />
      <div className="container">
        <div className="py-5">
          <div className="text-center ">
            <div className="heading-style4">
              <h2 className="text-black">Our Patron</h2>
            </div>
            {/* <p>
              PI is governed by a Board of Directors which is responsible for
              the overall strategy and governance of the organisation.
            </p> */}

            <div className="py-5 row">
              {BoardMember.slice(0, loadMore).map((value) => (
                <div className="col-md-4">
                  <div className="director-card">
                    <img src={value.personImg} alt="" className="teamImg w-75" />
                    <div className="infoo patron-info">
                      <div className="pb-5">
                        <h4 className="text-white">{value.name}</h4>
                        <small>{value.designation}</small> <br />
                        <small>Country: {value.country}</small>
                      </div>

                      {/* <div className="card-overlay">
                        <p className="pt-2">
                          PI is governed by a Board PI is governed by a Board of
                          Directors in India
                        </p>
                      </div> */}
                    </div>
                  </div>
                </div>
              ))}
            </div>
            <button onClick={LoadMoreHandle} className="primary-btn2">
              Load More
            </button>
          </div>
        </div>
      </div>

      <div className="TopSubFooterBg">
        <div className="custom-container">
          <div className="row TopSubFooter">
            <div className="col-md-8 my-auto">
              <h3 className="my-auto text-uppercase mb-2">Become a Part of Our Global Team</h3>
            </div>
            <div className="my-auto col-md-4">
              <Link to="/membership"><button className="">Join Us Now</button></Link>
            </div>
          </div>
        </div>
      </div>

    </section>
  );
};

export default Patron;
